<template>
  <div class="sso-settings">
    <div class="row items-center q-mb-md">
      <span class="q-mr-md">Set SSO (Single Sign-On)</span>
      <runai-tooltip
        aid="tooltip-sso-settings"
        tooltip-text="Anyone given access to the Run:ai application via the identity provider system will be able to join the platform and authenticate using SSO."
        width="400px"
        tooltip-position="right"
      />
    </div>
    <setting-readonly-input v-if="shouldShowAddButton">
      <template v-slot:default="{ readonly }">
        <q-btn aid="add-idp-btn" label="+ IDENTITY PROVIDER" flat color="primary" class="idp-button" :disable="readonly">
          <q-menu touch-position>
            <q-list style="min-width: 100px">
              <q-item clickable v-close-popup key="saml" @click="handleSelectIDP(IdpTypeEnum.Saml)">
                <q-item-section>Custom SAML 2.0</q-item-section>
              </q-item>
              <q-item clickable v-close-popup key="oidc" @click="handleSelectIDP(IdpTypeEnum.Oidc)">
                <q-item-section>Custom OpenID Connect</q-item-section>
              </q-item>
              <q-item clickable v-close-popup key="ocp" @click="handleSelectIDP(IdpTypeEnum.OpenshiftV4)">
                <q-item-section>OpenShift V4</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </template>
    </setting-readonly-input>
    <div v-else>
      <saml-settings
        v-if="isSamlIdp"
        :idp="currentIdp"
        :title="getIdpTitle"
        :idp-mappers="mappers"
        :is-new-idp="isNewIdp"
        @remove="deleteIdp"
        @update-idp="updateIdp"
        @add-idp="addIdp"
        @cancel-edit-idp="cancelEditIdp"
        ref="samlSettingsEl"
      />
      <oidc-settings
        v-if="isOidcIdp"
        :idp="currentIdp"
        :title="getIdpTitle"
        :idp-mappers="mappers"
        :is-new-idp="isNewIdp"
        @remove="deleteIdp"
        @update-idp="updateIdp"
        @add-idp="addIdp"
        @cancel-edit-idp="cancelEditIdp"
        ref="oidcSettingsEl"
      />
      <ocp-settings
        v-if="isOcpIdp"
        :idp="currentIdp"
        :title="getIdpTitle"
        :idp-mappers="mappers"
        :is-new-idp="isNewIdp"
        @remove="deleteIdp"
        @update-idp="updateIdp"
        @add-idp="addIdp"
        @cancel-edit-idp="cancelEditIdp"
        ref="ocpSettingsEl"
      />
    </div>
  </div>
  <div class="row items-center q-my-md">
    <span class="text-italic"
      >For more information, see the
      <a href="https://docs.run.ai/latest/admin/runai-setup/authentication/sso/?h=single+sign" target="_blank"
        >Single sign-On guide</a
      ></span
    >
  </div>
</template>

<script lang="ts">
import {
  IdpCreationRequestTypeEnum,
  IdpTypeEnum,
  type Idp,
  type IdpCreationRequest,
  type Mappers,
  type OidcCreationData,
  type SamlCreationData,
} from "@/swagger-models/identity-manager-client";
import { defineComponent } from "vue";
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { useIdpsStore } from "@/stores/idps.store";
import { alertUtil } from "@/utils/alert.util";
import { defaultEntityId, defaultMappers, defaultOcpMappers, settingUpdateSuccessMessage } from "@/models/setting.model";
import { settingsUtil } from "@/utils/settings.util";
import { useAppStore } from "@/stores/app.store";
import { urlService } from "@/services/url.service/url.service";
import { OidcSettings } from "./idps/oidc";
import { SamlSettings } from "./idps/saml";
import { OcpSettings } from "./idps/ocp";
import SettingReadonlyInput from "@/components/settings/setting-readonly-input/setting-readonly-input.vue";

export default defineComponent({
  name: "sso-settings",
  components: { SettingReadonlyInput, OcpSettings, OidcSettings, SamlSettings, RunaiTooltip },
  data() {
    return {
      idpStore: useIdpsStore(),
      isNewIdp: false,
      idpNewAlias: "" as string,
      appStore: useAppStore(),
      currentType: IdpTypeEnum.Saml,
    };
  },
  computed: {
    IdpTypeEnum(): typeof IdpTypeEnum {
      return IdpTypeEnum;
    },
    currentIdp(): Idp | undefined {
      return this.idpStore.currentIdp
        ? this.idpStore.currentIdp
        : this.getDefaultIdpConfig(this.idpNewAlias as IdpTypeEnum);
    },
    mappers(): Mappers {
      return this.isNewIdp ? (this.isOcpIdp ? defaultOcpMappers : defaultMappers) : this.idpStore.idpMappers;
    },
    getIdpTitle(): string {
      const alias = this.currentIdp?.alias || this.idpNewAlias;
      switch (alias) {
        case IdpCreationRequestTypeEnum.Saml:
          return "SAML 2.0";
        case IdpCreationRequestTypeEnum.Oidc:
          return "OpenID Connect";
        case IdpCreationRequestTypeEnum.OpenshiftV4:
          return "OpenShift V4";
        default:
          return "";
      }
    },
    shouldShowAddButton(): boolean {
      return this.currentIdp == undefined && !this.isNewIdp;
    },
    isSamlIdp(): boolean {
      return this.currentIdp?.alias === IdpTypeEnum.Saml || this.idpNewAlias === IdpTypeEnum.Saml;
    },
    isOidcIdp(): boolean {
      return this.currentIdp?.alias === IdpTypeEnum.Oidc || this.idpNewAlias === IdpTypeEnum.Oidc;
    },
    isOcpIdp(): boolean {
      return this.currentIdp?.alias === IdpTypeEnum.OpenshiftV4 || this.idpNewAlias === IdpTypeEnum.OpenshiftV4;
    },
  },
  methods: {
    getDefaultIdpConfig(idpType: IdpTypeEnum): Idp | undefined {
      if (!idpType) return undefined;
      return {
        alias: idpType,
        type: idpType,
        redirectUri: this.getDefaultRedirectUri(idpType),
        config: {
          entityId: defaultEntityId,
        },
      };
    },
    getDefaultRedirectUri(idpType: IdpTypeEnum): string {
      const baseUrl = urlService.getBackendBaseUrl();
      const tenantName = this.appStore.tenantName;
      return `${baseUrl}auth/realms/${tenantName}/broker/${idpType}/endpoint`;
    },
    handleSelectIDP(idp: IdpTypeEnum): void {
      this.isNewIdp = true;
      this.idpNewAlias = idp;
    },
    async deleteIdp(): Promise<void> {
      if (!this.currentIdp) return;
      try {
        await this.idpStore.removeIdp(this.currentIdp.alias);
        this.$q.notify(alertUtil.getSuccess(settingUpdateSuccessMessage));
      } catch (error: unknown) {
        this.$q.notify(settingsUtil.getSettingErrorMessage());
        console.error(error);
      }
    },
    getIdpSettingsElement(): typeof OidcSettings | typeof SamlSettings | typeof OcpSettings {
      if (this.isOidcIdp) {
        return this.$refs.oidcSettingsEl as typeof OidcSettings;
      } else if (this.isOcpIdp) {
        return this.$refs.ocpSettingsEl as typeof OcpSettings;
      }
      return this.$refs.samlSettingsEl as typeof SamlSettings;
    },
    async updateIdp(idpCreationRequest: IdpCreationRequest): Promise<void> {
      if (!this.currentIdp) return;
      try {
        await this.idpStore.updateIdp(this.currentIdp.alias, idpCreationRequest);
        this.$q.notify(alertUtil.getSuccess(settingUpdateSuccessMessage));
        this.getIdpSettingsElement().changeToReadMode();
      } catch (error: unknown) {
        this.$q.notify(settingsUtil.getSettingErrorMessage());
        console.error(error);
      }
    },
    async addIdp(idpNewFields: OidcCreationData | SamlCreationData, mappers: Mappers): Promise<void> {
      const idpCreationRequest: IdpCreationRequest | null = settingsUtil.createIdpCreationRequest(
        this.idpNewAlias,
        idpNewFields,
        mappers,
      );
      if (idpCreationRequest === null) return;
      try {
        await this.idpStore.addIdp(idpCreationRequest);
        this.$q.notify(alertUtil.getSuccess(settingUpdateSuccessMessage));
        this.idpNewAlias = "";
        this.isNewIdp = false;
        this.getIdpSettingsElement().changeToReadMode();
      } catch (error: unknown) {
        switch (idpCreationRequest.type) {
          case IdpCreationRequestTypeEnum.Saml:
            this.$q.notify(
              alertUtil.getError("SAML metadata couldn't be retrieved. Validate the metadata URL and try again."),
            );
            break;
          case IdpCreationRequestTypeEnum.Oidc:
            this.$q.notify(
              alertUtil.getError(
                "OpenID Connect metadata couldn't be retrieved. Validate the discovery URL and try again.",
              ),
            );
            break;
          case IdpCreationRequestTypeEnum.OpenshiftV4:
            this.$q.notify(
              alertUtil.getError("OpenShift metadata couldn't be retrieved. Validate the base URL and try again."),
            );
            break;
          default:
            this.$q.notify(settingsUtil.getSettingErrorMessage());
            break;
        }
        console.error(error);
      }
    },
    cancelEditIdp(): void {
      this.isNewIdp = false;
      this.idpNewAlias = "";
    },
  },
});
</script>
<style lang="scss" scoped>
.sso-settings {
  .idp-button {
    margin-bottom: 20px;
  }
}
</style>
