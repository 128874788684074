<template>
  <section class="toleration-box bg-grey-3 q-my-md q-pa-md row justify-between items-center">
    <div class="row items-center">
      <q-input
        aid="toleration-key-input"
        label="Key"
        stack-label
        placeholder="Enter a key"
        :style="{ minWidth: '190px' }"
        :model-value="toleration.key"
        @update:model-value="updateKey"
        no-error-icon
        :rules="[isEmptyKey, isValidKey]"
        :disable="readOnly"
      />

      <q-input
        v-if="showValueInput"
        aid="toleration-value-input"
        stack-label
        placeholder="Enter a value"
        class="q-ml-md"
        label="Value"
        :style="{ minWidth: '190px' }"
        :model-value="toleration.value"
        @update:model-value="updateValue"
        no-error-icon
        :rules="[isEmptyValue, isValidValue]"
        :disable="readOnly"
      />
    </div>

    <div class="row items-center justify-evenly">
      <runai-select
        aid="toleration-operator-select"
        unclearable
        label="Operator"
        :style="{ minWidth: '100px' }"
        :options="tolerationOptions"
        :model-value="toleration.operator"
        @update:model-value="updateOperator"
        :rules="[() => true]"
        :disable="readOnly"
      />

      <runai-select
        aid="toleration-effect-select"
        class="q-mx-md"
        label="Effect"
        emit-value
        unclearable
        :style="{ minWidth: '145px' }"
        :model-value="toleration.effect"
        @update:model-value="updateEffect"
        :options="effectOptions"
        :rules="[() => true]"
        :disable="readOnly"
      />

      <q-btn
        aid="remove-toleration-btn"
        icon="fa-regular fa-xmark"
        flat
        round
        color="black-54"
        @click="removeToleration"
        :disable="readOnly"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
// Models
import { TolerationOperator, type Toleration } from "@/swagger-models/assets-service-client";
import type { ISelectOption } from "@/models/global.model";
// Components
import { RunaiSelect } from "@/components/common/runai-select";
// Utils
import { isEmpty, isValidRunaiEntityNameFormat } from "@/common/form.validators";
// Constants
import { errorMessages } from "@/common/error-message.constant";

export default defineComponent({
  name: "toleration-box",
  components: { RunaiSelect },
  emits: ["remove-toleration", "update-toleration"],
  props: {
    toleration: {
      type: Object as PropType<Toleration>,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tolerationOptions(): string[] {
      return [TolerationOperator.Exists, TolerationOperator.Equal];
    },
    effectOptions(): ISelectOption[] {
      return [
        { label: "Any", value: "any", toolTip: "All effects match" },
        {
          label: "NoExecute",
          value: "NoExecute",
          toolTip: "Pods that do not tolerate the taint are evicted immediately",
        },
        {
          label: "NoSchedule",
          value: "NoSchedule",
          toolTip:
            "No new pods will be scheduled on the tainted node unless they have a matching toleration. Pods currently running on the node are not evicted.",
        },
        {
          label: "PreferNoSchedule",
          value: "PreferNoSchedule",
          toolTip:
            "The control plane will try to avoid placing a pod that does not tolerate the taint on the node, but it is not guaranteed",
        },
      ];
    },
    showValueInput(): boolean {
      return !!(this.toleration.operator === TolerationOperator.Equal);
    },
  },
  methods: {
    removeToleration(): void {
      this.$emit("remove-toleration");
    },
    updateKey(key: string | number | null): void {
      this.$emit("update-toleration", { ...this.toleration, key });
    },
    updateValue(value: string | number | null): void {
      this.$emit("update-toleration", { ...this.toleration, value });
    },
    updateOperator(operator: string | number | null): void {
      if (operator === TolerationOperator.Exists) {
        const toleration = { ...this.toleration, value: null, operator };
        this.$emit("update-toleration", toleration);
        return;
      }
      this.$emit("update-toleration", { ...this.toleration, operator });
    },
    updateEffect(effect: string | number | null): void {
      this.$emit("update-toleration", { ...this.toleration, effect });
    },
    // Rules
    isValidKey(val: string): boolean | string {
      return isValidRunaiEntityNameFormat(val) || errorMessages.VALID_FORMAT_KEY;
    },
    isEmptyKey(val: string): boolean | string {
      return !isEmpty(val) || errorMessages.KEY_NOT_EMPTY;
    },
    isValidValue(val: string): boolean | string {
      return isValidRunaiEntityNameFormat(val) || errorMessages.VALID_FORMAT_VALUE;
    },
    isEmptyValue(val: string): boolean | string {
      return !isEmpty(val) || errorMessages.VALUE_NOT_EMPTY;
    },
  },
});
</script>
