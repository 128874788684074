<template>
  <runai-page-filter-wrapper
    display
    :title="filter.label"
    @save="saveFilter"
    @close="$emit('close')"
    input-label="The following value:"
    max-width="250px"
  >
    <template #body>
      <div class="checkbox-container">
        <span class="text-black-54 q-mb-md">Matches any:</span>
        <q-item dense v-for="(option, index) in selectOptions" :key="index">
          <q-checkbox dense class="no-padding" v-model="selectedValues" :val="option.value" :label="option.label" />
        </q-item>
      </div>
    </template>
  </runai-page-filter-wrapper>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { IEnumBasedFilterModel, IEnumBasedFilterSelectOption } from "@/models/filter.model";
import { RunaiPageFilterWrapper } from "@/components/common/runai-advanced-filters/runai-page-filter-wrapper";

export default defineComponent({
  name: "runai-enum-based-filter",
  components: { RunaiPageFilterWrapper },
  emits: ["save", "close"],
  props: {
    filter: {
      type: Object as PropType<IEnumBasedFilterModel>,
      required: true,
    },
  },
  data() {
    return {
      selectedValues: [] as string[],
    };
  },
  created() {
    this.selectedValues = [...this.filter.selectedValues];
  },
  computed: {
    selectOptions(): IEnumBasedFilterSelectOption[] {
      return this.filter.selectOptions.filter((option) => !option.hidden);
    },
  },
  methods: {
    saveFilter(): void {
      this.$emit("save", {
        ...this.filter,
        selectedValues: this.selectedValues,
      });
    },
  },
});
</script>

<style scoped lang="scss">
.checkbox-container {
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding: unset;
  .q-item {
    padding: 0;
  }
}
</style>
