export enum EUserType {
  REGULAR_USER = "regular-user",
  SSO_USER = "sso-user",
}

export enum ERoles {
  ADMIN = "admin",
  RESEARCHER = "researcher",
  RESEARCH_MANAGER = "research_manager",
  EDITOR = "editor",
  VIEWER = "viewer",
  ML_ENGINEER = "ml_engineer",
  DEPARTMENT_ADMIN = "department_admin",
}

export interface IUser {
  email: string; // todo: remove - use username instead
  name: string; // todo: remove
  roles: Array<string>;
  entityType: EUserType; // todo: remove
  needToChangePassword: boolean;
  permitAllClusters: boolean;
  permittedClusters: [];
  username: string;
  tenantId?: number;
  userId?: string; // todo: remove - use id instead
  lastUpdated?: string;
  password?: string; // todo: remove - use tempPassword instead
  id: string;
  createdAt?: string;
  lastLogin?: string;
  createdBy?: string;
  updatedAt?: string;
  tempPassword?: string;
  isLocal?: boolean;
  identityProvider?: string;
  groups?: Array<string>;
}

export interface IUserCreationRequest {
  email: string;
}

export interface IUserCreationResponse {
  id: string;
  tempPassword: string;
  username: string;
}

export interface IUserResetPasswordResponse {
  tempPassword: {
    type: "tempPassword";
    value: string;
  };
}

export interface IUserData {
  lastLogin: string;
}
