<template>
  <runai-expansion-item :label="expansionLabel" default-opened>
    <template #subheader>
      {{ selectedScope?.path || "none" }}
    </template>
    <scope-input
      v-if="selectedScope"
      :readonly="!isNewProject || readonly"
      :selected-scope="selectedScope"
      tick-strategy="leaf"
      @update-selected="selectedScope = $event"
      :hidden-scope-types="hiddenScopeTypes"
      :clearable="false"
      :title="scopeInputTitle"
      :placeholder-search="placeHolderSearch"
    />
  </runai-expansion-item>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { ScopeInput } from "@/components/rbac/common/scope-input";
import type { IOrgTreeNodeId } from "@/models/org-tree.model";
import { ScopeType } from "@/swagger-models/authorization-client";
import { orgTreeService } from "@/services/control-plane/rbac/org-tree.service/org-tree.service";
import { useAuthStore } from "@/stores/auth.store";
import { useClusterStore } from "@/stores/cluster.store";
import { PROJECT_ROUTE_NAMES } from "@/router/project.routes/project.routes.names";
import { useSettingStore } from "@/stores/setting.store";
import type { OrgUnit } from "@/swagger-models/backend-client";

export default defineComponent({
  name: "project-scope-section",
  components: { ScopeInput, RunaiExpansionItem },
  emits: ["update-scope"],
  props: {
    departmentId: {
      type: [String, null] as PropType<string | null>,
      required: false,
    },
    clusterId: {
      type: String as PropType<string>,
      required: true,
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      selectedScope: { id: "", type: ScopeType.Cluster } as IOrgTreeNodeId,
      clusterStore: useClusterStore(),
      settingStore: useSettingStore(),
      authStore: useAuthStore(),
      hiddenScopeTypes: {
        [ScopeType.System]: false,
        [ScopeType.Tenant]: false,
        [ScopeType.Cluster]: false,
        [ScopeType.Department]: false,
        [ScopeType.Project]: true,
      } as Record<ScopeType, boolean>,
    };
  },
  created() {
    this.initSelectedScope();
  },
  computed: {
    expansionLabel(): string {
      return this.isDepartmentEnabled ? "Department" : "Cluster";
    },
    scopeInputTitle(): string {
      return this.isDepartmentEnabled ? "Departments" : "Clusters";
    },
    placeHolderSearch(): string {
      const suffix = this.isDepartmentEnabled ? "department" : "cluster";
      return `Search ${suffix}`;
    },
    isNewProject(): boolean {
      return this.$route.name === PROJECT_ROUTE_NAMES.PROJECT_NEW;
    },
    isDepartmentEnabled(): boolean {
      return this.settingStore.isDepartmentEnabled;
    },
    orgUnitList(): OrgUnit[] {
      return this.authStore.orgUnitList;
    },
  },
  methods: {
    initSelectedScope(): void {
      const ancestorId = this.isDepartmentEnabled ? this.departmentId : this.clusterId;
      const ancestorScopeType = this.isDepartmentEnabled ? ScopeType.Department : ScopeType.Cluster;
      const selectedScope = orgTreeService.getOrgTreeNodeId(this.orgUnitList, ancestorId, ancestorScopeType);

      if (selectedScope !== null) {
        this.selectedScope = selectedScope;
      } else {
        throw new Error("Selected scope is null");
      }
    },
    updateSelectedScope(): void {
      const scope = {
        clusterId: this.getClusterId(),
        departmentId: this.isDepartmentEnabled ? this.getDepartmentId() : null,
      };
      this.$emit("update-scope", scope);
    },
    getClusterId(): string | null {
      return orgTreeService.getParentClusterUuid(this.orgUnitList, this.selectedScope?.id, this.selectedScope?.type);
    },
    getDepartmentId(): string | undefined {
      return this.selectedScope?.id;
    },
  },
  watch: {
    selectedScope: {
      handler(): void {
        if (!this.isNewProject) return;
        this.updateSelectedScope();
      },
    },
  },
});
</script>

<style scoped lang="scss"></style>
