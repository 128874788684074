/* eslint-disable */

// component names: runai-custom-table-columns

import type { EColumnFilterType } from "@/models/filter.model";
import type { ISelectOption } from "@/models/global.model";

export enum ECustomCell {
  NAME_COL = "name-col",
  LIST_COL = "list-col",
  LINK_COL = "link-col",
  NODE_POOL_STATUS_COL = "node-pool-status-col",
  ROLE_ASSIGMENT_COL = "role-assigment-col",
  APP_STATUS_COL = "applications-status-col",
  LICENSE_STATUS_COL = "license-status-col",
  AUDIT_STATUS_COL = "audit-status-col",
  ORG_TREE_COL = "org-tree-col",
  SCOPE_COL = "scope-col",
  STRINGS_LIST_COL = "strings-list-col",
  STATUS_COL = "status-col",
  HTML_COL = "html-col",
  SOURCE_NAME_COL = "source-name-col",
  CLUSTER_ID_TO_NAME_COL = "cluster-id-to-name-col",
  WORKLOAD_CONNECTION_COL = "workload-connection-col",
  DESCRIPTION_COL = "description-col",
  TOOL_ACCESS_COL = "tool-access-col",
  DEPARTMENT_QUOTA_COL = "department-quota-col",
}

export interface ICustomCellLinkColConfig {
  isTextOnly?: boolean; //make link col display text instead of a link button
}
export interface ICustomCellConfig {
  [ECustomCell.LINK_COL]: ICustomCellLinkColConfig;
}
// based on QTableProps["columns"] props
export interface ITableColumn {
  name: string;
  label: string;
  field: string | ((row: any) => any);
  required?: boolean;
  align?: "left" | "right" | "center";
  sortable?: boolean;
  sort?: (a: any, b: any, rowA: any, rowB: any) => number;
  sortOrder?: "ad" | "da";
  format?: (val: any, row: any) => any;
  style?: string | ((row: any) => string);
  classes?: string | ((row: any) => string);
  headerStyle?: string;
  headerClasses?: string;
  display?: boolean;
  customCell?: ECustomCell;
  customCellEvent?: { emitName: string }; // the event name that will be emitted
  customCellConfig?: ICustomCellConfig;
  mandatory?: boolean;
  hideFilter?: boolean;
  filterKey?: string;
  exportFormat?: (row: any) => any;
  filter?: {
    type: EColumnFilterType;
    selectOptions?: ISelectOption[]; //for filter type EColumnFilterType.EnumBased
  };
}

export enum ETableFilters {
  NODE = "node-table-filters",
  NODE_POOL = "node-pool-table-filters",
  DEPARTMENT = "department-table-filters-advanced",
  COMPUTE_RESOURCE = "compute-resource-table-filters",
  DATA_SOURCE = "data-source-table-filters",
  TEMPLATE = "template-table-filters",
  ENVIRONMENT = "environment-table-filters",
  AUDIT = "audit-table-filters-advanced",
  CREDENTIAL = "credential-table-filters",
  PROJECT = "project-table-filters-advanced",
  ROLE = "role-table-filters",
  APPLICATION = "application-table-filters",
  CLUSTER = "cluster-table-filters",
  USERS = "users-table-filters",
  ACCESS_RULE = "access-rule-table-filters",
  WORKLOAD = "workload-table-filter",
  WORKLOAD_DELETED = "workload-deleted-table-filters",
  POLICY = "policy-table-filters",
}

export enum ETableExportCsvFilesNames {
  Application = "applications",
  Audit = "events-history",
  Cluster = "clusters",
  Credential = "credentials",
  DataSource = "data-sources",
  Department = "departments",
  Environment = "environments",
  Node = "nodes",
  Project = "projects",
  AccessRule = "access-rules",
  Role = "roles",
  Training = "trainings",
  User = "users",
  Template = "templates",
  Workload = "workloads",
  Workspace = "workspaces",
  NodePool = "node-pools",
}

export type TColumnOptions = IStatusColOptions; // Add future types so the filter would work

// based on QTableProps["columns"] props
export interface ITableColumn {
  name: string;
  label: string;
  field: string | ((row: any) => any);
  required?: boolean;
  align?: "left" | "right" | "center";
  sortable?: boolean;
  sort?: (a: any, b: any, rowA: any, rowB: any) => number;
  sortOrder?: "ad" | "da";
  format?: (val: any, row: any) => any;
  style?: string | ((row: any) => string);
  classes?: string | ((row: any) => string);
  headerStyle?: string;
  headerClasses?: string;
  display?: boolean;
  customCell?: ECustomCell;
  customCellEvent?: { emitName: string }; // the event name that will be emitted
  mandatory?: boolean;
  hideFilter?: boolean;
  filterKey?: string;
  exportFormat?: (row: any) => any;
  ignoreFilter?: (row: any) => boolean; // ignoring filter under sepecific circumstances
}

export interface IStatusColOptions {
  status: string;
  tooltipText?: string;
  displayAnimation: boolean;
  color?: string;
  showIconToolTip?: boolean;
  filterKey?: string; //only for client side filtering
  statusUpdatedTimeInMs?: number | string | Date;
  colStyle?: Object; // example usage - {width: '5px'}
}
