<template>
  <runai-expansion-item label="Projects" class="project-section q-pb-none" disable-closing>
    <runai-form-card-section
      :loading="loading"
      :main-message="`Select the project in which your workload will run`"
      :cards-list="projectCards"
      :selected-cards-ids="selectedProjectsIds"
      search-name="projects"
      empty-message="Looks like you don't have any project for the selected cluster..."
      :can-create-entity="canCreateProject"
      entity-name="project"
      :icon-name="projectIcon"
      @create-new="createProject"
      @selected-card-changed="onSelectedProject"
      :sort-options="{ name: true, creation: true }"
      default-sort-option="name"
    >
      <template #no-roles>
        <div class="empty-message text-center text-italic q-pt-md">
          <div>Looks like you don't have any project for the selected cluster...</div>
          <div>To create a workload, you need to be assigned to a project.</div>
          <div>Contact your administrator to be assigned.</div>
        </div>
      </template>
    </runai-form-card-section>
    <div class="col-12" v-if="!loading">
      <q-field class="hidden-field" :model-value="selectedProjectIdAsString" :rules="[isRequiredProject]"></q-field>
    </div>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// stores
import { usePermissionStore } from "@/stores/permissions.store";
import { useAppStore } from "@/stores/app.store";

// Models
import type { IProjectResources } from "@/models/project.model";
import { TCardCmpName, type ICardListItem } from "@/components/common/runai-card-list";
import { Action, ResourceType } from "@/swagger-models/authorization-client";

// Components
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { RunaiFormCardSection } from "@/components/common";

// Utils
import { required } from "@/common/form.validators";

// Constants
import { errorMessages } from "@/common/error-message.constant";

// routes
import { PROJECT_ROUTE_NAMES } from "@/router/project.routes/project.routes.names";

export default defineComponent({
  components: {
    RunaiExpansionItem,
    RunaiFormCardSection,
  },
  props: {
    loading: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    projects: {
      type: Array as PropType<Array<IProjectResources>>,
      required: true,
    },
    selectedProjectId: {
      type: [Number, null] as PropType<number | null>,
      required: true,
    },
    clusterId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      permissionStore: usePermissionStore(),
      appStore: useAppStore(),
    };
  },
  computed: {
    projectCards(): Array<ICardListItem> {
      return this.projects.map((project: IProjectResources) => {
        const name = project.name;
        const quota = (project.deservedGpus || 0).toString();
        const allocated = (project.allocatedGpus || 0).toString();
        const overquota = (project.allocatedGpus - project.deservedGpus).toString();

        return {
          id: String(project.id),
          cardName: TCardCmpName.PROJECT,
          searchValues: [name, quota, allocated, overquota].filter((val) => !!val),
          data: project,
          sortInfo: {
            name: project.name,
            createdAt: project.createdAt,
          },
        };
      });
    },
    selectedProjectIdAsString(): string | null {
      return this.selectedProjectId !== null && this.selectedProjectId !== -1 ? String(this.selectedProjectId) : null;
    },
    selectedProjectsIds(): Array<string> {
      return this.selectedProjectId ? [String(this.selectedProjectId)] : [];
    },
    canCreateProject(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Project, Action.Create);
    },
    projectIcon(): string {
      return this.appStore.isNewNavigationFeatureOn ? "project-gray-new" : "project-gray";
    },
  },
  methods: {
    onSelectedProject(selectedItems: Array<string>): void {
      selectedItems.length === 0
        ? this.$emit("project-changed", null)
        : this.$emit("project-changed", Number(selectedItems[0]));
    },
    isRequiredProject(val: string | null): boolean | string {
      return (val && required(val)) || errorMessages.SELECT_PROJECT;
    },
    createProject(): void {
      this.$router.push({
        name: PROJECT_ROUTE_NAMES.PROJECT_NEW,
        query: { clusterId: this.clusterId },
      });
    },
  },
});
</script>
