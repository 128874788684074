<template>
  <div class="node-affinity-section column q-ml-sm q-mt-lg">
    <div>Set node affinity to schedule the workload on a specific node type</div>
    <div class="row q-my-md">
      <q-select
        v-if="inputType === 'select'"
        aid="node-affinity-select"
        class="col-5 node-affinity-select"
        option-label="name"
        :model-value="nodeType"
        @update:model-value="selectNodeAffinity"
        no-error-icon
        :options="nodesAffinity"
        label="Node type (Affinity)"
        :rules="[isSelected]"
        :disable="disabled"
      />

      <q-input
        v-if="inputType === 'input'"
        aid="node-affinity-input"
        type="text"
        :model-value="nodeType"
        @update:model-value="updateNodeAffinity"
        class="col-5"
        debounce="300"
        label="Node type (Affinity)"
        :disable="disabled"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// Models
import type { ISelectedNodeAffinity } from "@/models/project.model";

// constant
import { errorMessages } from "@/common/error-message.constant";

export default defineComponent({
  emits: ["node-type-changed"],
  props: {
    nodesAffinity: {
      type: Array as PropType<Array<ISelectedNodeAffinity>>,
      required: false,
    },
    inputType: {
      type: String as PropType<"select" | "input">,
      required: true,
    },
    nodeType: {
      type: [String, null] as PropType<string | null>,
      required: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
  },
  methods: {
    selectNodeAffinity(selectedNodeAffinity: ISelectedNodeAffinity | null): void {
      this.updateNodeAffinity(selectedNodeAffinity?.name || null);
    },
    updateNodeAffinity(nodeAffinity: string | number | null): void {
      this.$emit("node-type-changed", nodeAffinity);
    },
    isSelected(selectedOption: ISelectedNodeAffinity | null): boolean | string {
      return !!selectedOption || errorMessages.SELECT_NODE_TYPE;
    },
  },
});
</script>
<style lang="scss" scoped></style>
