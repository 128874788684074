<template>
  <div class="column items-center q-pt-md">
    <project-edit-form
      v-if="!loading && project"
      :is-project-submitting="isProjectSubmitting"
      :project="project"
      :is-department-enabled="isDepartmentEnabled"
      @on-save="onSave"
      @on-cancel="onCancel"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PROJECT_ROUTE_NAMES } from "@/router/project.routes/project.routes.names";
// cmps
import { ProjectEditForm } from "@/components/project/project-edit-form";
// services
import { alertUtil } from "@/utils/alert.util";
import { requestToLeave } from "@/services/infra/router.service/router.service";
// stores
import { useAppStore } from "@/stores/app.store";
import { useSettingStore } from "@/stores/setting.store";
import { useAuthStore } from "@/stores/auth.store";
// models
import { EProjectColumnName } from "@/models/project.model";
import { useClusterStore } from "@/stores/cluster.store";
import { resourceUtil } from "@/utils/resource.util";
import { type IFilterBy } from "@/models/filter.model";
import { filterService } from "@/services/filter.service/filter.service";
import { ETableFilters } from "@/models/table.model";
import { projectIndexColumns } from "@/table-models/project.table-model";
import type { INodePoolsNameAndId } from "@/models/node-pool.model";
import {
  type Department,
  type Project,
  type ProjectCreationRequest,
  type Resources,
} from "@/swagger-models/org-unit-service-client";
import { orgUnitService } from "@/services/control-plane/org-unit.service/org-unit.service";
import { ErrorAlert } from "@/utils/error-alert.util";
import { orgUnitUtil } from "@/utils/org-unit.util";
import type { IEmptyProjectModelConfig } from "@/models/org-unit.model";

export default defineComponent({
  components: { ProjectEditForm },
  data() {
    return {
      loading: false as boolean,
      isProjectSubmitting: false as boolean,
      appStore: useAppStore(),
      authStore: useAuthStore(),
      settingStore: useSettingStore(),
      clusterStore: useClusterStore(),
      defaultDepartment: null as Department | null,
      project: null as Project | ProjectCreationRequest | null,
      clusterId: "" as string,
    };
  },
  async created() {
    this.loading = true;
    this.initClusterId();
    try {
      await this.loadDefaultDepartment();
      await this.loadProject();
    } catch (err) {
      console.log(err);
      this.appStore.setFallback(true);
    } finally {
      this.loading = false;
      this.appStore.setPageLoading(false);
    }
  },
  computed: {
    isNewProject(): boolean {
      return this.$route.name === PROJECT_ROUTE_NAMES.PROJECT_NEW;
    },
    projectId(): string {
      return this.$route.params.id as string;
    },
    isDepartmentEnabled(): boolean {
      return this.settingStore.isDepartmentEnabled;
    },
    isCpuEnabled(): boolean {
      return this.settingStore.isCPUResourcesQuotaEnabled;
    },
    isOverQuotaPriorityEnabled(): boolean {
      return this.settingStore.isOverQuotaPriorityEnabled;
    },
  },
  methods: {
    initClusterId(): void {
      if (this.$route.query.clusterId) {
        this.clusterId = this.$route.query.clusterId.toString();
      } else {
        this.clusterId = this.clusterStore.getClusterIdFromFilters(ETableFilters.PROJECT, true);
      }
    },
    async loadDefaultDepartment(): Promise<void> {
      const defaultDepartment = await orgUnitService.getDefaultDepartment(this.clusterId);
      if (defaultDepartment === null) {
        throw new Error("No default department found");
      }
      this.defaultDepartment = defaultDepartment;
    },
    async loadProject(): Promise<void> {
      if (this.isNewProject) {
        await this.loadNewProject();
      } else if (this.projectId) {
        await this.loadExistingProject();
      }
    },
    async loadNewProject(): Promise<void> {
      const emptyModelConfig = this.getEmptyProjectModelConfig();
      const emptyProjectFields: ProjectCreationRequest = orgUnitUtil.getEmptyProjectModel(emptyModelConfig);
      this.project = emptyProjectFields;
    },
    getNodePoolsNamesAndIds(): INodePoolsNameAndId[] {
      return (
        this.defaultDepartment?.resources.map((resources) => {
          return {
            id: orgUnitUtil.getNodePoolIdByResource(resources),
            name: orgUnitUtil.getNodePoolNameByResource(resources),
          };
        }) || []
      );
    },
    getEmptyProjectModelConfig(): IEmptyProjectModelConfig {
      return {
        nodePoolsNamesAndIds: this.getNodePoolsNamesAndIds(),
        clusterId: this.clusterId,
        isCpuEnabled: this.isCpuEnabled,
        parentId: this.defaultDepartment === null ? undefined : this.defaultDepartment.id,
      };
    },
    async loadExistingProject(): Promise<void> {
      const project: Project = await orgUnitService.getProject(this.projectId);
      const resources = resourceUtil.sortNodePools(project.resources);
      project.resources = orgUnitUtil.initResourcesOverQuotaWeight(resources, this.isOverQuotaPriorityEnabled);
      if (this.isCpuEnabled) {
        project.resources = orgUnitUtil.enrichResourcesWithCpuAndMemory(project.resources);
      }
      if (project) {
        this.project = project;
      }
    },
    async onCancel(): Promise<void> {
      const allowToLeave: boolean = await requestToLeave();
      if (allowToLeave) {
        this.$router.back();
      }
    },
    async onSave(project: Project | ProjectCreationRequest): Promise<void> {
      this.isProjectSubmitting = true;

      let savedProject: Project;
      project.resources = this.updateLimitOrOverQuota(project.resources);
      if (!this.isOverQuotaPriorityEnabled) {
        project.resources = orgUnitUtil.removeOverQuotaWeightFields(project.resources);
      }

      try {
        if (this.isNewProject) {
          savedProject = await orgUnitService.createProject(project as ProjectCreationRequest);
          await useAuthStore().loadUserOrgUnits();
        } else {
          const projectUpdateRequest = orgUnitUtil.getProjectUpdateRequest(project as Project);
          savedProject = await orgUnitService.updateProject((project as Project).id, projectUpdateRequest);
        }
        this.redirect(savedProject);
        this.$q.notify(alertUtil.getSuccess(`Project ${project.name} ${this.isNewProject ? "created" : "saved"}`));
      } catch (error: unknown) {
        this.handleError(error);
      } finally {
        this.isProjectSubmitting = false;
      }
    },
    updateLimitOrOverQuota(resources: Resources[]): Resources[] {
      return orgUnitUtil.updateLimitOrOverQuota(resources, this.isOverQuotaPriorityEnabled);
    },
    redirect(savedProject: Project): void {
      const routeQuery = this.$route.meta.backPageName;
      const routeName = routeQuery ? routeQuery.toString() : PROJECT_ROUTE_NAMES.PROJECT_INDEX;
      const shouldApplyClusterFilter = this.isNewProject && routeName === PROJECT_ROUTE_NAMES.PROJECT_INDEX;

      if (shouldApplyClusterFilter) {
        this.setClusterColumnFilter(savedProject);
      }

      this.$router.push({
        name: routeQuery,
        query: this.getProjectQueryParams(savedProject),
      });
    },
    getProjectQueryParams(savedProject: Project): { createdEntityId: string; clusterId?: string } {
      const queryParams: { createdEntityId: string; clusterId?: string } = { createdEntityId: savedProject.id };
      if (this.$route.query.clusterId) {
        queryParams.clusterId = this.$route.query.clusterId.toString();
      }
      return queryParams;
    },
    setClusterColumnFilter(project: Project): void {
      const filterBy: IFilterBy = this.getProjectsFilterBy();
      filterBy.clusterId = project.clusterId;
      filterService.saveFilters(ETableFilters.PROJECT, filterBy);
    },
    getProjectsFilterBy(): IFilterBy {
      const defaultFilters: IFilterBy = filterService.getDefaultFilters(
        EProjectColumnName.ProjectName,
        projectIndexColumns,
      );
      return filterService.loadFilters(window.location, ETableFilters.PROJECT, defaultFilters);
    },
    handleError(error: unknown) {
      const errorAlert = new ErrorAlert({
        generalMessage: this.isNewProject
          ? ErrorAlert.failedCreateMessage("project")
          : ErrorAlert.failedUpdateMessage("project"),
      });
      this.$q.notify(errorAlert.getNotification(error));
    },
  },
});
</script>
