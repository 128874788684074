<template>
  <runai-expansion-item label="Scheduling rules" :default-opened="isSectionOpen" :section-invalid="!sectionValid">
    <template #subheader>{{ expansionSubHeader }} </template>
    <div class="q-my-md">
      <span class="block q-my-md">Set rules to control utilization of the project's compute resources</span>
      <span class="text-italic"
        >For more information, see the
        <a target="_blank" href="https://docs.run.ai/latest/admin/admin-ui-setup/project-setup/">Projects</a>
        guide</span
      >
    </div>
    <!-- workload max idle duration -->
    <workload-duration-configurator
      v-if="showWorkloadMaxIdleDurationSection"
      header="Idle GPU timeout"
      subheader="Limit the duration of a workload whose GPU is idle"
      add-duration-button-label="+IDLE GPU TIMEOUT"
      :workloads-duration="workloadsMaxIdleDuration"
      :workload-options="workloadMaxIdleDurationOptions"
      @add-duration="addWorkloadMaxIdleDuration"
      @remove-duration="removeWorkloadMaxIdleDuration"
      @duration-changed="onWorkloadMaxIdleDurationChanged"
      @close="hideSection($options.ERulesSection.IdleGpuTimeout)"
    />

    <!-- workload time limit duration -->
    <workload-duration-configurator
      v-if="showWorkloadTimeLimitDurationSection"
      header="Workload time limit"
      subheader="Set a time limit for workspaces regardless of their activity (e.g., stop the workspace after 1 day of work)"
      add-duration-button-label="+WORKLOAD TIME LIMIT"
      :workloads-duration="workloadTimeLimitDuration"
      :workload-options="workloadTimeLimitDurationOptions"
      @add-duration="addWorkloadTimeLimitDuration"
      @remove-duration="removeWorkloadTimeLimitDuration"
      @duration-changed="onWorkloadTimeLimitDurationChanged"
      @close="hideSection($options.ERulesSection.WorkloadTimeLimit)"
    />
    <node-affinity-section
      v-if="showNodeAffinitySection"
      :cluster-id="clusterId"
      :workloads="nodeAffinityWorkloads"
      @add-workload="addNodeAffinity"
      @remove-workload="removeNodeAffinity"
      @workload-changed="onNodeAffinityChanged"
      @add-new-node-affinity-type="addNewNodeAffinityType"
      @update-workload-loading="updateNodeAffinityLoading"
      @close="hideSection($options.ERulesSection.NodeAffinity)"
    />
    <runai-button-with-menu
      v-if="showRulesButton"
      button-label="+ RULE"
      aid="add-scheduling-rule-button"
      :options="sectionOptions"
      @item-clicked="showSection"
    />
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
// cmps
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { RunaiButtonWithMenu } from "@/components/common/runai-button-with-menu";
import { NodeAffinitySection } from "@/components/project/project-edit-form/scheduling-rules-section/node-affinity-section";
import { WorkloadDurationConfigurator } from "@/components/project/project-edit-form/scheduling-rules-section/workload-duration-configurator/";
// models
import type { ISelectOption } from "@/models/global.model";
import { EWorkloadTimeLimit, type IWorkloadDurationOption } from "@/models/workload.model";
import { EIdleWorkloadMaxIdleDuration } from "@/models/workload.model";
import type { INodeAffinitySelectOption } from "@/models/project.model";
import { EWorkloadNodeAffinity } from "@/models/project.model";
//utils
import type { NodeTypesPerWorkload, SchedulingRules } from "@/swagger-models/org-unit-service-client";

enum ERulesSection {
  IdleGpuTimeout = "idleGpuTimeout",
  WorkloadTimeLimit = "workloadTimeLimit",
  NodeAffinity = "nodeAffinity",
}

enum ESectionLabel {
  IdleGpuTimeout = "Idle GPU timeout",
  WorkloadTimeLimit = "Workload time limit",
  NodeAffinity = "Node type (Affinity)",
}

export default defineComponent({
  components: {
    WorkloadDurationConfigurator,
    NodeAffinitySection,
    RunaiButtonWithMenu,
    RunaiExpansionItem,
  },
  emits: ["update:scheduling-rules", "update:node-types", "is-section-invalid"],
  props: {
    clusterId: {
      type: String,
      required: true,
    },
    schedulingRules: {
      type: [Object, null] as PropType<SchedulingRules | null>,
      required: false,
    },
    nodeTypes: {
      type: Object as PropType<NodeTypesPerWorkload>,
      required: false,
    },
  },
  ERulesSection: ERulesSection,
  data() {
    const sectionOptions = [
      {
        value: ERulesSection.IdleGpuTimeout,
        label: `+ ${ESectionLabel.IdleGpuTimeout}`,
        disable: false,
      },
      {
        value: ERulesSection.WorkloadTimeLimit,
        label: `+ ${ESectionLabel.WorkloadTimeLimit}`,
        disable: false,
      },
      {
        value: ERulesSection.NodeAffinity,
        label: `+ ${ESectionLabel.NodeAffinity}`,
        disable: false,
      },
    ] as ISelectOption[];
    return {
      showWorkloadMaxIdleDurationSection: false as boolean,
      showWorkloadTimeLimitDurationSection: false as boolean,
      showNodeAffinitySection: false as boolean,
      workloadsMaxIdleDuration: [] as IWorkloadDurationOption[],
      workloadTimeLimitDuration: [] as IWorkloadDurationOption[],
      nodeAffinityWorkloads: [] as INodeAffinitySelectOption[],
      sectionOptions: sectionOptions,
    };
  },
  created() {
    this.initWorkloadMaxIdleDurationSection();
    this.initWorkloadTimeLimitDurationSection();
    this.initNodeAffinitySection();
  },
  computed: {
    sectionValid(): boolean {
      if (!this.isSectionOpen) {
        return true;
      }

      return [
        this.isWorkloadMaxIdleSectionValid,
        this.isWorkloadTimeLimitSectionValid,
        this.isNodeAffinitySectionValid,
      ].every((isValid) => isValid);
    },
    isWorkloadMaxIdleSectionValid(): boolean {
      return (
        this.showWorkloadMaxIdleDurationSection &&
        this.workloadsMaxIdleDuration.some((workload: IWorkloadDurationOption) => !workload.value || !workload.duration)
      );
    },
    isWorkloadTimeLimitSectionValid(): boolean {
      return (
        this.showWorkloadTimeLimitDurationSection &&
        !this.schedulingRules?.interactiveJobTimeLimitSeconds &&
        !this.schedulingRules?.trainingJobTimeLimitSeconds
      );
    },
    isNodeAffinitySectionValid(): boolean {
      return (
        this.showNodeAffinitySection &&
        this.nodeAffinityWorkloads.some(
          (workload: INodeAffinitySelectOption) => !workload.value || workload.selectedTypes.length === 0,
        )
      );
    },
    isSectionOpen(): boolean {
      return (
        this.showWorkloadMaxIdleDurationSection ||
        this.showWorkloadTimeLimitDurationSection ||
        this.showNodeAffinitySection
      );
    },
    showRulesButton(): boolean {
      return this.sectionOptions.some((item: ISelectOption) => !item.disable);
    },
    isAllSectionsEmpty(): boolean {
      return (
        !this.schedulingRules?.trainingJobTimeLimitSeconds &&
        !this.schedulingRules?.interactiveJobTimeLimitSeconds &&
        !this.schedulingRules?.trainingJobMaxIdleDurationSeconds &&
        !this.schedulingRules?.interactiveJobPreemptIdleDurationSeconds &&
        !this.schedulingRules?.interactiveJobMaxIdleDurationSeconds &&
        !this.nodeTypes?.workspace?.length &&
        !this.nodeTypes?.training?.length
      );
    },
    expansionSubHeader(): string {
      if (this.isAllSectionsEmpty) {
        return "None";
      }
      const sections = [];
      if (
        this.schedulingRules?.trainingJobMaxIdleDurationSeconds ||
        this.schedulingRules?.interactiveJobMaxIdleDurationSeconds ||
        this.schedulingRules?.interactiveJobPreemptIdleDurationSeconds
      ) {
        sections.push(ESectionLabel.IdleGpuTimeout);
      }

      if (this.schedulingRules?.interactiveJobTimeLimitSeconds || this.schedulingRules?.trainingJobTimeLimitSeconds) {
        sections.push(ESectionLabel.WorkloadTimeLimit);
      }

      if (this.nodeTypes?.workspace?.length || this.nodeTypes?.training?.length) {
        sections.push(ESectionLabel.NodeAffinity);
      }
      return sections.join(" / ");
    },
    //********* workload max idle duration  *********
    workloadMaxIdleDurationOptions(): ISelectOption[] {
      const isOptionDisabled = (value: EIdleWorkloadMaxIdleDuration): boolean =>
        this.workloadsMaxIdleDuration.some((workload: IWorkloadDurationOption) => workload.value === value);
      return [
        {
          value: EIdleWorkloadMaxIdleDuration.training,
          label: "Training",
          disable: isOptionDisabled(EIdleWorkloadMaxIdleDuration.training),
        },
        {
          value: EIdleWorkloadMaxIdleDuration.interactivePreemptible,
          label: "Preemptive workspaces",
          disable: isOptionDisabled(EIdleWorkloadMaxIdleDuration.interactivePreemptible),
        },
        {
          value: EIdleWorkloadMaxIdleDuration.interactive,
          label: "Non Preemptive workspaces",
          disable: isOptionDisabled(EIdleWorkloadMaxIdleDuration.interactive),
        },
      ] as ISelectOption[];
    },
    //********* workload time limit duration *********
    workloadTimeLimitDurationOptions(): ISelectOption[] {
      const isOptionDisabled = (value: EWorkloadTimeLimit): boolean =>
        this.workloadTimeLimitDuration.some((workload) => workload.value === value);

      return [
        {
          value: EWorkloadTimeLimit.training,
          label: "Training",
          disable: isOptionDisabled(EWorkloadTimeLimit.training),
        },
        {
          value: EWorkloadTimeLimit.interactive,
          label: "Workspace",
          disable: isOptionDisabled(EWorkloadTimeLimit.interactive),
        },
      ];
    },
  },
  methods: {
    //********* workload max idle duration  *********
    initWorkloadMaxIdleDurationSection() {
      if (
        this.schedulingRules?.trainingJobMaxIdleDurationSeconds !== null &&
        this.schedulingRules?.trainingJobMaxIdleDurationSeconds !== undefined
      ) {
        this.workloadsMaxIdleDuration.push({
          label: "Training",
          value: EIdleWorkloadMaxIdleDuration.training,
          duration: this.schedulingRules.trainingJobMaxIdleDurationSeconds,
        });
        this.showSection(ERulesSection.IdleGpuTimeout);
      }

      if (
        this.schedulingRules?.interactiveJobPreemptIdleDurationSeconds !== null &&
        this.schedulingRules?.interactiveJobPreemptIdleDurationSeconds !== undefined
      ) {
        this.workloadsMaxIdleDuration.push({
          label: "Preemptive workspaces",
          value: EIdleWorkloadMaxIdleDuration.interactivePreemptible,
          duration: this.schedulingRules.interactiveJobPreemptIdleDurationSeconds,
        });
        this.showSection(ERulesSection.IdleGpuTimeout);
      }

      if (
        this.schedulingRules?.interactiveJobMaxIdleDurationSeconds !== null &&
        this.schedulingRules?.interactiveJobMaxIdleDurationSeconds !== undefined
      ) {
        this.workloadsMaxIdleDuration.push({
          label: "Non Preemptive workspaces",
          value: EIdleWorkloadMaxIdleDuration.interactive,
          duration: this.schedulingRules.interactiveJobMaxIdleDurationSeconds,
        });
        this.showSection(ERulesSection.IdleGpuTimeout);
      }
    },
    addWorkloadMaxIdleDuration(): void {
      this.workloadsMaxIdleDuration.push({
        label: "",
        value: "",
        duration: 0,
      });
    },
    removeWorkloadMaxIdleDuration(workloadIndex: number): void {
      this.workloadsMaxIdleDuration.splice(workloadIndex, 1);
    },
    onWorkloadMaxIdleDurationChanged(workload: IWorkloadDurationOption, workloadIndex: number): void {
      this.workloadsMaxIdleDuration.splice(workloadIndex, 1, workload);
    },

    //********* workload time limit duration *********
    initWorkloadTimeLimitDurationSection() {
      if (
        this.schedulingRules?.trainingJobTimeLimitSeconds !== null &&
        this.schedulingRules?.trainingJobTimeLimitSeconds !== undefined
      ) {
        this.workloadTimeLimitDuration.push({
          label: "Training",
          value: EWorkloadTimeLimit.training,
          duration: this.schedulingRules.trainingJobTimeLimitSeconds,
        });
        this.showSection(ERulesSection.WorkloadTimeLimit);
      }

      if (
        this.schedulingRules?.interactiveJobTimeLimitSeconds !== null &&
        this.schedulingRules?.interactiveJobTimeLimitSeconds !== undefined
      ) {
        this.workloadTimeLimitDuration.push({
          label: "Workspace",
          value: EWorkloadTimeLimit.interactive,
          duration: this.schedulingRules.interactiveJobTimeLimitSeconds,
        });
        this.showSection(ERulesSection.WorkloadTimeLimit);
      }
    },
    addWorkloadTimeLimitDuration(): void {
      this.workloadTimeLimitDuration.push({
        label: "",
        value: "",
        duration: 0,
      });
    },
    removeWorkloadTimeLimitDuration(workloadIndex: number): void {
      this.workloadTimeLimitDuration.splice(workloadIndex, 1);
    },
    onWorkloadTimeLimitDurationChanged(workload: IWorkloadDurationOption, workloadIndex: number): void {
      this.workloadTimeLimitDuration.splice(workloadIndex, 1, workload);
    },

    //********* node affinity *********
    initNodeAffinitySection(): void {
      const nodeTypesNames = this.nodeTypes?.names || {};
      const pushNodeAffinityWorkload = (nodeTypes: string[], label: string, value: EWorkloadNodeAffinity) => {
        const selectedTypes = nodeTypes.map((nodeTypeId: string) => ({
          label: nodeTypesNames[nodeTypeId],
          value: nodeTypeId,
        }));
        if (selectedTypes.length > 0) {
          this.nodeAffinityWorkloads.push({
            label: label,
            value: value,
            loading: false,
            selectedTypes,
          });
          this.showSection(ERulesSection.NodeAffinity);
        }
      };
      pushNodeAffinityWorkload(this.nodeTypes?.training || [], "Training", EWorkloadNodeAffinity.Train);
      pushNodeAffinityWorkload(this.nodeTypes?.workspace || [], "Workspace", EWorkloadNodeAffinity.Interactive);
    },
    addNodeAffinity(): void {
      this.nodeAffinityWorkloads.push({
        label: "",
        value: "",
        loading: false,
        selectedTypes: [],
      });
    },
    addNewNodeAffinityType(workloadIndex: number, newType: ISelectOption): void {
      this.nodeAffinityWorkloads[workloadIndex].selectedTypes.push(newType);
    },
    updateNodeAffinityLoading(workloadIndex: number, isLoading: boolean): void {
      this.nodeAffinityWorkloads[workloadIndex].loading = isLoading;
    },
    onNodeAffinityChanged(workload: INodeAffinitySelectOption, workloadIndex: number): void {
      this.nodeAffinityWorkloads.splice(workloadIndex, 1, workload);
    },
    removeNodeAffinity(workloadIndex: number): void {
      this.nodeAffinityWorkloads.splice(workloadIndex, 1);
    },

    getSectionIndexByName(optionValue: string): number {
      return this.sectionOptions.findIndex((option: ISelectOption) => option.value === optionValue);
    },
    resetSection(section: ERulesSection): void {
      switch (section) {
        case ERulesSection.IdleGpuTimeout:
          this.workloadsMaxIdleDuration = [];
          break;
        case ERulesSection.WorkloadTimeLimit:
          this.workloadTimeLimitDuration = [];
          break;
        case ERulesSection.NodeAffinity:
          this.nodeAffinityWorkloads = [];
      }
    },
    showSection(section: ERulesSection): void {
      const optionIndex = this.getSectionIndexByName(section);
      this.sectionOptions[optionIndex].disable = true;
      this.toggleSectionVisibility(section, true);
    },
    hideSection(section: ERulesSection): void {
      const optionIndex = this.getSectionIndexByName(section);
      this.sectionOptions[optionIndex].disable = false;
      this.toggleSectionVisibility(section, false);
    },
    toggleSectionVisibility(section: ERulesSection, visible: boolean): void {
      switch (section) {
        case ERulesSection.IdleGpuTimeout:
          this.showWorkloadMaxIdleDurationSection = visible;
          break;
        case ERulesSection.WorkloadTimeLimit:
          this.showWorkloadTimeLimitDurationSection = visible;
          break;
        case ERulesSection.NodeAffinity:
          this.showNodeAffinitySection = visible;
      }
    },
    getWorkloadDuration(
      workloads: IWorkloadDurationOption[],
      value: EIdleWorkloadMaxIdleDuration | EWorkloadTimeLimit,
    ): number | null {
      const idleWorkload = workloads.find((workload: IWorkloadDurationOption) => workload.value === value);

      if (idleWorkload && idleWorkload.duration) {
        return idleWorkload.duration;
      } else {
        return null;
      }
    },
  },
  watch: {
    showWorkloadMaxIdleDurationSection(isSectionOpened: boolean): void {
      if (!isSectionOpened) {
        this.resetSection(ERulesSection.IdleGpuTimeout);
      } else if (this.workloadsMaxIdleDuration.length === 0) {
        //in case of no values open one section as default.
        this.addWorkloadMaxIdleDuration();
      }
    },
    showWorkloadTimeLimitDurationSection(isSectionOpened: boolean): void {
      if (!isSectionOpened) {
        this.resetSection(ERulesSection.WorkloadTimeLimit);
      } else if (this.workloadTimeLimitDuration.length === 0) {
        //in case of no values open one section as default.
        this.addWorkloadTimeLimitDuration();
      }
    },
    showNodeAffinitySection(isSectionOpened: boolean): void {
      if (!isSectionOpened) {
        this.resetSection(ERulesSection.NodeAffinity);
      } else if (this.nodeAffinityWorkloads.length === 0) {
        //in case of no values open one section as default.
        this.addNodeAffinity();
      }
    },
    workloadsMaxIdleDuration: {
      handler(workloads: IWorkloadDurationOption[]) {
        this.$emit("update:scheduling-rules", {
          ...this.schedulingRules,
          trainingJobMaxIdleDurationSeconds: this.getWorkloadDuration(workloads, EIdleWorkloadMaxIdleDuration.training),
          interactiveJobPreemptIdleDurationSeconds: this.getWorkloadDuration(
            workloads,
            EIdleWorkloadMaxIdleDuration.interactivePreemptible,
          ),
          interactiveJobMaxIdleDurationSeconds: this.getWorkloadDuration(
            workloads,
            EIdleWorkloadMaxIdleDuration.interactive,
          ),
        });
      },
      deep: true,
    },
    workloadTimeLimitDuration: {
      handler(workloads: IWorkloadDurationOption[]) {
        this.$emit("update:scheduling-rules", {
          ...this.schedulingRules,
          trainingJobTimeLimitSeconds: this.getWorkloadDuration(workloads, EWorkloadTimeLimit.training),
          interactiveJobTimeLimitSeconds: this.getWorkloadDuration(workloads, EWorkloadTimeLimit.interactive),
        });
      },
      deep: true,
    },
    nodeAffinityWorkloads: {
      handler(newVal: INodeAffinitySelectOption[]) {
        const getNodeTypes = (workloadType: EWorkloadNodeAffinity) =>
          newVal
            .filter(({ value }: INodeAffinitySelectOption) => value === workloadType)
            .flatMap(({ selectedTypes }: INodeAffinitySelectOption) =>
              selectedTypes.map(({ value }) => value?.toString()),
            );

        const nodeTypes: NodeTypesPerWorkload = {};
        const trainingNodeTypes = getNodeTypes(EWorkloadNodeAffinity.Train);
        const workspaceNodeTypes = getNodeTypes(EWorkloadNodeAffinity.Interactive);

        if (trainingNodeTypes.length) nodeTypes.training = trainingNodeTypes as string[];
        if (workspaceNodeTypes.length) nodeTypes.workspace = workspaceNodeTypes as string[];

        this.$emit("update:node-types", nodeTypes);
      },
      deep: true,
    },
  },
});
</script>
