<template>
  <section class="auto-delete-time-section q-mb-lg">
    <div class="time-inputs-container row items-center justify-between q-pt-md">
      <div>
        <div class="q-pb-md">
          <span>Set the timeframe for auto-deletion after workload completion or failure</span>
          <runai-tooltip
            class="q-ml-md"
            width="535px"
            tooltip-position="right"
            tooltip-text="The timeframe after which a workload that has completed or failed will be automatically deleted. If the fields are set to 0, the workload will be deleted immediately after completion or failure."
          />
        </div>
        <runai-duration-input
          :rules="[notZeroValue]"
          :model-value="modelValue"
          @update:model-value="updateAutoDeletionTime"
          :disable="disable"
          :key="inputCmpKey"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { type PropType, defineComponent } from "vue";
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { errorMessages } from "@/common/error-message.constant";
import { RunaiDurationInput } from "@/components/common/runai-duration-input/";
export default defineComponent({
  name: "auto-delete-time-section",
  components: {
    RunaiDurationInput,
    RunaiTooltip,
  },
  emits: ["update:model-value"],
  props: {
    modelValue: {
      type: [Number, null, undefined] as PropType<number | null | undefined>,
      required: true,
    },
    disable: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      inputCmpKey: 1001,
    };
  },
  mounted() {
    // 30 days is the default for this property
    if (!this.modelValue && typeof this.modelValue !== "number") {
      const thirtyDaysInSeconds = 60 * 60 * 24 * 30;
      this.updateAutoDeletionTime(thirtyDaysInSeconds);
      this.inputCmpKey++;
    }
  },
  methods: {
    notZeroValue(): string | boolean {
      if (this.modelValue === null || this.modelValue === undefined) {
        return errorMessages.VALID_TIME_FRAME;
      }
      return this.modelValue >= 0 ? true : errorMessages.VALID_TIME_FRAME;
    },
    updateAutoDeletionTime(time: number | null): void {
      this.$emit("update:model-value", time);
    },
  },
});
</script>

<style lang="scss" scoped>
.time-inputs-container {
  gap: 10px;
}
</style>
