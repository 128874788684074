import type { IToolItem } from "@/models/workload.model";
import type { Inference } from "@/swagger-models/assets-service-client";

export enum EInferenceType {
  RunaiModel = "runai-model",
  HuggingFace = "hugging-face",
  Custom = "custom",
}

export enum EHuggingFaceEnvVariableNames {
  RUNAI_MODEL = "RUNAI_MODEL",
  HF_TOKEN = "HF_TOKEN",
  RUNAI_MODEL_NAME = "RUNAI_MODEL_NAME",
}

export const LLM_ENVIRONMENT_FOR_HUGGING_FACE = "llm-server";

export interface IInferenceList extends Inference {
  connections?: Array<IToolItem>;
  extendJobValues?: IExtendJobValues;
}
export interface IExtendJobValues {
  usedMemory?: string;
  usedCPUs?: string;
  gpusUtilization?: string;
  usedGpuMemory?: string;
  currentAllocatedGPUsMemoryBytes?: number;
}
