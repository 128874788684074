import { EProjectColumnName, type IProjectTable } from "@/models/project.model";
import { EResourceState, EResourceType } from "@/models/resource.model";
import { ECustomCell, type IStatusColOptions, type ITableColumn } from "@/models/table.model";
import { dateUtil } from "@/utils/date.util";
import { convertToBytes, memoryFormat, toPercent } from "@/utils/format.util";
import { resourceUtil } from "@/utils/resource.util";
import { CLUSTER_COLUMN_FILTER_NAME, EColumnFilterType } from "@/models/filter.model";
import { ProjectFilterSortFields, ProjectPhase, type Resources } from "@/swagger-models/org-unit-service-client";
import { orgUnitUtil } from "@/utils/org-unit.util";

export const allProjectColumnsMap: Record<string, ITableColumn> = {
  projectName: {
    name: ProjectFilterSortFields.Name,
    label: "Project",
    field: (row: IProjectTable) => row.name,
    sortable: true,
    align: "left",
    filter: {
      type: EColumnFilterType.FreeText,
    },
  },
  departmentName: {
    name: ProjectFilterSortFields.ParentName,
    label: "Department",
    field: (row: IProjectTable) => row?.parent?.name,
    sortable: true,
    align: "left",
    hideFilter: false,
  },
  status: {
    name: ProjectFilterSortFields.Phase,
    label: "Status",
    field: (row: IProjectTable) => row,
    sortable: true,
    align: "left",
    format: (project: IProjectTable): IStatusColOptions => orgUnitUtil.getStatusColOptions(project.status),
    customCell: ECustomCell.STATUS_COL,
    exportFormat: (project: IProjectTable) => project?.status?.phase || "NA",
    filter: {
      type: EColumnFilterType.EnumBased,
      selectOptions: Object.keys(ProjectPhase).map((key) => ({
        label: key,
        value: key,
      })),
    },
  },
  nodePools: {
    name: EProjectColumnName.NodePools,
    label: "Node pool(s)",
    field: (row: IProjectTable) => row.resources,
    sortable: false,
    align: "left",
    format: (resources: Resources[]): string[] => resources.map((resource) => resource.nodePool?.name || "") || [],
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "node-pools-clicked" },
    hideFilter: true,
  },
  accessRules: {
    name: EProjectColumnName.AccessRules,
    label: "Subject(s)",
    field: (row: IProjectTable) => row.rolesNames,
    sortable: false,
    align: "left",
    customCell: ECustomCell.ROLE_ASSIGMENT_COL,
    customCellEvent: { emitName: "access-rules-clicked" },
    hideFilter: true,
  },
  allocatedCpu: {
    name: EProjectColumnName.AllocatedCpu,
    label: "Allocated CPUs (Cores)",
    field: (row: IProjectTable) => row.status?.quotaStatus?.allocated?.cpu,
    sortable: false,
    align: "left",
    format: (allocatedCpu: number | undefined) =>
      allocatedCpu ? resourceUtil.getResourceDisplayValue(allocatedCpu, EResourceType.CPU) : "-",
    hideFilter: true,
  },
  allocatedMemory: {
    name: EProjectColumnName.AllocatedMemory,
    label: "Allocated CPU memory",
    field: (row: IProjectTable) => row.status?.quotaStatus?.allocated?.memory,
    sortable: false,
    align: "left",
    format: (allocatedMemory: number | undefined) =>
      allocatedMemory ? memoryFormat(convertToBytes(allocatedMemory, "MB")) : "-",
    hideFilter: true,
  },
  allocatedGpus: {
    name: ProjectFilterSortFields.GpuAllocated,
    label: "Allocated GPUs",
    field: (row: IProjectTable) => row.status?.quotaStatus?.allocated?.gpu,
    sortable: true,
    align: "left",
    format: (allocatedGpus: number | undefined) =>
      allocatedGpus ? resourceUtil.getResourceDisplayValue(allocatedGpus, EResourceType.GPU) : "-",
    filter: {
      type: EColumnFilterType.Numeric,
    },
  },
  gpuUtilization: {
    name: EProjectColumnName.GpuUtilization,
    label: "GPU allocation ratio",
    field: (row: IProjectTable) => row.status?.quotaStatus?.allocated?.gpu,
    sortable: false,
    align: "left",
    format: (allocatedGpus: number | undefined, project: IProjectTable) =>
      toPercent(allocatedGpus || 0, project?.totalResources?.gpuQuota || 0),
    hideFilter: true,
  },
  cpuUtilization: {
    name: EProjectColumnName.CpuUtilization,
    label: "CPU allocation ratio",
    field: (row: IProjectTable) => row.status?.quotaStatus?.allocated?.cpu,
    sortable: false,
    align: "left",
    format: (allocatedCpu: number | undefined, project: IProjectTable) =>
      toPercent(allocatedCpu || 0, project?.totalResources?.cpuQuota || 0),
    hideFilter: true,
  },
  memoryUtilization: {
    name: EProjectColumnName.MemoryUtilization,
    label: "CPU memory allocation ratio",
    field: (row: IProjectTable) => row.status?.quotaStatus?.allocated?.memory,
    sortable: true,
    align: "left",
    format: (allocatedMemory: number | undefined, project: IProjectTable) =>
      toPercent(resourceUtil.fromBytesToMiB(allocatedMemory || 0), project.totalResources?.memoryQuota || 0),
    hideFilter: true,
  },
  gpuQuota: {
    name: ProjectFilterSortFields.TotalGpuQuota,
    label: "GPU quota",
    field: (row: IProjectTable) =>
      resourceUtil.getResourceDisplayValue(row?.totalResources?.gpuQuota, EResourceType.GPU) || 0,
    sortable: true,
    align: "left",
    exportFormat: (row: IProjectTable) => row.totalResources?.gpuQuota,
    filter: {
      type: EColumnFilterType.Numeric,
    },
  },
  cpuQuota: {
    name: EProjectColumnName.CpuQuota,
    label: "CPU quota (Cores)",
    field: (row: IProjectTable) => row.totalResources?.cpuQuota,
    sortable: false,
    align: "left",
    format: (cpuQuota: number | null | undefined) => resourceUtil.getResourceDisplayValue(cpuQuota, EResourceType.CPU),
    hideFilter: true,
    exportFormat: (row: IProjectTable) => row.totalResources?.cpuQuota,
  },
  cpuMemoryQuota: {
    name: EProjectColumnName.CpuMemoryQuota,
    label: "CPU memory quota",
    field: (row: IProjectTable) => row.totalResources?.memoryQuota,
    sortable: false,
    align: "left",
    format: (memoryQuota: number | null | undefined) => {
      if (memoryQuota === null || memoryQuota === undefined) return EResourceState.Unlimited;
      const memoryQuotaInMib = resourceUtil.fromMbToMib(memoryQuota);
      return resourceUtil.getResourceDisplayValue(memoryQuotaInMib, EResourceType.MEMORY);
    },
    hideFilter: true,
    exportFormat: (row: IProjectTable) => row.totalResources?.memoryQuota,
  },
  nodeAffinityTrain: {
    name: EProjectColumnName.NodeAffinityTrain,
    label: "Node type (affinity) - Training",
    field: (row: IProjectTable) => {
      return (
        row?.effective?.nodeTypes?.training?.map((nodeTypeId: string) => {
          return row.effective?.nodeTypes?.names?.[nodeTypeId];
        }) || []
      );
    },
    sortable: false,
    align: "left",
    customCell: ECustomCell.STRINGS_LIST_COL,
    hideFilter: true,
  },
  nodeAffinityInteractive: {
    name: EProjectColumnName.NodeAffinityInteractive,
    label: "Node type (affinity) - Workspaces",
    field: (row: IProjectTable) => {
      return (
        row?.effective?.nodeTypes?.workspace?.map((nodeTypeId: string) => {
          return row.effective?.nodeTypes?.names?.[nodeTypeId];
        }) || []
      );
    },
    sortable: false,
    align: "left",
    customCell: ECustomCell.STRINGS_LIST_COL,
    hideFilter: true,
  },
  trainingJobMaxIdleDurationSecs: {
    name: EProjectColumnName.TrainingJobMaxIdleDurationSecs,
    label: "Idle GPU time limit - Training",
    field: (row: IProjectTable) => row?.effective?.schedulingRules?.trainingJobMaxIdleDurationSeconds,
    sortable: false,
    align: "left",
    format: (trainingJobMaxIdleDurationSecs) =>
      trainingJobMaxIdleDurationSecs ? dateUtil.formatDuration(trainingJobMaxIdleDurationSecs) : "-",
    hideFilter: true,
  },
  interactivePreemptibleJobMaxIdleDurationSecs: {
    name: EProjectColumnName.InteractivePreemptibleJobMaxIdleDurationSecs,
    label: "Idle GPU time limit - preemptible workspaces",
    field: (row: IProjectTable) => row?.effective?.schedulingRules?.interactiveJobPreemptIdleDurationSeconds,
    sortable: false,
    align: "left",
    format: (interactivePreemptibleJobMaxIdleDurationSecs) =>
      interactivePreemptibleJobMaxIdleDurationSecs
        ? dateUtil.formatDuration(interactivePreemptibleJobMaxIdleDurationSecs)
        : "-",
    hideFilter: true,
  },
  interactiveJobMaxIdleDurationSecs: {
    name: EProjectColumnName.InteractiveJobMaxIdleDurationSecs,
    label: "Idle GPU time limit - non-preemptible workspaces",
    field: (row: IProjectTable) => row?.effective?.schedulingRules?.interactiveJobMaxIdleDurationSeconds,
    sortable: false,
    align: "left",
    format: (interactiveJobMaxIdleDurationSecs) =>
      interactiveJobMaxIdleDurationSecs ? dateUtil.formatDuration(interactiveJobMaxIdleDurationSecs) : "-",
    hideFilter: true,
  },
  interactiveJobTimeLimitSecs: {
    name: EProjectColumnName.InteractiveJobTimeLimitSecs,
    label: "Workspaces time limit",
    field: (row: IProjectTable) => row?.effective?.schedulingRules?.interactiveJobTimeLimitSeconds,
    sortable: false,
    align: "left",
    format: (interactiveJobTimeLimitSecs) =>
      interactiveJobTimeLimitSecs ? dateUtil.formatDuration(interactiveJobTimeLimitSecs) : "-",
    hideFilter: true,
  },
  trainingJobTimeLimitSecs: {
    name: EProjectColumnName.TrainingJobTimeLimitSecs,
    label: "Training time limit",
    field: (row: IProjectTable) => row?.effective?.schedulingRules?.trainingJobTimeLimitSeconds,
    sortable: false,
    align: "left",
    format: (trainingJobTimeLimitSecs) =>
      trainingJobTimeLimitSecs ? dateUtil.formatDuration(trainingJobTimeLimitSecs) : "-",
    hideFilter: true,
  },
  createdAt: {
    name: ProjectFilterSortFields.CreatedAt,
    label: "Creation time",
    field: (row: IProjectTable) => row.createdAt,
    sortable: true,
    align: "left",
    format: (createdAt: string) => (createdAt ? dateUtil.dateAndTimeFormat(new Date(createdAt)) : "-"),
    filter: {
      type: EColumnFilterType.Date,
    },
  },
  workloads: {
    name: EProjectColumnName.Workloads,
    label: "Workload(s)",
    field: () => "View",
    align: "left",
    sortable: false,
    hideFilter: true,
    customCell: ECustomCell.LINK_COL,
    customCellEvent: { emitName: "workloads-clicked" },
  },
  cluster: {
    name: CLUSTER_COLUMN_FILTER_NAME,
    label: "Cluster",
    field: (row: IProjectTable): string | undefined => row.clusterId,
    sortable: true,
    align: "left",
    customCell: ECustomCell.CLUSTER_ID_TO_NAME_COL,
    hideFilter: true,
  },
};

export const allProjectColumns: Array<ITableColumn> = [
  allProjectColumnsMap.projectName,
  allProjectColumnsMap.departmentName,
  allProjectColumnsMap.status,
  allProjectColumnsMap.nodePools,
  allProjectColumnsMap.accessRules,
  allProjectColumnsMap.gpuQuota,
  allProjectColumnsMap.cpuQuota,
  allProjectColumnsMap.cpuMemoryQuota,
  allProjectColumnsMap.createdAt,
  allProjectColumnsMap.nodeAffinityTrain,
  allProjectColumnsMap.nodeAffinityInteractive,
  allProjectColumnsMap.interactiveJobMaxIdleDurationSecs,
  allProjectColumnsMap.trainingJobMaxIdleDurationSecs,
  allProjectColumnsMap.interactivePreemptibleJobMaxIdleDurationSecs,
  allProjectColumnsMap.interactiveJobTimeLimitSecs,
  allProjectColumnsMap.trainingJobTimeLimitSecs,
];

export const projectIndexColumns: Array<ITableColumn> = [
  { ...allProjectColumnsMap.projectName, display: true, mandatory: true },
  { ...allProjectColumnsMap.departmentName, display: true },
  { ...allProjectColumnsMap.cluster, display: true },
  { ...allProjectColumnsMap.status, display: true },
  { ...allProjectColumnsMap.nodePools, display: true },
  { ...allProjectColumnsMap.accessRules, display: true },
  { ...allProjectColumnsMap.allocatedGpus, display: true },
  { ...allProjectColumnsMap.allocatedCpu, display: true },
  { ...allProjectColumnsMap.allocatedMemory, display: true },
  { ...allProjectColumnsMap.gpuUtilization, display: true },
  { ...allProjectColumnsMap.cpuUtilization, display: true },
  { ...allProjectColumnsMap.memoryUtilization, display: true },
  { ...allProjectColumnsMap.gpuQuota, display: true },
  { ...allProjectColumnsMap.cpuQuota, display: true },
  { ...allProjectColumnsMap.cpuMemoryQuota, display: true },
  { ...allProjectColumnsMap.nodeAffinityTrain, display: false },
  { ...allProjectColumnsMap.nodeAffinityInteractive, display: false },
  { ...allProjectColumnsMap.trainingJobMaxIdleDurationSecs, display: false },
  { ...allProjectColumnsMap.interactivePreemptibleJobMaxIdleDurationSecs, display: false },
  { ...allProjectColumnsMap.interactiveJobMaxIdleDurationSecs, display: false },
  { ...allProjectColumnsMap.trainingJobTimeLimitSecs, display: false },
  { ...allProjectColumnsMap.interactiveJobTimeLimitSecs, display: false },
  { ...allProjectColumnsMap.createdAt, display: false },
  { ...allProjectColumnsMap.workloads, display: false },
];

export const projectMiniTableColumns = [
  { ...allProjectColumnsMap.projectName, display: true },
  { ...allProjectColumnsMap.gpuQuota, display: true },
  { ...allProjectColumnsMap.cpuQuota, display: true },
  { ...allProjectColumnsMap.cpuMemoryQuota, display: true },
  { ...allProjectColumnsMap.allocatedGpus, display: true },
  { ...allProjectColumnsMap.allocatedCpu, display: true },
  { ...allProjectColumnsMap.allocatedMemory, display: true },
  { ...allProjectColumnsMap.gpuUtilization, display: true },
  { ...allProjectColumnsMap.cpuUtilization, display: true },
];

//columns that displayed depends on feature flags or versions
export const projectDependentColumns = {
  cpu: new Set([
    allProjectColumnsMap.cpuMemoryQuota.name,
    allProjectColumnsMap.cpuQuota.name,
    allProjectColumnsMap.memoryUtilization.name,
    allProjectColumnsMap.cpuUtilization.name,
    allProjectColumnsMap.allocatedCpu.name,
    allProjectColumnsMap.allocatedMemory.name,
  ]),
  department: new Set([allProjectColumnsMap.departmentName.name]),
  nodePools: new Set([allProjectColumnsMap.nodePools.name]),
  accessRules: new Set([allProjectColumnsMap.accessRules.name]),
  workloads: new Set([allProjectColumnsMap.workloads.name]),
};
