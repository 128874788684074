import type { INodePoolResources } from "./project.model";
import type { NodePoolQuotaStatus } from "@/swagger-models/backend-client";
import type { Department } from "@/swagger-models/org-unit-service-client";

export const DEFAULT_DEPARTMENT_NAME = "default";
export const DEFAULT_DEPARTMENT_DESERVED_GPUS = -1;
/**
 * @deprecated This interface is deprecated and will be removed in future versions.
 */
export interface IDepartmentProject {
  name: string;
  id: number;
  deserved_gpus: number;
}

/**
 * @deprecated This interface is deprecated and will be removed in future versions.
 */
export interface IDepartment extends Department {
  name: string;
  deservedGpus?: number;
  maxAllowedGpus?: number | null;
  clusterUuid?: string;
  projectsDeservedGpus?: null | number;
  projects?: Array<IDepartmentProject>;
  nodePoolsResources: INodePoolResources[];
  assignedResourcesId: number;
  roles?: string[];
  allocatedCpu?: number;
  allocatedGpus?: number;
  allocatedMemory?: number;
  quotaStatuses?: Array<NodePoolQuotaStatus>;
}

export interface IDepartmentTable extends Department {
  rolesNames?: string[];
}

export enum EDepartmentColumnName {
  DepartmentName = "name",
  NodePools = "node-pools",
  GpuQuota = "gpu-quota",
  ProjectsGpus = "projects-gpus",
  AccessRules = "accessRules",
  Projects = "projects",
  CpuQuota = "cpu-quota",
  MemoryQuota = "memory-quota",
  AllocatedCpu = "allocated-cpu",
  AllocatedMemory = "allocated-memory",
  AllocatedGpus = "allocated-gpus",
  GpuUtilization = "gpu-utilization",
  CpuUtilization = "cpu-utilization",
  MemoryUtilization = "memory-utilization",
  CreatedAt = "createdAt",
  Workloads = "workloads",
}
