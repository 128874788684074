<template>
  <runai-expansion-item label="Department name" default-opened :section-invalid="sectionInvalid">
    <template #subheader>
      <span>
        {{ departmentName || "&lt;Department name&gt;" }}
      </span>
    </template>
    <runai-name-validation
      :disable="readOnly"
      :model-value="departmentName"
      @update:model-value="$emit('update:departmentName', $event)"
      :rules="[isValidEntityName, isNameUnique]"
    />
  </runai-expansion-item>
</template>
<script lang="ts">
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { RunaiNameValidation } from "@/components/common/runai-name-validation";
import { defineComponent, type PropType } from "vue";
import { isValidEntityName } from "@/common/form.validators";
import { errorMessages } from "@/common/error-message.constant";
import { orgUnitService } from "@/services/control-plane/org-unit.service/org-unit.service";
import { ErrorAlert } from "@/utils/error-alert.util";

export default defineComponent({
  name: "department-name-section",
  components: {
    RunaiNameValidation,
    RunaiExpansionItem,
  },
  emits: ["update:departmentName", "is-section-invalid"],
  props: {
    departmentName: {
      type: String as PropType<string>,
      required: true,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    clusterId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      isDepartmentNameUnique: true as boolean,
    };
  },
  computed: {
    sectionInvalid(): boolean {
      return !isValidEntityName(this.departmentName) || !this.isDepartmentNameUnique;
    },
  },
  methods: {
    isValidEntityName(name: string): boolean | string {
      return isValidEntityName(name) || errorMessages.VALID_FORMAT;
    },
    async isNameUnique(name: string): Promise<boolean | string> {
      try {
        const isNameUnique = await orgUnitService.isDepartmentNameUnique(this.clusterId, name);
        this.isDepartmentNameUnique = isNameUnique;
        if (!isNameUnique) {
          return errorMessages.NAME_ALREADY_EXIST;
        }
        return true;
      } catch (error: unknown) {
        const errorAlert = new ErrorAlert({
          generalMessage: ErrorAlert.failedToValidateMessage("department name"),
        });
        this.$q.notify(errorAlert.getNotification(error));
        console.error(error);
        return false;
      }
    },
  },
  watch: {
    sectionInvalid: {
      handler(isSectionInvalid: boolean): void {
        this.$emit("is-section-invalid", isSectionInvalid);
      },
      immediate: true,
    },
  },
});
</script>

<style scoped lang="scss"></style>
