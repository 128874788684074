<template>
  <section class="environment-variables-section">
    <div class="q-mb-md">
      Set the environment variable(s)
      <span v-if="showOverrideText"> (The workload creator will be able to override this) </span>
    </div>
    <environment-variable-box
      class="q-mb-md"
      v-for="(envVar, idx) in environmentVariablesWithIds"
      :key="envVar.id"
      :environment-variable="envVar"
      @update-environment-variable="updateEnvironmentVariable(idx, $event)"
      @remove-environment-variable="removeEnvironmentVariable(idx)"
      :credentials="credentials"
      :read-only="readOnly"
    />
    <q-btn
      aid="add-environment-variable-button"
      flat
      label="+ envrionment variable"
      color="primary"
      @click="addEnvironmentVariable"
      :disable="readOnly"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
// Components
import { EnvironmentVariableBox } from "./environment-variable-box";
// Models
import { type EnvironmentVariable } from "@/swagger-models/assets-service-client";
import { ISelectOption } from "@/models/global.model";
// Utils
import { makeId } from "@/utils/common.util";

interface EnvironmentVariableWithId extends EnvironmentVariable {
  id: string;
  name?: string | null;
  value?: string | null;
  credential?: { assetId?: null | string; key?: string | null } | null;
  exclude?: boolean | null;
}

export default defineComponent({
  name: "environment-variables-section",
  components: {
    EnvironmentVariableBox,
  },
  emits: ["update-environment-variables", "remove-environment-variable"],
  props: {
    environmentVariables: {
      type: Array as PropType<Array<EnvironmentVariable>>,
      required: false,
      default: () => [],
    },
    credentials: {
      type: Array as PropType<Array<ISelectOption>>,
      required: true,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    showOverrideText: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      // We keep a local state of the environment variables with an id to avoid rendering issues
      environmentVariablesWithIds: [] as Array<EnvironmentVariableWithId>,
    };
  },
  methods: {
    addEnvironmentVariable() {
      const newEnvVar: EnvironmentVariable = { name: "", value: "" };
      this.$emit("update-environment-variables", [...this.environmentVariables, newEnvVar]);
    },
    updateEnvironmentVariable(idx: number, envVar: EnvironmentVariableWithId) {
      this.environmentVariablesWithIds[idx] = envVar;
      this.$emit(
        "update-environment-variables",
        this.environmentVariablesWithIds.map((currEnvVar: EnvironmentVariableWithId): EnvironmentVariable => {
          // Removing the ID from the environment variable
          return { name: currEnvVar.name, value: currEnvVar.value, credential: currEnvVar.credential };
        }),
      );
    },
    removeEnvironmentVariable(idx: number) {
      this.$emit(
        "update-environment-variables",
        this.environmentVariables.filter((_: EnvironmentVariable, i: number) => i !== idx),
      );
    },
  },
  watch: {
    // We have a rendering issue due to no unique value between the environment variables
    // So we add a unique id to each environment variable when adding or deleting one
    environmentVariables: {
      handler(val: EnvironmentVariable[] | undefined, oldVal: EnvironmentVariable[] | undefined) {
        if (val?.length === oldVal?.length) {
          return;
        }
        this.environmentVariablesWithIds =
          val?.map((envVar: EnvironmentVariable) => ({
            ...envVar,
            id: makeId(6),
          })) || [];
      },
      deep: true,
      immediate: true,
    },
  },
});
</script>

<style scoped lang="scss"></style>
