import type {
  Department,
  DepartmentCreationRequest,
  DepartmentUpdateRequest,
  Project,
  ProjectCreationRequest,
  ProjectStatus,
  Resources,
  ResourcesCpu,
  ResourcesGpu,
  ResourcesMemory,
  ResourcesNodePool,
} from "@/swagger-models/org-unit-service-client";
import {
  EOverQuotaPriority,
  OVER_QUOTA_DISABLED_VALUE,
  OVER_QUOTA_ENABLED_VALUE,
  RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
} from "@/models/resource.model";
import { type ProjectUpdateRequest, Units } from "@/swagger-models/org-unit-service-client";
import type { INodePoolsNameAndId } from "@/models/node-pool.model";
import type { IStatusColOptions } from "@/models/table.model";
import { ProjectPhaseMap } from "@/models/project.model";
import { tableUtil } from "@/utils/table.util";
import type { IEmptyDepartmentModelConfig, IEmptyProjectModelConfig } from "@/models/org-unit.model";

export const orgUnitUtil = {
  getEmptyProjectModel,
  getEmptyResourcesModel,
  getEmptyDepartmentModel,
  getStatusColOptions,
  getNodePoolNameByResource,
  getNodePoolIdByResource,
  removeOverQuotaWeightFields,
  getProjectUpdateRequest,
  getDepartmentUpdateRequest,
  updateLimitOrOverQuota,
  enrichResourcesWithCpuAndMemory,
  initResourcesOverQuotaWeight,
};

function getEmptyProjectModel(config: IEmptyProjectModelConfig): ProjectCreationRequest {
  const emptyResources: Resources[] = getEmptyResourcesModel(config.nodePoolsNamesAndIds, config.isCpuEnabled);
  return {
    clusterId: config.clusterId,
    parentId: config.parentId,
    resources: emptyResources,
    name: "",
    description: "",
    requestedNamespace: "",
  };
}

function getEmptyDepartmentModel(config: IEmptyDepartmentModelConfig): DepartmentCreationRequest {
  const emptyResources: Resources[] = getEmptyResourcesModel(config.nodePoolsNamesAndIds, config.isCpuEnabled);
  return {
    clusterId: config.clusterId,
    resources: emptyResources,
    name: "",
    description: "",
  };
}

function getEmptyResourcesCpu(isCpuEnabled: boolean): ResourcesCpu | null {
  return isCpuEnabled
    ? {
        deserved: null,
        limit: RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
        overQuotaWeight: OVER_QUOTA_ENABLED_VALUE,
      }
    : null;
}
function getEmptyResourcesMemory(isCpuEnabled: boolean): ResourcesMemory | null {
  return isCpuEnabled
    ? {
        deserved: null,
        limit: RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
        overQuotaWeight: OVER_QUOTA_ENABLED_VALUE,
        units: Units.Mib,
      }
    : null;
}
function getEmptyResourcesModel(nodePools: INodePoolsNameAndId[], isCpuEnabled = false): Resources[] {
  return nodePools.map((nodePool) => {
    return {
      nodePool: {
        name: nodePool.name,
        id: nodePool.id,
      } as ResourcesNodePool,
      gpu: {
        deserved: 0,
        limit: RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
        overQuotaWeight: OVER_QUOTA_ENABLED_VALUE,
      } as ResourcesGpu,
      cpu: getEmptyResourcesCpu(isCpuEnabled),
      memory: getEmptyResourcesMemory(isCpuEnabled),
    };
  });
}

function enrichResourcesWithCpuAndMemory(resources: Resources[]): Resources[] {
  return resources.map((resource) => {
    if (!resource.cpu) {
      resource.cpu = getEmptyResourcesCpu(true);
    }
    if (!resource.memory) {
      resource.memory = getEmptyResourcesMemory(true);
    }

    return resource;
  });
}

function getStatusColOptions(status?: ProjectStatus): IStatusColOptions {
  if (!status || !status.phase)
    return {
      status: "-",
      tooltipText: "",
      displayAnimation: false,
      filterKey: "status",
    };
  const statusOptions: IStatusColOptions = ProjectPhaseMap[status.phase];
  return tableUtil.getStatusColOptions(statusOptions, status.phaseMessage || "");
}

function getNodePoolNameByResource(resources: Resources): string {
  return resources?.nodePool?.name || "";
}

function getNodePoolIdByResource(resources: Resources): string {
  return resources?.nodePool?.id || "";
}

function removeOverQuotaWeightFields(resources: Resources[]): Resources[] {
  return resources.map((resource) => {
    return {
      ...resource,
      gpu: {
        ...resource.gpu,
        overQuotaWeight: undefined,
      },
      cpu: resource.cpu
        ? {
            ...resource.cpu,
            overQuotaWeight: undefined,
          }
        : undefined,
      memory: resource.memory
        ? {
            ...resource.memory,
            overQuotaWeight: undefined,
          }
        : undefined,
    };
  });
}

function getProjectUpdateRequest(project: Project): ProjectUpdateRequest {
  return {
    description: project.description,
    schedulingRules: project.schedulingRules,
    defaultNodePools: project.defaultNodePools,
    nodeTypes: project.nodeTypes,
    resources: project.resources,
  };
}

function getDepartmentUpdateRequest(department: Department): DepartmentUpdateRequest {
  return {
    description: department.description,
    resources: department.resources,
  };
}

/**** limit and over quota weight update functions ****/

/**
 * Updates the overQuotaWeight of resources based on their limit.
 *
 * @param {Resources[]} resources - The array of resources to be updated.
 * @returns {Resources[]} - The updated array of resources.
 */
function updateOverQuotaWeightByLimit(resources: Resources[]): Resources[] {
  return resources.map((resource: Resources) => {
    const updatedResource = { ...resource };
    if (updatedResource.gpu.overQuotaWeight === EOverQuotaPriority.Lowest) {
      if (updatedResource.gpu.limit !== RESOURCE_MAX_ALLOWED_INFINITE_VALUE) {
        updatedResource.gpu.overQuotaWeight = EOverQuotaPriority.None;

        //CPU FF is enabled
        if (updatedResource?.cpu?.limit && updatedResource?.memory?.limit) {
          updatedResource.cpu.overQuotaWeight = EOverQuotaPriority.None;
          updatedResource.memory.overQuotaWeight = EOverQuotaPriority.None;
        }
      }
    }
    return updatedResource;
  });
}

/**
 * Updates the limit or deserved values of resources based on the over quota status.
 *
 * @param {Resources[]} resources - The array of resources to be updated.
 * @returns {Resources[]} - The updated array of resources.
 */
function updateLimitOrDeservedByOverQuota(resources: Resources[]): Resources[] {
  return resources.map((resource) => {
    const updatedResource = { ...resource };
    const isOverQuotaEnabled = updatedResource.gpu.overQuotaWeight === OVER_QUOTA_ENABLED_VALUE;

    /**
     * Updates the limit values to RESOURCE_MAX_ALLOWED_INFINITE_VALUE.
     */
    const updateLimitToOverQuota = () => {
      updatedResource.gpu.limit = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;

      //CPU FF is enabled
      if (updatedResource?.cpu?.limit && updatedResource?.memory?.limit) {
        updatedResource.cpu.limit = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
        updatedResource.memory.limit = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
      }
    };

    /**
     * Updates the limit values to the deserved values.
     */
    const updateLimitToDeserved = () => {
      updatedResource.gpu.limit = updatedResource.gpu.deserved;

      //CPU FF is enabled
      if (updatedResource?.cpu?.limit && updatedResource?.memory?.limit) {
        updatedResource.cpu.limit = updatedResource.cpu?.deserved;
        updatedResource.memory.limit = updatedResource.memory?.deserved;
      }
    };

    if (isOverQuotaEnabled) {
      updateLimitToOverQuota();
    } else {
      updateLimitToDeserved();
    }
    return resource;
  });
}

/**
 * we have two states in our app, over quota priority feature is disable (binary choice enabled/disabled) and over quota priority feature is enabled.
 * for the first state, we have two options for overQuotaWeight, OVER_QUOTA_ENABLED_VALUE and OVER_QUOTA_DISABLED_VALUE, and we update the overQuotaWeight based on the limit, on load, and  update limit or deserved values on submit.
 * for the second state, we have multiple options for overQuotaWeight, (None, Lowest, Low, Medium, High) none means no over quota so limit = deserved.
 * @param resources
 * @param isOverQuotaPriorityEnabled
 */
function updateLimitOrOverQuota(resources: Resources[], isOverQuotaPriorityEnabled: boolean): Resources[] {
  if (isOverQuotaPriorityEnabled) {
    return updateOverQuotaWeightByLimit(resources);
  } else {
    return updateLimitOrDeservedByOverQuota(resources);
  }
}

function initOverQuotaWeightByLimit(resources: Resources[]): Resources[] {
  return resources.map((nodePool: Resources) => {
    const updatedNodePool = { ...nodePool };
    if (nodePool.gpu.limit === RESOURCE_MAX_ALLOWED_INFINITE_VALUE) {
      updatedNodePool.gpu.overQuotaWeight = OVER_QUOTA_ENABLED_VALUE;
    } else {
      updatedNodePool.gpu.overQuotaWeight = OVER_QUOTA_DISABLED_VALUE;
    }

    return updatedNodePool;
  });
}

// init the over overQuotaWeight based on limit
function initResourcesOverQuotaWeight(resources: Resources[], isOverQuotaPriorityEnabled: boolean): Resources[] {
  if (isOverQuotaPriorityEnabled) {
    return updateOverQuotaWeightByLimit(resources);
  } else {
    return initOverQuotaWeightByLimit(resources);
  }
}
