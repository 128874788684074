<template>
  <section class="auto-scale-section">
    <section class="min-max-section">
      <div class="q-my-md">
        Set the minimum and maximum number of replicas for your inference
        <runai-tooltip :tooltip-text="minMaxTooltip" tooltip-position="right" width="450px" />
      </div>
      <div class="min-max-replicas q-my-lg">
        <div class="row">
          <span>
            <q-input
              class="replicas-input"
              type="number"
              :model-value="autoScaleData.minReplicas"
              @update:model-value="updateMin"
              stack-label
              label="Minimum"
              :min="minReplicasAllowed"
              :disable="disabled || isScaleDownToZeroSelected"
              no-error-icon
              ref="minRepInput"
              aid="min-rep-input"
            />
            <q-tooltip v-if="isScaleDownToZeroSelected">
              The minimum number of replicas is 0. This can't be changed when automatic scaling to zero is enabled.
            </q-tooltip>
          </span>

          <span class="col-1 row justify-center items-center q-pt-sm">
            <hr style="width: 10px; display: block" />
          </span>

          <q-input
            class="replicas-input"
            type="number"
            :model-value="autoScaleData.maxReplicas"
            @update:model-value="updateMax"
            stack-label
            label="Maximum"
            :min="1"
            no-error-icon
            :disable="disabled"
            :rules="[isMaxMorThanMin, isMaxMoreThanMaxReplicas]"
            ref="maxRepInput"
            aid="max-rep-input"
          />
        </div>
      </div>
    </section>

    <div class="dashed-seperator" />

    <q-slide-transition>
      <section class="new-replica-condition-section" v-if="showReplicaCondition">
        <div class="q-my-md">Set the conditions for creating a new replica</div>
        <div class="row new-replica-condition-container">
          <q-select
            aid="threshold-metric-options"
            class="col-6 threshold-metric-options"
            label="Variable"
            option-value="id"
            option-label="name"
            map-options
            :model-value="autoScaleData.thresholdMetric"
            @update:model-value="onThresholdMetricChanged"
            no-error-icon
            :options="thresholdMetricOptions"
            :disable="disabled"
          />
          <q-select
            class="col-2"
            label="Operator"
            :model-value="1"
            option-value="id"
            option-label="name"
            map-options
            :options="[{ id: 1, name: '>' }]"
            no-error-icon
            :disable="true"
            hide-dropdown-icon
          />
          <q-input
            class="col-2"
            type="number"
            :model-value="autoScaleData.thresholdValue"
            @update:model-value="onThresholdValueChanged"
            stack-label
            label="Value"
            min="1"
            :disable="disabled"
            no-error-icon
            :rules="[isValueEmpty, isGreaterThanZero]"
            ref="thresholdValueInput"
            aid="threshold-value-input"
          />
        </div>
      </section>
    </q-slide-transition>

    <section class="scale-down-section" v-if="showScaleDownToZeroValues">
      <div class="q-mt-md">
        Set when the replicas should be automatically scaled down to zero
        <runai-tooltip :tooltip-text="scaleDownTooltip" tooltip-position="right" width="315px" />
      </div>
      <div class="row items-center gap-15">
        <q-select
          aid="scale-down-select"
          class="scale-down-select col-6"
          option-label="name"
          :model-value="scaleDownSelected"
          @update:model-value="onScaleDownChanged"
          no-error-icon
          :options="scaleDownOptions"
          :disable="disabled"
          :rules="[() => true]"
        />
        <div v-if="isCustomScaleDownToZeroSelected" class="custom-auto-scale row flex-1 gap-15">
          <span class="custom-auto-scale-label">After</span>
          <q-input
            class="custom-scale-to-zero-input col-4"
            type="number"
            :model-value="customScaleDownSelected"
            @update:model-value="updateCustomScaleToZero"
            :min="1"
            :max="60"
            ref="customScaleToZeroInput"
            no-error-icon
            :rules="[isValidScaleToZero]"
          />

          <span class="custom-auto-scale-label">minutes of inactivity</span>
        </div>
      </div>
      <runai-information-bar v-if="isScaleDownToZeroSelected" class="q-mt-md">
        When automatic scaling to zero is enabled, the minimum number of replicas is 0
      </runai-information-bar>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// cmps
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { RunaiInformationBar } from "@/components/common/runai-information-bar";

import { errorMessages } from "@/common/error-message.constant";
import { isMaxEqualOrHigherThenMin, isValueLowerOrEqualToMax } from "@/common/form.validators";

// utils
import { is } from "quasar";

// models
import {
  type IAutoScaleData,
  type IThresholdMetricOption,
  type IScaleDownOption,
  scaleDownOptions,
  thresholdMetricOptions,
  MIN_REPLICAS,
  MAX_REPLICAS_ALLOWED,
  DEFAULT_THRESHOLD_VALUE,
  NEVER_SCALE_DOWN_ID,
  CUSTOM_SCALE_DOWN_ID,
  MIN_REPLICAS_WHEN_SCALE_TO_ZERO_SELECTED,
  SCALE_TO_ZERO_RETENTION_IN_SECONDS_DEFAULT_VALUE,
  THRESHOLD_DEFAULT_METRIC,
} from "./auto-scale-section.models";
import { AutoScalingMetric } from "@/swagger-models/assets-service-client";

function toMinutes(seconds: number): number {
  return seconds / 60;
}

function toSeconds(minutes: number): number {
  return minutes * 60;
}

export default defineComponent({
  components: {
    RunaiTooltip,
    RunaiInformationBar,
  },
  emits: ["on-auto-scale-changed"],
  props: {
    autoScaleData: {
      type: Object as PropType<IAutoScaleData>,
      required: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      minMaxTooltip:
        "Set the minimum and maximum number of replicas to be scaled up and down. If there is a difference between the two, you'll need to set conditions for auto-scaling." as string,
      scaleDownTooltip:
        "Compute resources can be freed up when the model is inactive (i.e., there are no requests being sent)" as string,
      maxNumberInvalidErrorMessage: errorMessages.EQUAL_TO_OR_HIGHER_THAN_THE_MINIMUM,
      scaleDownOptions: scaleDownOptions,
      scaleDownSelected: null as IScaleDownOption | null,
      customScaleDownSelected: SCALE_TO_ZERO_RETENTION_IN_SECONDS_DEFAULT_VALUE as number,
      thresholdMetricOptions: thresholdMetricOptions,
      minReplicasLastValueTyped: 1 as number,
      minimumInput: null as HTMLElement | null,
      maximumInput: null as HTMLElement | null,
      customScaleToZeroInput: null as HTMLElement | null,
      thresholdValueInput: null as HTMLElement | null,
      customScaleToZeroMessageError: "" as string,
      minMinutesForScaleToZero: 1 as number,
      maxMinutesForScaleToZero: 60 as number,
    };
  },
  created() {
    this.customScaleToZeroMessageError = errorMessages.ENTER_A_NUMBER_FROM_MIN_TO_MAX.replace(
      "${1}",
      this.minMinutesForScaleToZero.toString(),
    ).replace("${2}", this.maxMinutesForScaleToZero.toString());

    this.scaleDownSelected =
      scaleDownOptions.find((option) => option.id === String(this.autoScaleData.scaleToZeroRetentionSeconds)) || // check if the option is one of the predefined options
      (this.autoScaleData.scaleToZeroRetentionSeconds && this.autoScaleData.scaleToZeroRetentionSeconds > 0 // if not, check if the value is a custom value
        ? (scaleDownOptions[scaleDownOptions.length - 1] as IScaleDownOption) // last option is the custom one
        : (scaleDownOptions[0] as IScaleDownOption)); // if not, default to the first option (never)

    this.customScaleDownSelected = this.autoScaleData.scaleToZeroRetentionSeconds
      ? toMinutes(this.autoScaleData.scaleToZeroRetentionSeconds)
      : SCALE_TO_ZERO_RETENTION_IN_SECONDS_DEFAULT_VALUE;
  },
  mounted() {
    // Quasar input is incrementing its value on scroll if the window reaches the bottom of the page, therefore we blur the element whenever the scroll event is fired in order to prevent that
    this.addScrollListeners();
  },
  computed: {
    showScaleDownToZeroValues(): boolean {
      return this.autoScaleData.thresholdMetric !== AutoScalingMetric.Latency;
    },
    isCustomScaleToZeroRetentionSecondsInvalid(): boolean {
      return !is.number(this.customScaleDownSelected) || this.customScaleDownSelected <= 0;
    },
    isCustomScaleDownToZeroSelected(): boolean {
      return !!this.scaleDownSelected && this.scaleDownSelected.id == CUSTOM_SCALE_DOWN_ID;
    },
    isScaleDownToZeroSelected(): boolean {
      return !!this.scaleDownSelected && this.scaleDownSelected.id !== NEVER_SCALE_DOWN_ID;
    },
    minReplicasAllowed(): number {
      return this.isScaleDownToZeroSelected ? 0 : 1;
    },
    showReplicaCondition(): boolean {
      if (this.autoScaleData.minReplicas == 0 && this.autoScaleData.maxReplicas == 1) {
        return false;
      }
      return this.autoScaleData.maxReplicas > this.autoScaleData.minReplicas;
    },
    customScaleDownSelectedAsSeconds(): number {
      return toSeconds(this.customScaleDownSelected);
    },
  },
  methods: {
    isMaxMorThanMin(): boolean | string {
      return (
        isMaxEqualOrHigherThenMin(this.autoScaleData.maxReplicas, this.autoScaleData.minReplicas) ||
        errorMessages.EQUAL_TO_OR_HIGHER_THAN_THE_MINIMUM
      );
    },
    isMaxMoreThanMaxReplicas(): boolean | string {
      return (
        isValueLowerOrEqualToMax(this.autoScaleData.maxReplicas, MAX_REPLICAS_ALLOWED) ||
        `${errorMessages.ENTER_A_NUMBER_EQUAL_TO_OR_LOWER_THAN} 10,000`
      );
    },
    isValidScaleToZero(val: string): boolean | string {
      return (
        (Number(val) >= this.minMinutesForScaleToZero && Number(val) <= this.maxMinutesForScaleToZero) ||
        this.customScaleToZeroMessageError
      );
    },
    updateMin(num: number | string | null): void {
      const minReplicas: number = num && Number(num) > 0 ? Number(num) : 1;

      let thresholdMetric = this.autoScaleData.thresholdMetric;
      let thresholdValue = this.autoScaleData.thresholdValue;
      if (minReplicas >= this.autoScaleData.maxReplicas) {
        thresholdMetric = undefined;
        thresholdValue = undefined;
      } else if (thresholdMetric === undefined && thresholdValue === undefined) {
        thresholdMetric = THRESHOLD_DEFAULT_METRIC;
        thresholdValue = DEFAULT_THRESHOLD_VALUE;
      }

      this.updateModel({
        ...this.autoScaleData,
        minReplicas,
        thresholdMetric,
        thresholdValue,
      });
    },

    updateCustomScaleToZero(minutes: number | string | null): void {
      this.customScaleDownSelected = Math.round(Number(minutes));
      this.updateModel({
        ...this.autoScaleData,
        scaleToZeroRetentionSeconds: minutes ? this.customScaleDownSelectedAsSeconds : undefined,
      });
    },
    updateMax(max: number | string | null): void {
      const maxReplicas: number = max ? Number(max) : 1;
      let thresholdMetric = this.autoScaleData.thresholdMetric;
      let thresholdValue = this.autoScaleData.thresholdValue;
      if (this.autoScaleData.minReplicas >= maxReplicas) {
        thresholdMetric = undefined;
        thresholdValue = undefined;
      } else if (thresholdMetric === undefined && thresholdValue === undefined) {
        thresholdMetric = THRESHOLD_DEFAULT_METRIC;
        thresholdValue = DEFAULT_THRESHOLD_VALUE;
      }

      this.updateModel({
        ...this.autoScaleData,
        maxReplicas,
        thresholdMetric,
        thresholdValue,
      });
    },
    onScaleDownChanged(option: IScaleDownOption): void {
      this.scaleDownSelected = option;
      if (this.isScaleDownToZeroSelected) {
        this.minReplicasLastValueTyped = this.autoScaleData.minReplicas;
        this.updateModel({
          ...this.autoScaleData,
          minReplicas: MIN_REPLICAS_WHEN_SCALE_TO_ZERO_SELECTED,
          scaleToZeroRetentionSeconds: Number(this.scaleDownSelected.id),
        });
      } else {
        this.updateModel({
          ...this.autoScaleData,
          minReplicas: this.minReplicasLastValueTyped,
          scaleToZeroRetentionSeconds: undefined,
        });
      }
    },
    getScaleDownOptionById(id: string): IScaleDownOption | undefined {
      return this.scaleDownOptions.find((option) => option.id === id);
    },
    onThresholdMetricChanged(value: IThresholdMetricOption): void {
      const autoScaleData: IAutoScaleData = {
        ...this.autoScaleData,
        thresholdMetric: value.id,
        thresholdValue: DEFAULT_THRESHOLD_VALUE,
      };

      if (value.id === AutoScalingMetric.Latency) {
        autoScaleData.scaleToZeroRetentionSeconds = undefined;
        autoScaleData.minReplicas = MIN_REPLICAS;
        const scaleDownSelected: IScaleDownOption | undefined = this.getScaleDownOptionById(NEVER_SCALE_DOWN_ID);
        if (scaleDownSelected) {
          this.scaleDownSelected = scaleDownSelected;
        }
      }

      this.updateModel(autoScaleData);
    },
    onThresholdValueChanged(value: number | string | null): void {
      this.updateModel({
        ...this.autoScaleData,
        thresholdValue: value ? Number(value) : undefined,
      });
    },
    updateModel(autoScaleData: IAutoScaleData): void {
      this.$emit("on-auto-scale-changed", autoScaleData);
    },
    isValueEmpty(val: string): boolean | string {
      return is.number(val) || errorMessages.ENTER_A_VALUE;
    },
    isGreaterThanZero(val: string): boolean | string {
      return (is.number(val) && Number(val) > 0) || errorMessages.VALUE_ABOVE_ZERO;
    },
    blurMinimumInput(): void {
      this.minimumInput?.blur();
    },
    blurMaximumInput(): void {
      this.maximumInput?.blur();
    },
    blurCustomScaleToZeroInput(): void {
      this.customScaleToZeroInput?.blur();
    },
    blurThresholdValueInputInput(): void {
      this.thresholdValueInput?.blur();
    },
    addScrollListeners(): void {
      this.minimumInput = this.$refs.minRepInput as HTMLElement | null;
      this.maximumInput = this.$refs.maxRepInput as HTMLElement | null;
      this.customScaleToZeroInput = this.$refs.customScaleToZeroInput as HTMLElement | null;
      window.addEventListener("scroll", this.blurMinimumInput);
      window.addEventListener("scroll", this.blurMaximumInput);
      window.addEventListener("scroll", this.blurCustomScaleToZeroInput);
    },
    removeScrollListeners(): void {
      window.removeEventListener("scroll", this.blurMinimumInput);
      window.removeEventListener("scroll", this.blurMaximumInput);
      window.removeEventListener("scroll", this.blurCustomScaleToZeroInput);

      if (this.thresholdValueInput) {
        window.removeEventListener("scroll", this.blurThresholdValueInputInput);
      }
    },
  },
  watch: {
    showReplicaCondition(isShow: boolean): void {
      if (isShow) {
        this.thresholdValueInput = this.$refs.thresholdValueInput as HTMLElement | null;
        window.addEventListener("scroll", this.blurThresholdValueInputInput);
      } else {
        window.removeEventListener("scroll", this.blurThresholdValueInputInput);
        this.thresholdValueInput = null;
      }
    },
  },
  beforeUnmount() {
    // Quasar input is incrementing its value on scroll if the window reaches the bottom of the page, therefore we blur the element whenever the scroll event is fired in order to prevent that
    this.removeScrollListeners();
  },
});
</script>
<style lang="scss" scoped>
.auto-scale-section {
  .replicas-input {
    width: 160px;
  }
  .scale-down-select {
    width: 215px;
  }

  .dashed-seperator {
    border: 1px dashed $black-12;
  }

  .custom-auto-scale-label {
    margin-top: 17px;
  }
  .new-replica-condition-section {
    .new-replica-condition-container {
      gap: 45px;
      background-color: $body-background-color;
      padding: 15px;
    }
  }
}
</style>
