<template>
  <quota-management-section
    :section-invalid="sectionInvalid"
    :resources="department.resources"
    :quota-statuses="quotaStatuses"
    @update:resources="$emit('update:resources', $event)"
    :entity="EQuotaEntity.department"
    :cluster-id="department.clusterId"
    :read-only="readOnly"
    :loading="loading"
  >
    <template #over-quota-toggle>
      <q-toggle
        :disable="readOnly"
        left-label
        label="Allow department to go over quota"
        :model-value="isOverQuotaOn"
        @update:model-value="allowGpuOverQuotaChanged"
      />
      <runai-tooltip
        tooltip-position="right"
        width="320px"
        tooltip-text="Departments that have quota overage disabled are limited to
                    using the GPUs assigned to them. Departments that have it
                    enabled can use GPUs beyond their quota, if needed and
                    available."
      />
    </template>
  </quota-management-section>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { QuotaManagementSection } from "@/components/project/project-edit-form/quota-management-section";
import { DEFAULT_DEPARTMENT_DESERVED_GPUS } from "@/models/department.model";
import {
  EQuotaEntity,
  OVER_QUOTA_DISABLED_VALUE,
  OVER_QUOTA_ENABLED_VALUE,
  RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
} from "@/models/resource.model";
import { useSettingStore } from "@/stores/setting.store";
import type {
  Department,
  DepartmentCreationRequest,
  QuotaStatusNodePool,
  Resources,
} from "@/swagger-models/org-unit-service-client";
import { orgUnitUtil } from "@/utils/org-unit.util";

export default defineComponent({
  name: "department-quota-management-section",
  components: { QuotaManagementSection, RunaiTooltip },
  emits: ["update:resources", "is-section-invalid"],
  props: {
    department: {
      type: Object as PropType<Department | DepartmentCreationRequest>,
      required: true,
    },
    resources: {
      type: Array as PropType<Array<Resources>>,
      required: true,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    quotaStatuses: {
      type: Array as PropType<Array<QuotaStatusNodePool>>,
      default: () => [],
    },
  },
  data() {
    return {
      isOverQuotaOn: true as boolean,
      settingStore: useSettingStore(),
    };
  },
  created() {
    const isOverQuotaOn = this.department.resources.some(
      (resources) => resources.gpu.limit === RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
    );
    this.allowGpuOverQuotaChanged(isOverQuotaOn);
  },
  computed: {
    sectionInvalid(): boolean {
      return this.department.resources.some((resources) => resources.gpu.deserved === DEFAULT_DEPARTMENT_DESERVED_GPUS);
    },
    EQuotaEntity(): typeof EQuotaEntity {
      return EQuotaEntity;
    },
  },
  methods: {
    allowGpuOverQuotaChanged(isOverQuotaOn: boolean): void {
      this.isOverQuotaOn = isOverQuotaOn;
      //todo: should be remove this loop when moving department to use over quota priority node pool based - ask alon for the relevant ticket
      this.department.resources.forEach((resources) => {
        resources.gpu.overQuotaWeight = this.isOverQuotaOn ? OVER_QUOTA_ENABLED_VALUE : OVER_QUOTA_DISABLED_VALUE;
      });
      const updatedResources = orgUnitUtil.updateLimitOrOverQuota(this.department.resources, false);
      this.$emit("update:resources", updatedResources);
    },
  },
  watch: {
    sectionInvalid: {
      handler(isSectionInvalid: boolean): void {
        this.$emit("is-section-invalid", isSectionInvalid);
      },
      immediate: true,
    },
  },
});
</script>

<style scoped lang="scss"></style>
