/* tslint:disable */
/* eslint-disable */
/**
 * Runai API
 * # Introduction  The Run:AI Control-Plane API reference is a guide that provides an easy-to-use programming interface for adding various tasks to your application, including workload submission, resource management, and administrative operations.  Run:ai APIs are accessed using *bearer tokens*. To obtain a token, you need to create an **Application** through the Run:ai user interface. To create an application, in your UI, go to `Settings & Tools`, `Application` and create a new Application.  After you have created a new application, you will need to assign it access rules. To assign access rules to the application, see [Create access rules](https://docs.run.ai/latest/admin/runai-setup/access-control/rbac/?h=create+delete+app#create-or-delete-rules). Make sure you assign the correct rules to your application. Use the [Roles](https://docs.run.ai/latest/admin/runai-setup/access-control/rbac/?h=create+delete+app#roles) to assign the correct access rules.  To get your access token, follow the instructions in [Request a token](https://docs.run.ai/latest/developer/rest-auth/#request-an-api-token). 
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AgentSyncDataDepricated
 */
export interface AgentSyncDataDepricated {
    /**
     * 
     * @type {string}
     * @memberof AgentSyncDataDepricated
     */
    'version': string;
    /**
     * 
     * @type {Array<PodGroup>}
     * @memberof AgentSyncDataDepricated
     */
    'podGroups'?: Array<PodGroup>;
    /**
     * 
     * @type {Array<PodGroup>}
     * @memberof AgentSyncDataDepricated
     */
    'pods'?: Array<PodGroup>;
}
/**
 * The ancestors of an org unit.
 * @export
 * @interface Ancestors
 */
export interface Ancestors {
    /**
     * 
     * @type {string}
     * @memberof Ancestors
     */
    'tenant_id': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof Ancestors
     */
    'cluster_uuid'?: string | null;
    /**
     * The id of the department.
     * @type {string}
     * @memberof Ancestors
     */
    'department_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Ancestors
     */
    'project_id'?: string | null;
}
/**
 * 
 * @export
 * @interface App
 */
export interface App {
    /**
     * 
     * @type {AuthEntityType}
     * @memberof App
     */
    'entityType'?: AuthEntityType;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof App
     */
    'tenantId'?: number;
    /**
     * Unique identifier of the user
     * @type {string}
     * @memberof App
     */
    'userId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof App
     */
    'permitAllClusters'?: boolean;
    /**
     * A list of clusters that the user or application can access.
     * @type {Array<string>}
     * @memberof App
     */
    'permittedClusters'?: Array<string>;
    /**
     * 
     * @type {Array<Role>}
     * @memberof App
     */
    'roles'?: Array<Role>;
    /**
     * The creation date of the application.
     * @type {string}
     * @memberof App
     */
    'createdAt'?: string;
    /**
     * The client ID of the application.
     * @type {string}
     * @memberof App
     */
    'clientId'?: string;
    /**
     * The name of the application.
     * @type {string}
     * @memberof App
     */
    'name': string;
    /**
     * Whether the application has been revoked.
     * @type {boolean}
     * @memberof App
     */
    'revoked'?: boolean;
}
/**
 * 
 * @export
 * @interface AppAllOf
 */
export interface AppAllOf {
    /**
     * The client ID of the application.
     * @type {string}
     * @memberof AppAllOf
     */
    'clientId'?: string;
    /**
     * The name of the application.
     * @type {string}
     * @memberof AppAllOf
     */
    'name'?: string;
    /**
     * Whether the application has been revoked.
     * @type {boolean}
     * @memberof AppAllOf
     */
    'revoked'?: boolean;
}
/**
 * 
 * @export
 * @interface AssignedResources
 */
export interface AssignedResources {
    /**
     * 
     * @type {number}
     * @memberof AssignedResources
     */
    'id'?: number;
    /**
     * 
     * @type {AssignedResourcesGpu}
     * @memberof AssignedResources
     */
    'gpu'?: AssignedResourcesGpu;
    /**
     * 
     * @type {AssignedResourcesCpu}
     * @memberof AssignedResources
     */
    'cpu'?: AssignedResourcesCpu;
    /**
     * 
     * @type {AssignedResourcesMemory}
     * @memberof AssignedResources
     */
    'memory'?: AssignedResourcesMemory;
}
/**
 * CPU Millicores assigned. Supported only if \'CPU Resources Quota\' feature flag is enabled.
 * @export
 * @interface AssignedResourcesCpu
 */
export interface AssignedResourcesCpu {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof AssignedResourcesCpu
     */
    'deserved'?: number;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof AssignedResourcesCpu
     */
    'maxAllowed'?: number;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof AssignedResourcesCpu
     */
    'overQuotaWeight'?: number;
}
/**
 * GPU number assigned
 * @export
 * @interface AssignedResourcesGpu
 */
export interface AssignedResourcesGpu {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof AssignedResourcesGpu
     */
    'deserved'?: number;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof AssignedResourcesGpu
     */
    'maxAllowed'?: number;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof AssignedResourcesGpu
     */
    'overQuotaWeight'?: number;
}
/**
 * CPU Memory Mib assigned. Supported only if \'CPU Resources Quota\' feature flag is enabled.
 * @export
 * @interface AssignedResourcesMemory
 */
export interface AssignedResourcesMemory {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof AssignedResourcesMemory
     */
    'deserved'?: number;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof AssignedResourcesMemory
     */
    'maxAllowed'?: number;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof AssignedResourcesMemory
     */
    'overQuotaWeight'?: number;
}
/**
 * 
 * @export
 * @interface AuditLogRecord
 */
export interface AuditLogRecord {
    /**
     * The unique id of the audit log.
     * @type {number}
     * @memberof AuditLogRecord
     */
    'id'?: number;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof AuditLogRecord
     */
    'clusterUuid'?: string;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof AuditLogRecord
     */
    'tenantId'?: number;
    /**
     * The date and time in which the event happened.
     * @type {string}
     * @memberof AuditLogRecord
     */
    'happenedAt'?: string;
    /**
     * The action that was performed by the user.
     * @type {string}
     * @memberof AuditLogRecord
     */
    'action'?: string;
    /**
     * The version of the audit log record.
     * @type {string}
     * @memberof AuditLogRecord
     */
    'version'?: string;
    /**
     * The id of the action related entity.
     * @type {string}
     * @memberof AuditLogRecord
     */
    'entityId'?: string;
    /**
     * The type of the action related entity.
     * @type {string}
     * @memberof AuditLogRecord
     */
    'entityType'?: string;
    /**
     * The name of the action related entity.
     * @type {string}
     * @memberof AuditLogRecord
     */
    'entityName'?: string;
    /**
     * The type of the source of the action.
     * @type {string}
     * @memberof AuditLogRecord
     */
    'sourceType'?: string;
    /**
     * The id of the source of the action.
     * @type {string}
     * @memberof AuditLogRecord
     */
    'sourceId'?: string;
    /**
     * The name of the source of the action.
     * @type {string}
     * @memberof AuditLogRecord
     */
    'sourceName'?: string;
    /**
     * In case of a failed action, the corresponding error
     * @type {string}
     * @memberof AuditLogRecord
     */
    'error'?: string;
    /**
     * The context of the action.
     * @type {object}
     * @memberof AuditLogRecord
     */
    'context'?: object;
    /**
     * The body of the action http request.
     * @type {object}
     * @memberof AuditLogRecord
     */
    'body'?: object;
}
/**
 * 
 * @export
 * @interface AuthEntity
 */
export interface AuthEntity {
    /**
     * 
     * @type {AuthEntityType}
     * @memberof AuthEntity
     */
    'entityType'?: AuthEntityType;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof AuthEntity
     */
    'tenantId'?: number;
    /**
     * Unique identifier of the user
     * @type {string}
     * @memberof AuthEntity
     */
    'userId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthEntity
     */
    'permitAllClusters'?: boolean;
    /**
     * A list of clusters that the user or application can access.
     * @type {Array<string>}
     * @memberof AuthEntity
     */
    'permittedClusters'?: Array<string>;
    /**
     * 
     * @type {Array<Role>}
     * @memberof AuthEntity
     */
    'roles'?: Array<Role>;
    /**
     * The creation date of the application.
     * @type {string}
     * @memberof AuthEntity
     */
    'createdAt'?: string;
}
/**
 * The type of authentication entity.
 * @export
 * @enum {string}
 */

export const AuthEntityType = {
    RegularUser: 'regular-user',
    SsoUser: 'sso-user',
    App: 'app',
    Group: 'group',
    InternalUser: 'internal-user',
    SuperAdmin: 'super-admin'
} as const;

export type AuthEntityType = typeof AuthEntityType[keyof typeof AuthEntityType];


/**
 * 
 * @export
 * @interface Cluster
 */
export interface Cluster {
    /**
     * 
     * @type {ClusterMetadata}
     * @memberof Cluster
     */
    'metadata': ClusterMetadata;
    /**
     * 
     * @type {ClusterCurrent}
     * @memberof Cluster
     */
    'current': ClusterCurrent;
    /**
     * 
     * @type {TimeRange}
     * @memberof Cluster
     */
    'timeRange'?: TimeRange;
}
/**
 * 
 * @export
 * @interface Cluster1
 */
export interface Cluster1 {
    /**
     * The cluster ID.
     * @type {string}
     * @memberof Cluster1
     */
    'uuid'?: string;
    /**
     * The cluster\'s creation date.
     * @type {string}
     * @memberof Cluster1
     */
    'createdAt'?: string;
    /**
     * The cluster\'s url.
     * @type {string}
     * @memberof Cluster1
     */
    'domain'?: string;
    /**
     * The tenant id this cluster belongs to.
     * @type {number}
     * @memberof Cluster1
     */
    'tenantId'?: number;
    /**
     * The cluster\'s name.
     * @type {string}
     * @memberof Cluster1
     */
    'name'?: string;
    /**
     * The cluster\'s name.
     * @type {string}
     * @memberof Cluster1
     */
    'description'?: string;
    /**
     * The cluster\'s  RUN:AI version.
     * @type {string}
     * @memberof Cluster1
     */
    'version'?: string;
    /**
     * if the cluster is connected to the control plane.
     * @type {boolean}
     * @memberof Cluster1
     */
    'connected'?: boolean;
    /**
     * 
     * @type {Cluster1Status}
     * @memberof Cluster1
     */
    'status'?: Cluster1Status;
}
/**
 * 
 * @export
 * @interface Cluster1Status
 */
export interface Cluster1Status {
    /**
     * The cluster\'s state.
     * @type {string}
     * @memberof Cluster1Status
     */
    'state'?: Cluster1StatusStateEnum;
    /**
     * The last time the cluster was connected to the control plane.
     * @type {string}
     * @memberof Cluster1Status
     */
    'lastConnected'?: string;
    /**
     * 
     * @type {Array<ClusterReportedStatusConditionsInner>}
     * @memberof Cluster1Status
     */
    'conditions'?: Array<ClusterReportedStatusConditionsInner>;
    /**
     * OperandStatuses specifies the status of the managed operands
     * @type {{ [key: string]: ClusterReportedStatusOperandsValue; }}
     * @memberof Cluster1Status
     */
    'operands'?: { [key: string]: ClusterReportedStatusOperandsValue; };
    /**
     * 
     * @type {ClusterDependenciesStatus}
     * @memberof Cluster1Status
     */
    'dependencies'?: ClusterDependenciesStatus;
}

export const Cluster1StatusStateEnum = {
    WaitingToConnect: 'WaitingToConnect',
    Connected: 'Connected',
    Disconnected: 'Disconnected',
    MissingPrerequisites: 'MissingPrerequisites',
    ServiceIssues: 'ServiceIssues',
    Unknown: 'Unknown'
} as const;

export type Cluster1StatusStateEnum = typeof Cluster1StatusStateEnum[keyof typeof Cluster1StatusStateEnum];

/**
 * The cluster status fields that are calculated by the control plane.
 * @export
 * @interface ClusterCalculatedStatus
 */
export interface ClusterCalculatedStatus {
    /**
     * The cluster\'s state.
     * @type {string}
     * @memberof ClusterCalculatedStatus
     */
    'state'?: ClusterCalculatedStatusStateEnum;
    /**
     * The last time the cluster was connected to the control plane.
     * @type {string}
     * @memberof ClusterCalculatedStatus
     */
    'lastConnected'?: string;
}

export const ClusterCalculatedStatusStateEnum = {
    WaitingToConnect: 'WaitingToConnect',
    Connected: 'Connected',
    Disconnected: 'Disconnected',
    MissingPrerequisites: 'MissingPrerequisites',
    ServiceIssues: 'ServiceIssues',
    Unknown: 'Unknown'
} as const;

export type ClusterCalculatedStatusStateEnum = typeof ClusterCalculatedStatusStateEnum[keyof typeof ClusterCalculatedStatusStateEnum];

/**
 * Current reported metrics data. resources holds data that is summed up to the cluster level and projectResources explains the division of it to projects
 * @export
 * @interface ClusterCurrent
 */
export interface ClusterCurrent {
    /**
     * 
     * @type {Array<ClusterCurrentResourcesInner>}
     * @memberof ClusterCurrent
     */
    'resources': Array<ClusterCurrentResourcesInner>;
    /**
     * 
     * @type {Array<ClusterCurrentProjectResourcesInner>}
     * @memberof ClusterCurrent
     */
    'projectResources': Array<ClusterCurrentProjectResourcesInner>;
}
/**
 * 
 * @export
 * @interface ClusterCurrentProjectResourcesInner
 */
export interface ClusterCurrentProjectResourcesInner {
    /**
     * 
     * @type {number}
     * @memberof ClusterCurrentProjectResourcesInner
     */
    'numberOfPendingWorkloads': number;
    /**
     * 
     * @type {ResourceData}
     * @memberof ClusterCurrentProjectResourcesInner
     */
    'gpu': ResourceData;
    /**
     * 
     * @type {ResourceData}
     * @memberof ClusterCurrentProjectResourcesInner
     */
    'cpu': ResourceData;
    /**
     * 
     * @type {ResourceData}
     * @memberof ClusterCurrentProjectResourcesInner
     */
    'memory': ResourceData;
    /**
     * 
     * @type {string}
     * @memberof ClusterCurrentProjectResourcesInner
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterCurrentProjectResourcesInner
     */
    'departmentName': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterCurrentProjectResourcesInner
     */
    'nodepoolName': string;
}
/**
 * 
 * @export
 * @interface ClusterCurrentProjectResourcesInnerAllOf
 */
export interface ClusterCurrentProjectResourcesInnerAllOf {
    /**
     * 
     * @type {string}
     * @memberof ClusterCurrentProjectResourcesInnerAllOf
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterCurrentProjectResourcesInnerAllOf
     */
    'departmentName': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterCurrentProjectResourcesInnerAllOf
     */
    'nodepoolName': string;
}
/**
 * 
 * @export
 * @interface ClusterCurrentResourcesInner
 */
export interface ClusterCurrentResourcesInner {
    /**
     * 
     * @type {number}
     * @memberof ClusterCurrentResourcesInner
     */
    'numberOfPendingWorkloads': number;
    /**
     * 
     * @type {ResourceData}
     * @memberof ClusterCurrentResourcesInner
     */
    'gpu': ResourceData;
    /**
     * 
     * @type {ResourceData}
     * @memberof ClusterCurrentResourcesInner
     */
    'cpu': ResourceData;
    /**
     * 
     * @type {ResourceData}
     * @memberof ClusterCurrentResourcesInner
     */
    'memory': ResourceData;
    /**
     * 
     * @type {string}
     * @memberof ClusterCurrentResourcesInner
     */
    'nodepoolName': string;
}
/**
 * 
 * @export
 * @interface ClusterCurrentResourcesInnerAllOf
 */
export interface ClusterCurrentResourcesInnerAllOf {
    /**
     * 
     * @type {string}
     * @memberof ClusterCurrentResourcesInnerAllOf
     */
    'nodepoolName': string;
}
/**
 * 
 * @export
 * @interface ClusterDependenciesStatus
 */
export interface ClusterDependenciesStatus {
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterDependenciesStatus
     */
    'required': { [key: string]: ClusterDependencyStatus; };
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterDependenciesStatus
     */
    'optional': { [key: string]: ClusterDependencyStatus; };
}
/**
 * 
 * @export
 * @interface ClusterDependencyStatus
 */
export interface ClusterDependencyStatus {
    /**
     * whether the dependency is available
     * @type {boolean}
     * @memberof ClusterDependencyStatus
     */
    'available': boolean;
    /**
     * the reason for the dependency status
     * @type {string}
     * @memberof ClusterDependencyStatus
     */
    'reason'?: string;
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterDependencyStatus
     */
    'components'?: { [key: string]: ClusterDependencyStatus; };
}
/**
 * 
 * @export
 * @interface ClusterInfo
 */
export interface ClusterInfo {
    /**
     * the cluster domain
     * @type {string}
     * @memberof ClusterInfo
     */
    'domain': string;
    /**
     * the cluster version
     * @type {string}
     * @memberof ClusterInfo
     */
    'version': string;
    /**
     * 
     * @type {ClusterDependenciesStatus}
     * @memberof ClusterInfo
     */
    'dependencies'?: ClusterDependenciesStatus;
    /**
     * 
     * @type {ClusterReportedStatus}
     * @memberof ClusterInfo
     */
    'status'?: ClusterReportedStatus;
}
/**
 * 
 * @export
 * @interface ClusterMetaData
 */
export interface ClusterMetaData {
    /**
     * The cluster ID.
     * @type {string}
     * @memberof ClusterMetaData
     */
    'uuid'?: string;
    /**
     * The tenant id this cluster belongs to.
     * @type {number}
     * @memberof ClusterMetaData
     */
    'tenantId'?: number;
    /**
     * The cluster\'s name.
     * @type {string}
     * @memberof ClusterMetaData
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ClusterMetadata
 */
export interface ClusterMetadata {
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof ClusterMetadata
     */
    'clusterId': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterMetadata
     */
    'clusterName': string;
}
/**
 * the cluster status reported by the cluster
 * @export
 * @interface ClusterReportedStatus
 */
export interface ClusterReportedStatus {
    /**
     * 
     * @type {Array<ClusterReportedStatusConditionsInner>}
     * @memberof ClusterReportedStatus
     */
    'conditions'?: Array<ClusterReportedStatusConditionsInner>;
    /**
     * OperandStatuses specifies the status of the managed operands
     * @type {{ [key: string]: ClusterReportedStatusOperandsValue; }}
     * @memberof ClusterReportedStatus
     */
    'operands'?: { [key: string]: ClusterReportedStatusOperandsValue; };
    /**
     * 
     * @type {ClusterDependenciesStatus}
     * @memberof ClusterReportedStatus
     */
    'dependencies'?: ClusterDependenciesStatus;
}
/**
 * Condition contains details for one aspect of the current state of this API Resource
 * @export
 * @interface ClusterReportedStatusConditionsInner
 */
export interface ClusterReportedStatusConditionsInner {
    /**
     * lastTransitionTime is the last time the condition transitioned from one status to another. This should be when the underlying condition changed.  If that is not known, then using the time when the API field changed is acceptable.
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'lastTransitionTime': string;
    /**
     * message is a human readable message indicating details about the transition. This may be an empty string.
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'message': string;
    /**
     * observedGeneration represents the .metadata.generation that the condition was set based upon. For instance, if .metadata.generation is currently 12, but the .status.conditions[x].observedGeneration is 9, the condition is out of date with respect to the current state of the instance.
     * @type {number}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'observedGeneration'?: number;
    /**
     * reason contains a programmatic identifier indicating the reason for the condition\'s last transition. Producers of specific condition types may define expected values and meanings for this field, and whether the values are considered a guaranteed API. The value should be a CamelCase string. This field may not be empty.
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'reason': string;
    /**
     * status of the condition, one of True, False, Unknown.
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'status': ClusterReportedStatusConditionsInnerStatusEnum;
    /**
     * type of condition in CamelCase or in foo.example.com/CamelCase. --- Many .condition.type values are consistent across resources like Available, but because arbitrary conditions can be useful (see .node.status.conditions), the ability to deconflict is important. The regex it matches is (dns1123SubdomainFmt/)?(qualifiedNameFmt)
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'type': string;
}

export const ClusterReportedStatusConditionsInnerStatusEnum = {
    True: 'True',
    False: 'False',
    Unknown: 'Unknown'
} as const;

export type ClusterReportedStatusConditionsInnerStatusEnum = typeof ClusterReportedStatusConditionsInnerStatusEnum[keyof typeof ClusterReportedStatusConditionsInnerStatusEnum];

/**
 * Status specifies the status of an operand. And operand manages resources, some of which are not immediately available and need their status monitored, such as Deployments, Daemonsets, Ingressess etc. Some resources are immediately available and should not be monitored once accepted by the cluster, such as Services, ConfigMaps, Secrets etc. If all resources of an operand are ready, a Status with Ready set to \'true\' and a nil \'Reasons\' slice should be returned, otherwise, a Status with \'Reasons\' slice containig the reason why the Operand is not ready (Deployment pods are not ready for example.)
 * @export
 * @interface ClusterReportedStatusOperandsValue
 */
export interface ClusterReportedStatusOperandsValue {
    /**
     * LastTransitionTime specifies the last time the operand readiness changed
     * @type {string}
     * @memberof ClusterReportedStatusOperandsValue
     */
    'lastTransitionTime'?: string;
    /**
     * Ready specifies if the operand is ready or not
     * @type {boolean}
     * @memberof ClusterReportedStatusOperandsValue
     */
    'ready': boolean;
    /**
     * Reasons specifies the reasons why the operand is not ready
     * @type {Array<string>}
     * @memberof ClusterReportedStatusOperandsValue
     */
    'reasons'?: Array<string>;
    /**
     * UnreadyThresholdCrossed specifies if the operand has been unready for longer than the threshold
     * @type {boolean}
     * @memberof ClusterReportedStatusOperandsValue
     */
    'unreadyThresholdCrossed'?: boolean;
}
/**
 * 
 * @export
 * @interface ComplianceIssues
 */
export interface ComplianceIssues {
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof ComplianceIssues
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface ComplianceIssuesComplianceIssuesInner
 */
export interface ComplianceIssuesComplianceIssuesInner {
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'details': string;
}
/**
 * 
 * @export
 * @interface Config
 */
export interface Config {
    /**
     * 
     * @type {string}
     * @memberof Config
     */
    'tenantName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Config
     */
    'authClientName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Config
     */
    'authUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Config
     */
    'authRealm'?: string;
}
/**
 * 
 * @export
 * @interface Container
 */
export interface Container {
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    'image': string;
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateCluster
 */
export interface CreateCluster {
    /**
     * 
     * @type {string}
     * @memberof CreateCluster
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCluster
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface Dashboards
 */
export interface Dashboards {
    /**
     * 
     * @type {string}
     * @memberof Dashboards
     */
    'promProxy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dashboards
     */
    'logoutPage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dashboards
     */
    'loginPage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dashboards
     */
    'nowDashboard'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dashboards
     */
    'longTermDashboard'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dashboards
     */
    'clustersDashboard'?: string;
}
/**
 * 
 * @export
 * @interface Department
 */
export interface Department {
    /**
     * 
     * @type {DepartmentMetadata}
     * @memberof Department
     */
    'metadata': DepartmentMetadata;
    /**
     * 
     * @type {DepartmentCurrent}
     * @memberof Department
     */
    'current': DepartmentCurrent;
    /**
     * 
     * @type {TimeRange}
     * @memberof Department
     */
    'timeRange'?: TimeRange;
}
/**
 * 
 * @export
 * @interface Department1
 */
export interface Department1 {
    /**
     * Resources assigned to the Department per node pool.
     * @type {Array<NodePoolAssignedResources>}
     * @memberof Department1
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResources>;
    /**
     * The tenant id this cluster belongs to.
     * @type {number}
     * @memberof Department1
     */
    'tenantId'?: number;
    /**
     * The cluster UUID this department belongs to.
     * @type {string}
     * @memberof Department1
     */
    'clusterUuid'?: string;
    /**
     * The creation date of the department.
     * @type {string}
     * @memberof Department1
     */
    'createdAt'?: string;
    /**
     * The unique id identifying the department.
     * @type {number}
     * @memberof Department1
     */
    'id'?: number;
    /**
     * Projects under this department.
     * @type {Array<Project1>}
     * @memberof Department1
     */
    'projects'?: Array<Project1>;
    /**
     * Deprecated. Instead, use \'nodePoolsResources\' field. Total deserved GPUs of the projects under this department - as string.
     * @type {string}
     * @memberof Department1
     * @deprecated
     */
    'projectsDeservedGpus'?: string;
    /**
     * Id\'s of users with department admin role that are assigned to managed the department
     * @type {Array<string>}
     * @memberof Department1
     */
    'departmentAdmins'?: Array<string>;
    /**
     * The name of the department.
     * @type {string}
     * @memberof Department1
     */
    'name'?: string;
    /**
     * Deprecated. Instead, use `deserved` for the relevant resource type under `NodePoolResources`. Deserved GPUs for the department.
     * @type {number}
     * @memberof Department1
     * @deprecated
     */
    'deservedGpus'?: number;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Is over quota allowed for the department.
     * @type {boolean}
     * @memberof Department1
     * @deprecated
     */
    'allowOverQuota'?: boolean;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Max allowed GPUs for the department.
     * @type {number}
     * @memberof Department1
     * @deprecated
     */
    'maxAllowedGpus'?: number;
    /**
     * 
     * @type {DepartmentCommonFieldsResources}
     * @memberof Department1
     * @deprecated
     */
    'resources'?: DepartmentCommonFieldsResources;
}
/**
 * 
 * @export
 * @interface Department1AllOf
 */
export interface Department1AllOf {
    /**
     * Resources assigned to the Department per node pool.
     * @type {Array<NodePoolAssignedResources>}
     * @memberof Department1AllOf
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResources>;
    /**
     * The tenant id this cluster belongs to.
     * @type {number}
     * @memberof Department1AllOf
     */
    'tenantId'?: number;
    /**
     * The cluster UUID this department belongs to.
     * @type {string}
     * @memberof Department1AllOf
     */
    'clusterUuid'?: string;
    /**
     * The creation date of the department.
     * @type {string}
     * @memberof Department1AllOf
     */
    'createdAt'?: string;
    /**
     * The unique id identifying the department.
     * @type {number}
     * @memberof Department1AllOf
     */
    'id'?: number;
    /**
     * Projects under this department.
     * @type {Array<Project1>}
     * @memberof Department1AllOf
     */
    'projects'?: Array<Project1>;
    /**
     * Deprecated. Instead, use \'nodePoolsResources\' field. Total deserved GPUs of the projects under this department - as string.
     * @type {string}
     * @memberof Department1AllOf
     * @deprecated
     */
    'projectsDeservedGpus'?: string;
    /**
     * Id\'s of users with department admin role that are assigned to managed the department
     * @type {Array<string>}
     * @memberof Department1AllOf
     */
    'departmentAdmins'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DepartmentAccessControl
 */
export interface DepartmentAccessControl {
    /**
     * The unique id identifying the department.
     * @type {number}
     * @memberof DepartmentAccessControl
     */
    'departmentId'?: number;
    /**
     * Id\'s of users with department admin role that are assigned to managed the department
     * @type {Array<string>}
     * @memberof DepartmentAccessControl
     */
    'departmentAdmins'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DepartmentCommonFields
 */
export interface DepartmentCommonFields {
    /**
     * The name of the department.
     * @type {string}
     * @memberof DepartmentCommonFields
     */
    'name'?: string;
    /**
     * Deprecated. Instead, use `deserved` for the relevant resource type under `NodePoolResources`. Deserved GPUs for the department.
     * @type {number}
     * @memberof DepartmentCommonFields
     * @deprecated
     */
    'deservedGpus'?: number;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Is over quota allowed for the department.
     * @type {boolean}
     * @memberof DepartmentCommonFields
     * @deprecated
     */
    'allowOverQuota'?: boolean;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Max allowed GPUs for the department.
     * @type {number}
     * @memberof DepartmentCommonFields
     * @deprecated
     */
    'maxAllowedGpus'?: number;
    /**
     * 
     * @type {DepartmentCommonFieldsResources}
     * @memberof DepartmentCommonFields
     * @deprecated
     */
    'resources'?: DepartmentCommonFieldsResources;
}
/**
 * Deprecated. Instead, use \'nodePoolsResources\'. Total resources assigned to the Department. Can only be used in PUT/POST when there is a single Node Pool in the system. The resources returned in GET are the sum of all Node Pool Resources.
 * @export
 * @interface DepartmentCommonFieldsResources
 */
export interface DepartmentCommonFieldsResources {
    /**
     * 
     * @type {number}
     * @memberof DepartmentCommonFieldsResources
     */
    'id'?: number;
    /**
     * 
     * @type {AssignedResourcesGpu}
     * @memberof DepartmentCommonFieldsResources
     */
    'gpu'?: AssignedResourcesGpu;
    /**
     * 
     * @type {AssignedResourcesCpu}
     * @memberof DepartmentCommonFieldsResources
     */
    'cpu'?: AssignedResourcesCpu;
    /**
     * 
     * @type {AssignedResourcesMemory}
     * @memberof DepartmentCommonFieldsResources
     */
    'memory'?: AssignedResourcesMemory;
}
/**
 * 
 * @export
 * @interface DepartmentCreateRequest
 */
export interface DepartmentCreateRequest {
    /**
     * Resources assigned to this Department per Node Pool
     * @type {Array<NodePoolAssignedResourcesCreate>}
     * @memberof DepartmentCreateRequest
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResourcesCreate>;
    /**
     * The name of the department.
     * @type {string}
     * @memberof DepartmentCreateRequest
     */
    'name': string;
    /**
     * Deprecated. Instead, use `deserved` for the relevant resource type under `NodePoolResources`. Deserved GPUs for the department.
     * @type {number}
     * @memberof DepartmentCreateRequest
     * @deprecated
     */
    'deservedGpus'?: number;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Is over quota allowed for the department.
     * @type {boolean}
     * @memberof DepartmentCreateRequest
     * @deprecated
     */
    'allowOverQuota'?: boolean;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Max allowed GPUs for the department.
     * @type {number}
     * @memberof DepartmentCreateRequest
     * @deprecated
     */
    'maxAllowedGpus'?: number;
    /**
     * 
     * @type {DepartmentCommonFieldsResources}
     * @memberof DepartmentCreateRequest
     * @deprecated
     */
    'resources'?: DepartmentCommonFieldsResources;
}
/**
 * 
 * @export
 * @interface DepartmentCreateRequestAllOf
 */
export interface DepartmentCreateRequestAllOf {
    /**
     * Resources assigned to this Department per Node Pool
     * @type {Array<NodePoolAssignedResourcesCreate>}
     * @memberof DepartmentCreateRequestAllOf
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResourcesCreate>;
}
/**
 * Current reported metrics data. resources holds data that is summed up to the department level and projectResources explains the division of it to projects
 * @export
 * @interface DepartmentCurrent
 */
export interface DepartmentCurrent {
    /**
     * 
     * @type {Array<ClusterCurrentResourcesInner>}
     * @memberof DepartmentCurrent
     */
    'resources': Array<ClusterCurrentResourcesInner>;
    /**
     * 
     * @type {Array<DepartmentCurrentProjectResourcesInner>}
     * @memberof DepartmentCurrent
     */
    'projectResources': Array<DepartmentCurrentProjectResourcesInner>;
}
/**
 * 
 * @export
 * @interface DepartmentCurrentProjectResourcesInner
 */
export interface DepartmentCurrentProjectResourcesInner {
    /**
     * 
     * @type {number}
     * @memberof DepartmentCurrentProjectResourcesInner
     */
    'numberOfPendingWorkloads': number;
    /**
     * 
     * @type {ResourceData}
     * @memberof DepartmentCurrentProjectResourcesInner
     */
    'gpu': ResourceData;
    /**
     * 
     * @type {ResourceData}
     * @memberof DepartmentCurrentProjectResourcesInner
     */
    'cpu': ResourceData;
    /**
     * 
     * @type {ResourceData}
     * @memberof DepartmentCurrentProjectResourcesInner
     */
    'memory': ResourceData;
    /**
     * 
     * @type {string}
     * @memberof DepartmentCurrentProjectResourcesInner
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentCurrentProjectResourcesInner
     */
    'nodepoolName': string;
}
/**
 * 
 * @export
 * @interface DepartmentCurrentProjectResourcesInnerAllOf
 */
export interface DepartmentCurrentProjectResourcesInnerAllOf {
    /**
     * 
     * @type {string}
     * @memberof DepartmentCurrentProjectResourcesInnerAllOf
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentCurrentProjectResourcesInnerAllOf
     */
    'nodepoolName': string;
}
/**
 * 
 * @export
 * @interface DepartmentMetadata
 */
export interface DepartmentMetadata {
    /**
     * The id of the department
     * @type {number}
     * @memberof DepartmentMetadata
     */
    'departmentId': number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentMetadata
     */
    'departmentName': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof DepartmentMetadata
     */
    'clusterId': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentMetadata
     */
    'clusterName': string;
}
/**
 * 
 * @export
 * @interface DepartmentUpdateRequest
 */
export interface DepartmentUpdateRequest {
    /**
     * Resources assigned to this Department per Node Pool
     * @type {Array<NodePoolAssignedResources>}
     * @memberof DepartmentUpdateRequest
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResources>;
    /**
     * The name of the department.
     * @type {string}
     * @memberof DepartmentUpdateRequest
     */
    'name': string;
    /**
     * Deprecated. Instead, use `deserved` for the relevant resource type under `NodePoolResources`. Deserved GPUs for the department.
     * @type {number}
     * @memberof DepartmentUpdateRequest
     * @deprecated
     */
    'deservedGpus'?: number;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Is over quota allowed for the department.
     * @type {boolean}
     * @memberof DepartmentUpdateRequest
     * @deprecated
     */
    'allowOverQuota'?: boolean;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Max allowed GPUs for the department.
     * @type {number}
     * @memberof DepartmentUpdateRequest
     * @deprecated
     */
    'maxAllowedGpus'?: number;
    /**
     * 
     * @type {DepartmentCommonFieldsResources}
     * @memberof DepartmentUpdateRequest
     * @deprecated
     */
    'resources'?: DepartmentCommonFieldsResources;
}
/**
 * 
 * @export
 * @interface DepartmentUpdateRequestAllOf
 */
export interface DepartmentUpdateRequestAllOf {
    /**
     * Resources assigned to this Department per Node Pool
     * @type {Array<NodePoolAssignedResources>}
     * @memberof DepartmentUpdateRequestAllOf
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResources>;
}
/**
 * 
 * @export
 * @interface Departments
 */
export interface Departments {
    /**
     * 
     * @type {Array<DepartmentsDataInner>}
     * @memberof Departments
     */
    'data': Array<DepartmentsDataInner>;
    /**
     * 
     * @type {TimeRange}
     * @memberof Departments
     */
    'timeRange'?: TimeRange;
}
/**
 * 
 * @export
 * @interface DepartmentsDataInner
 */
export interface DepartmentsDataInner {
    /**
     * 
     * @type {DepartmentMetadata}
     * @memberof DepartmentsDataInner
     */
    'metadata': DepartmentMetadata;
    /**
     * 
     * @type {DepartmentCurrent}
     * @memberof DepartmentsDataInner
     */
    'current': DepartmentCurrent;
    /**
     * 
     * @type {any}
     * @memberof DepartmentsDataInner
     */
    'timeRange'?: any;
}
/**
 * 
 * @export
 * @interface DepartmentsDataInnerAllOf
 */
export interface DepartmentsDataInnerAllOf {
    /**
     * 
     * @type {any}
     * @memberof DepartmentsDataInnerAllOf
     */
    'timeRange'?: any;
}
/**
 * 
 * @export
 * @interface Deployment
 */
export interface Deployment {
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'clusterUuid': string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'project'?: string;
    /**
     * 
     * @type {Array<Owner>}
     * @memberof Deployment
     */
    'owners': Array<Owner>;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof Deployment
     */
    'replicas': number;
    /**
     * 
     * @type {PodTemplate}
     * @memberof Deployment
     */
    'template': PodTemplate;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'schedulerName': string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'username'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Deployment
     */
    'connections': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'serviceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'revisionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'inferenceworkloadName'?: string;
    /**
     * 
     * @type {Array<DeploymentCondition>}
     * @memberof Deployment
     */
    'conditions'?: Array<DeploymentCondition>;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'nodePool'?: string;
}
/**
 * 
 * @export
 * @interface DeploymentCondition
 */
export interface DeploymentCondition {
    /**
     * 
     * @type {string}
     * @memberof DeploymentCondition
     */
    'type': DeploymentConditionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DeploymentCondition
     */
    'status': DeploymentConditionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DeploymentCondition
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentCondition
     */
    'message'?: string;
}

export const DeploymentConditionTypeEnum = {
    Available: 'Available',
    Progressing: 'Progressing',
    ReplicaFailure: 'ReplicaFailure'
} as const;

export type DeploymentConditionTypeEnum = typeof DeploymentConditionTypeEnum[keyof typeof DeploymentConditionTypeEnum];
export const DeploymentConditionStatusEnum = {
    True: 'true',
    False: 'false',
    Unknown: 'Unknown'
} as const;

export type DeploymentConditionStatusEnum = typeof DeploymentConditionStatusEnum[keyof typeof DeploymentConditionStatusEnum];

/**
 * 
 * @export
 * @interface DeploymentsSync
 */
export interface DeploymentsSync {
    /**
     * The project name we are syncing objects in
     * @type {string}
     * @memberof DeploymentsSync
     */
    'projectName': string;
    /**
     * 
     * @type {Array<Deployment>}
     * @memberof DeploymentsSync
     */
    'deployments': Array<Deployment>;
}
/**
 * 
 * @export
 * @interface DisplayedJob
 */
export interface DisplayedJob {
    /**
     * Unique identifier of the job.
     * @type {string}
     * @memberof DisplayedJob
     */
    'JobId'?: string;
    /**
     * Unique identifier of the pod group.
     * @type {string}
     * @memberof DisplayedJob
     */
    'podGroupId'?: string;
    /**
     * The name of the job.
     * @type {string}
     * @memberof DisplayedJob
     */
    'jobName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayedJob
     */
    'jobType'?: string;
    /**
     * Unique identifier of the cluster.
     * @type {string}
     * @memberof DisplayedJob
     */
    'clusterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayedJob
     */
    'status'?: string;
    /**
     * The name of the image executed by the pod.
     * @type {string}
     * @memberof DisplayedJob
     */
    'imageName'?: string;
    /**
     * The owner of the job.
     * @type {string}
     * @memberof DisplayedJob
     */
    'user'?: string;
    /**
     * The project that the pod group belongs to.
     * @type {string}
     * @memberof DisplayedJob
     */
    'project'?: string;
    /**
     * Unique identifier of the node.
     * @type {string}
     * @memberof DisplayedJob
     */
    'nodeId'?: string;
    /**
     * Creation time of the job.
     * @type {string}
     * @memberof DisplayedJob
     */
    'creationTime'?: string;
    /**
     * Completion time of the job.
     * @type {string}
     * @memberof DisplayedJob
     */
    'completionTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayedJob
     */
    'totalRuntime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayedJob
     */
    'totalWaitTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'pending'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'running'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'parallelism'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'completions'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'failed'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'succeeded'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'currentAllocatedGPUs'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'currentAllocatedGPUsMemory'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'currentRequestedGPUs'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'totalRequestedGPUs'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'requestedGPUsPerPodGroup'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'requestedGPUsMemoryPerPodGroup'?: number;
    /**
     * 
     * @type {string}
     * @memberof DisplayedJob
     */
    'parentWorkloadName'?: string;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'totalRequestedMemory'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'totalRequestedCPU'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'totalLimitCPU'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'totalLimitMemory'?: number;
    /**
     * Specifies the kind of k8s resource that owns the pod group.
     * @type {number}
     * @memberof DisplayedJob
     */
    'workloadKind'?: number;
    /**
     * 
     * @type {Pod}
     * @memberof DisplayedJob
     */
    'latestPod'?: Pod;
    /**
     * 
     * @type {string}
     * @memberof DisplayedJob
     */
    'cliCommand'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayedJob
     */
    'requestedMigDevices'?: string;
    /**
     * 
     * @type {object}
     * @memberof DisplayedJob
     */
    'dynamicData'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof DisplayedJob
     */
    'existsInCluster'?: boolean;
    /**
     * If true, it indicates that the pod group runs jupyter notebook.
     * @type {boolean}
     * @memberof DisplayedJob
     */
    'isJupyter'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DisplayedJob
     */
    'jobUrl'?: string;
    /**
     * The node pool of the job.
     * @type {string}
     * @memberof DisplayedJob
     */
    'nodePool'?: string;
}
/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'uid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'reason'?: string;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'creationTimestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'sourceComponent'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'involvedObjUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'involvedObjKind'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'involvedObjName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'involvedObjNamespace'?: string;
}
/**
 * 
 * @export
 * @interface EventsSyncData
 */
export interface EventsSyncData {
    /**
     * 
     * @type {Array<Event>}
     * @memberof EventsSyncData
     */
    'events'?: Array<Event>;
}
/**
 * 
 * @export
 * @interface GetOrgTree200Response
 */
export interface GetOrgTree200Response {
    /**
     * 
     * @type {Array<OrgUnit>}
     * @memberof GetOrgTree200Response
     */
    'orgUnits': Array<OrgUnit>;
}
/**
 * 
 * @export
 * @interface GetScopeName200Response
 */
export interface GetScopeName200Response {
    /**
     * 
     * @type {string}
     * @memberof GetScopeName200Response
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'entityType'?: string;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof Group
     */
    'tenantId'?: number;
    /**
     * Group\'s name
     * @type {string}
     * @memberof Group
     */
    'userId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Group
     */
    'permitAllClusters'?: boolean;
    /**
     * A list of clusters that the user or application can access.
     * @type {Array<string>}
     * @memberof Group
     */
    'permittedClusters'?: Array<string>;
    /**
     * 
     * @type {Array<Role>}
     * @memberof Group
     */
    'roles'?: Array<Role>;
    /**
     * uid of the user
     * @type {string}
     * @memberof Group
     */
    'createdBy'?: string;
}
/**
 * 
 * @export
 * @interface GroupCreationRequest
 */
export interface GroupCreationRequest {
    /**
     * Group\'s name
     * @type {string}
     * @memberof GroupCreationRequest
     */
    'username': string;
    /**
     * Empty email address
     * @type {string}
     * @memberof GroupCreationRequest
     */
    'email': string;
    /**
     * 
     * @type {Array<Role>}
     * @memberof GroupCreationRequest
     */
    'roles': Array<Role>;
    /**
     * 
     * @type {string}
     * @memberof GroupCreationRequest
     */
    'entityType'?: string;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof GroupCreationRequest
     */
    'tenantId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GroupCreationRequest
     */
    'permitAllClusters'?: boolean;
    /**
     * Group\'s name
     * @type {string}
     * @memberof GroupCreationRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupCreationRequest
     */
    'permittedClusters'?: Array<string>;
    /**
     * uid of the user
     * @type {string}
     * @memberof GroupCreationRequest
     */
    'createdBy'?: string;
}
/**
 * 
 * @export
 * @interface GroupCreationResponse
 */
export interface GroupCreationResponse {
    /**
     * Group\'s name
     * @type {string}
     * @memberof GroupCreationResponse
     */
    'username': string;
    /**
     * Empty email address
     * @type {string}
     * @memberof GroupCreationResponse
     */
    'email': string;
    /**
     * 
     * @type {Array<Role>}
     * @memberof GroupCreationResponse
     */
    'roles': Array<Role>;
    /**
     * 
     * @type {string}
     * @memberof GroupCreationResponse
     */
    'entityType'?: string;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof GroupCreationResponse
     */
    'tenantId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GroupCreationResponse
     */
    'permitAllClusters'?: boolean;
    /**
     * Group\'s name
     * @type {string}
     * @memberof GroupCreationResponse
     */
    'userId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupCreationResponse
     */
    'permittedClusters'?: Array<string>;
    /**
     * uid of the user
     * @type {string}
     * @memberof GroupCreationResponse
     */
    'createdBy'?: string;
}
/**
 * 
 * @export
 * @interface GroupWithName
 */
export interface GroupWithName {
    /**
     * 
     * @type {string}
     * @memberof GroupWithName
     */
    'entityType'?: string;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof GroupWithName
     */
    'tenantId'?: number;
    /**
     * Group\'s name
     * @type {string}
     * @memberof GroupWithName
     */
    'userId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupWithName
     */
    'permitAllClusters'?: boolean;
    /**
     * A list of clusters that the user or application can access.
     * @type {Array<string>}
     * @memberof GroupWithName
     */
    'permittedClusters'?: Array<string>;
    /**
     * 
     * @type {Array<Role>}
     * @memberof GroupWithName
     */
    'roles'?: Array<Role>;
    /**
     * uid of the user
     * @type {string}
     * @memberof GroupWithName
     */
    'createdBy'?: string;
    /**
     * group name
     * @type {string}
     * @memberof GroupWithName
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface GroupWithNameAllOf
 */
export interface GroupWithNameAllOf {
    /**
     * group name
     * @type {string}
     * @memberof GroupWithNameAllOf
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface Groups
 */
export interface Groups {
    /**
     * 
     * @type {Array<User1>}
     * @memberof Groups
     */
    'groups': Array<User1>;
}
/**
 * 
 * @export
 * @interface IdsResponse
 */
export interface IdsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof IdsResponse
     */
    'ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Job
 */
export interface Job {
    /**
     * 
     * @type {JobMetadata}
     * @memberof Job
     */
    'metadata': JobMetadata;
    /**
     * 
     * @type {JobCurrent}
     * @memberof Job
     */
    'current': JobCurrent;
    /**
     * 
     * @type {JobTimeRange}
     * @memberof Job
     */
    'timeRange'?: JobTimeRange;
}
/**
 * 
 * @export
 * @interface JobAdvancedData
 */
export interface JobAdvancedData {
    /**
     * 
     * @type {number}
     * @memberof JobAdvancedData
     */
    'idleSeconds'?: number;
    /**
     * The fraction of time any portion of the graphics or compute engines were active
     * @type {number}
     * @memberof JobAdvancedData
     */
    'grEngineActive'?: number;
    /**
     * The fraction of cycles where data was sent to or received from device memory
     * @type {number}
     * @memberof JobAdvancedData
     */
    'dramActive'?: number;
    /**
     * The fraction of time at least one warp was active on a multiprocessor, averaged over all multiprocessors
     * @type {number}
     * @memberof JobAdvancedData
     */
    'smActive'?: number;
    /**
     * The fraction of resident warps on a multiprocessor, relative to the maximum number of concurrent warps supported on a multiprocessor
     * @type {number}
     * @memberof JobAdvancedData
     */
    'smOccupancy'?: number;
    /**
     * The fraction of cycles the tensor (HMMA / IMMA) pipe was active
     * @type {number}
     * @memberof JobAdvancedData
     */
    'pipeTensorActive'?: number;
    /**
     * The fraction of cycles the FP64 (double precision) pipe was active
     * @type {number}
     * @memberof JobAdvancedData
     */
    'pipeFp64Active'?: number;
    /**
     * The fraction of cycles the FMA (FP32 (single precision), and integer) pipe was active
     * @type {number}
     * @memberof JobAdvancedData
     */
    'pipeFp32Active'?: number;
    /**
     * The fraction of cycles the FP16 (half precision) pipe was active
     * @type {number}
     * @memberof JobAdvancedData
     */
    'pipeFp16Active'?: number;
    /**
     * The rate of data transmitted over NVLink, not including protocol headers, in bytes per second
     * @type {number}
     * @memberof JobAdvancedData
     */
    'nvlinkTxBytes'?: number;
    /**
     * The rate of data received over NVLink, not including protocol headers, in bytes per second
     * @type {number}
     * @memberof JobAdvancedData
     */
    'nvlinkRxBytes'?: number;
    /**
     * The rate of data transmitted over the PCIe bus, including both protocol headers and data payloads, in bytes per second
     * @type {number}
     * @memberof JobAdvancedData
     */
    'pcieTxBytes'?: number;
    /**
     * The rate of data received over the PCIe bus, including both protocol headers and data payloads, in bytes per second
     * @type {number}
     * @memberof JobAdvancedData
     */
    'pcieRxBytes'?: number;
}
/**
 * 
 * @export
 * @interface JobCurrent
 */
export interface JobCurrent {
    /**
     * Job total run time in seconds
     * @type {number}
     * @memberof JobCurrent
     */
    'totalRunTime': number;
    /**
     * Job total wait time in seconds
     * @type {number}
     * @memberof JobCurrent
     */
    'totalWaitTime': number;
    /**
     * 
     * @type {JobResourcesData}
     * @memberof JobCurrent
     */
    'resources': JobResourcesData;
}
/**
 * 
 * @export
 * @interface JobMetadata
 */
export interface JobMetadata {
    /**
     * 
     * @type {string}
     * @memberof JobMetadata
     */
    'jobId': string;
    /**
     * 
     * @type {string}
     * @memberof JobMetadata
     */
    'jobName': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof JobMetadata
     */
    'clusterId': string;
    /**
     * 
     * @type {string}
     * @memberof JobMetadata
     */
    'clusterName': string;
}
/**
 * 
 * @export
 * @interface JobResourceData
 */
export interface JobResourceData {
    /**
     * 
     * @type {number}
     * @memberof JobResourceData
     */
    'allocated': number;
    /**
     * Utilization percentage, values between 0 to 1.
     * @type {number}
     * @memberof JobResourceData
     */
    'utilization'?: number;
}
/**
 * 
 * @export
 * @interface JobResourcesData
 */
export interface JobResourcesData {
    /**
     * 
     * @type {JobResourceData}
     * @memberof JobResourcesData
     */
    'gpu': JobResourceData;
    /**
     * 
     * @type {JobResourceData}
     * @memberof JobResourcesData
     */
    'gpu-memory': JobResourceData;
    /**
     * 
     * @type {JobResourceData}
     * @memberof JobResourcesData
     */
    'cpu': JobResourceData;
    /**
     * 
     * @type {JobResourceData}
     * @memberof JobResourcesData
     */
    'cpu-memory': JobResourceData;
    /**
     * 
     * @type {JobAdvancedData}
     * @memberof JobResourcesData
     */
    'advanced'?: JobAdvancedData;
}
/**
 * 
 * @export
 * @interface JobResourcesDataOvertime
 */
export interface JobResourcesDataOvertime {
    /**
     * 
     * @type {JobResourceData}
     * @memberof JobResourcesDataOvertime
     */
    'gpu': JobResourceData;
    /**
     * 
     * @type {JobResourceData}
     * @memberof JobResourcesDataOvertime
     */
    'gpu-memory': JobResourceData;
    /**
     * 
     * @type {JobResourceData}
     * @memberof JobResourcesDataOvertime
     */
    'cpu': JobResourceData;
    /**
     * 
     * @type {JobResourceData}
     * @memberof JobResourcesDataOvertime
     */
    'cpu-memory': JobResourceData;
    /**
     * 
     * @type {JobAdvancedData}
     * @memberof JobResourcesDataOvertime
     */
    'advanced'?: JobAdvancedData;
    /**
     * 
     * @type {string}
     * @memberof JobResourcesDataOvertime
     */
    'timestamp': string;
}
/**
 * Overtime metrics data. Returned only if both start and end query parameters are set.
 * @export
 * @interface JobTimeRange
 */
export interface JobTimeRange {
    /**
     * 
     * @type {Array<JobResourcesDataOvertime>}
     * @memberof JobTimeRange
     */
    'resources': Array<JobResourcesDataOvertime>;
}
/**
 * 
 * @export
 * @interface JobsNodeAffinity
 */
export interface JobsNodeAffinity {
    /**
     * 
     * @type {JobsNodeAffinityTrain}
     * @memberof JobsNodeAffinity
     */
    'train'?: JobsNodeAffinityTrain;
    /**
     * 
     * @type {JobsNodeAffinityInteractive}
     * @memberof JobsNodeAffinity
     */
    'interactive'?: JobsNodeAffinityInteractive;
}
/**
 * Node affinity configuration for interactive jobs.
 * @export
 * @interface JobsNodeAffinityInteractive
 */
export interface JobsNodeAffinityInteractive {
    /**
     * The type of affinity of the jobs on the nodes.
     * @type {string}
     * @memberof JobsNodeAffinityInteractive
     */
    'affinityType'?: JobsNodeAffinityInteractiveAffinityTypeEnum;
    /**
     * 
     * @type {Array<NodeTypeForProject>}
     * @memberof JobsNodeAffinityInteractive
     */
    'selectedTypes'?: Array<NodeTypeForProject>;
}

export const JobsNodeAffinityInteractiveAffinityTypeEnum = {
    NoLimit: 'no_limit',
    OnlySelected: 'only_selected'
} as const;

export type JobsNodeAffinityInteractiveAffinityTypeEnum = typeof JobsNodeAffinityInteractiveAffinityTypeEnum[keyof typeof JobsNodeAffinityInteractiveAffinityTypeEnum];

/**
 * Node affinity configuration for training jobs.
 * @export
 * @interface JobsNodeAffinityTrain
 */
export interface JobsNodeAffinityTrain {
    /**
     * The type of affinity of the jobs on the nodes.
     * @type {string}
     * @memberof JobsNodeAffinityTrain
     */
    'affinityType'?: JobsNodeAffinityTrainAffinityTypeEnum;
    /**
     * 
     * @type {Array<NodeTypeForProject>}
     * @memberof JobsNodeAffinityTrain
     */
    'selectedTypes'?: Array<NodeTypeForProject>;
}

export const JobsNodeAffinityTrainAffinityTypeEnum = {
    NoLimit: 'no_limit',
    OnlySelected: 'only_selected'
} as const;

export type JobsNodeAffinityTrainAffinityTypeEnum = typeof JobsNodeAffinityTrainAffinityTypeEnum[keyof typeof JobsNodeAffinityTrainAffinityTypeEnum];

/**
 * 
 * @export
 * @interface MeasurementResponse
 */
export interface MeasurementResponse {
    /**
     * specifies what data returned
     * @type {string}
     * @memberof MeasurementResponse
     */
    'type': string;
    /**
     * labels of the metric measurement
     * @type {{ [key: string]: string; }}
     * @memberof MeasurementResponse
     */
    'labels'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {Array<MeasurementResponseValuesInner>}
     * @memberof MeasurementResponse
     */
    'values': Array<MeasurementResponseValuesInner> | null;
}
/**
 * 
 * @export
 * @interface MeasurementResponseValuesInner
 */
export interface MeasurementResponseValuesInner {
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseValuesInner
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseValuesInner
     */
    'timestamp': string | null;
}
/**
 * 
 * @export
 * @interface MetricStore
 */
export interface MetricStore {
    /**
     * 
     * @type {MetricStoreEndpoint}
     * @memberof MetricStore
     */
    'read'?: MetricStoreEndpoint;
    /**
     * 
     * @type {MetricStoreEndpoint}
     * @memberof MetricStore
     */
    'write'?: MetricStoreEndpoint;
}
/**
 * 
 * @export
 * @interface MetricStoreEndpoint
 */
export interface MetricStoreEndpoint {
    /**
     * 
     * @type {string}
     * @memberof MetricStoreEndpoint
     */
    'url': string;
    /**
     * 
     * @type {MetricStoreEndpointAuth}
     * @memberof MetricStoreEndpoint
     */
    'auth'?: MetricStoreEndpointAuth | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MetricStoreEndpoint
     */
    'headers'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface MetricStoreEndpointAuth
 */
export interface MetricStoreEndpointAuth {
    /**
     * 
     * @type {MetricStoreEndpointAuthBasic}
     * @memberof MetricStoreEndpointAuth
     */
    'basic'?: MetricStoreEndpointAuthBasic | null;
    /**
     * 
     * @type {string}
     * @memberof MetricStoreEndpointAuth
     */
    'bearer'?: string | null;
}
/**
 * 
 * @export
 * @interface MetricStoreEndpointAuthBasic
 */
export interface MetricStoreEndpointAuthBasic {
    /**
     * 
     * @type {string}
     * @memberof MetricStoreEndpointAuthBasic
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricStoreEndpointAuthBasic
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface MetricsResponse
 */
export interface MetricsResponse {
    /**
     * 
     * @type {Array<MeasurementResponse>}
     * @memberof MetricsResponse
     */
    'measurements': Array<MeasurementResponse>;
}
/**
 * specifies what data to request
 * @export
 * @enum {string}
 */

export const MetricsType = {
    TotalGpuNodes: 'TOTAL_GPU_NODES',
    GpuUtilization: 'GPU_UTILIZATION',
    GpuUtilizationDistribution: 'GPU_UTILIZATION_DISTRIBUTION',
    GpuMemoryUtilization: 'GPU_MEMORY_UTILIZATION',
    CpuUtilization: 'CPU_UTILIZATION',
    CpuMemoryUtilization: 'CPU_MEMORY_UTILIZATION',
    TotalGpu: 'TOTAL_GPU',
    GpuQuota: 'GPU_QUOTA',
    AllocatedGpu: 'ALLOCATED_GPU',
    UnallocatedGpu: 'UNALLOCATED_GPU',
    AvgWorkloadWaitTime: 'AVG_WORKLOAD_WAIT_TIME'
} as const;

export type MetricsType = typeof MetricsType[keyof typeof MetricsType];


/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface NodeAffinity
 */
export interface NodeAffinity {
    /**
     * The type of affinity of the jobs on the nodes.
     * @type {string}
     * @memberof NodeAffinity
     */
    'affinityType'?: NodeAffinityAffinityTypeEnum;
    /**
     * 
     * @type {Array<NodeTypeForProject>}
     * @memberof NodeAffinity
     */
    'selectedTypes'?: Array<NodeTypeForProject>;
}

export const NodeAffinityAffinityTypeEnum = {
    NoLimit: 'no_limit',
    OnlySelected: 'only_selected'
} as const;

export type NodeAffinityAffinityTypeEnum = typeof NodeAffinityAffinityTypeEnum[keyof typeof NodeAffinityAffinityTypeEnum];

/**
 * 
 * @export
 * @interface NodePool
 */
export interface NodePool {
    /**
     * Node Pool Name
     * @type {string}
     * @memberof NodePool
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof NodePool
     */
    'overProvisioningRatio'?: number;
    /**
     * Label key for associated nodes to the Node Pool (with value as in labelValue)
     * @type {string}
     * @memberof NodePool
     */
    'labelKey'?: string;
    /**
     * Label value for associated nodes to the Node Pool (with key as in labelKey)
     * @type {string}
     * @memberof NodePool
     */
    'labelValue'?: string;
    /**
     * 
     * @type {PlacementStrategy}
     * @memberof NodePool
     */
    'placementStrategy'?: PlacementStrategy;
    /**
     * Node Pool unique id
     * @type {number}
     * @memberof NodePool
     */
    'id'?: number;
    /**
     * Node Pool cluster id
     * @type {string}
     * @memberof NodePool
     */
    'clusterId'?: string;
    /**
     * Node Pool creation time
     * @type {string}
     * @memberof NodePool
     */
    'createdAt'?: string;
    /**
     * Node Pool update time
     * @type {string}
     * @memberof NodePool
     */
    'updatedAt'?: string;
    /**
     * Node Pool delete time
     * @type {string}
     * @memberof NodePool
     */
    'deletedAt'?: string;
    /**
     * Node Pool status
     * @type {string}
     * @memberof NodePool
     */
    'status'?: NodePoolStatusEnum;
    /**
     * Node Pool status details
     * @type {string}
     * @memberof NodePool
     */
    'statusMessage'?: string;
    /**
     * List of Nodes that are assigned to this nodepool - as json string
     * @type {string}
     * @memberof NodePool
     */
    'nodes'?: string;
    /**
     * Node Pool creator
     * @type {string}
     * @memberof NodePool
     */
    'createdBy'?: string;
    /**
     * Node Pool updater
     * @type {string}
     * @memberof NodePool
     */
    'updatedBy'?: string;
    /**
     * Is the Node Pool the default Node Pool for all nodes not assigned to any other Node Pool
     * @type {boolean}
     * @memberof NodePool
     */
    'isDefault'?: boolean;
}

export const NodePoolStatusEnum = {
    Creating: 'Creating',
    Updating: 'Updating',
    Deleting: 'Deleting',
    Empty: 'Empty',
    Unschedulable: 'Unschedulable',
    Ready: 'Ready',
    Deleted: 'Deleted'
} as const;

export type NodePoolStatusEnum = typeof NodePoolStatusEnum[keyof typeof NodePoolStatusEnum];

/**
 * 
 * @export
 * @interface NodePoolAllOf
 */
export interface NodePoolAllOf {
    /**
     * Node Pool unique id
     * @type {number}
     * @memberof NodePoolAllOf
     */
    'id'?: number;
    /**
     * Node Pool cluster id
     * @type {string}
     * @memberof NodePoolAllOf
     */
    'clusterId'?: string;
    /**
     * Node Pool creation time
     * @type {string}
     * @memberof NodePoolAllOf
     */
    'createdAt'?: string;
    /**
     * Node Pool update time
     * @type {string}
     * @memberof NodePoolAllOf
     */
    'updatedAt'?: string;
    /**
     * Node Pool delete time
     * @type {string}
     * @memberof NodePoolAllOf
     */
    'deletedAt'?: string;
    /**
     * Node Pool status
     * @type {string}
     * @memberof NodePoolAllOf
     */
    'status'?: NodePoolAllOfStatusEnum;
    /**
     * Node Pool status details
     * @type {string}
     * @memberof NodePoolAllOf
     */
    'statusMessage'?: string;
    /**
     * List of Nodes that are assigned to this nodepool - as json string
     * @type {string}
     * @memberof NodePoolAllOf
     */
    'nodes'?: string;
    /**
     * Node Pool creator
     * @type {string}
     * @memberof NodePoolAllOf
     */
    'createdBy'?: string;
    /**
     * Node Pool updater
     * @type {string}
     * @memberof NodePoolAllOf
     */
    'updatedBy'?: string;
    /**
     * Is the Node Pool the default Node Pool for all nodes not assigned to any other Node Pool
     * @type {boolean}
     * @memberof NodePoolAllOf
     */
    'isDefault'?: boolean;
}

export const NodePoolAllOfStatusEnum = {
    Creating: 'Creating',
    Updating: 'Updating',
    Deleting: 'Deleting',
    Empty: 'Empty',
    Unschedulable: 'Unschedulable',
    Ready: 'Ready',
    Deleted: 'Deleted'
} as const;

export type NodePoolAllOfStatusEnum = typeof NodePoolAllOfStatusEnum[keyof typeof NodePoolAllOfStatusEnum];

/**
 * 
 * @export
 * @interface NodePoolAssignedResources
 */
export interface NodePoolAssignedResources {
    /**
     * The id of the assigned resources. Required in PUT when updating the assigned resources.
     * @type {number}
     * @memberof NodePoolAssignedResources
     */
    'id': number;
    /**
     * 
     * @type {NodePoolAssignedResourcesCreateNodePool}
     * @memberof NodePoolAssignedResources
     */
    'nodePool': NodePoolAssignedResourcesCreateNodePool;
    /**
     * 
     * @type {NodePoolAssignedResourcesCreateGpu}
     * @memberof NodePoolAssignedResources
     */
    'gpu'?: NodePoolAssignedResourcesCreateGpu;
    /**
     * 
     * @type {NodePoolAssignedResourcesCreateCpu}
     * @memberof NodePoolAssignedResources
     */
    'cpu'?: NodePoolAssignedResourcesCreateCpu;
    /**
     * 
     * @type {NodePoolAssignedResourcesCreateMemory}
     * @memberof NodePoolAssignedResources
     */
    'memory'?: NodePoolAssignedResourcesCreateMemory;
}
/**
 * 
 * @export
 * @interface NodePoolAssignedResourcesAllOf
 */
export interface NodePoolAssignedResourcesAllOf {
    /**
     * The id of the assigned resources. Required in PUT when updating the assigned resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesAllOf
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface NodePoolAssignedResourcesCreate
 */
export interface NodePoolAssignedResourcesCreate {
    /**
     * 
     * @type {NodePoolAssignedResourcesCreateNodePool}
     * @memberof NodePoolAssignedResourcesCreate
     */
    'nodePool': NodePoolAssignedResourcesCreateNodePool;
    /**
     * 
     * @type {NodePoolAssignedResourcesCreateGpu}
     * @memberof NodePoolAssignedResourcesCreate
     */
    'gpu'?: NodePoolAssignedResourcesCreateGpu;
    /**
     * 
     * @type {NodePoolAssignedResourcesCreateCpu}
     * @memberof NodePoolAssignedResourcesCreate
     */
    'cpu'?: NodePoolAssignedResourcesCreateCpu;
    /**
     * 
     * @type {NodePoolAssignedResourcesCreateMemory}
     * @memberof NodePoolAssignedResourcesCreate
     */
    'memory'?: NodePoolAssignedResourcesCreateMemory;
}
/**
 * Number of CPU Millicores assigned in the node pool. Supported only if the \'CPU Resources Quota\' feature flag is enabled.
 * @export
 * @interface NodePoolAssignedResourcesCreateCpu
 */
export interface NodePoolAssignedResourcesCreateCpu {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesCreateCpu
     */
    'deserved'?: number;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof NodePoolAssignedResourcesCreateCpu
     */
    'maxAllowed'?: number;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesCreateCpu
     */
    'overQuotaWeight'?: number;
}
/**
 * Number of GPUs assigned in the node pool.
 * @export
 * @interface NodePoolAssignedResourcesCreateGpu
 */
export interface NodePoolAssignedResourcesCreateGpu {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesCreateGpu
     */
    'deserved'?: number;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof NodePoolAssignedResourcesCreateGpu
     */
    'maxAllowed'?: number;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesCreateGpu
     */
    'overQuotaWeight'?: number;
}
/**
 * Amount of CPU Memory Mib assigned in the node pool. Supported only if the \'CPU Resources Quota\' feature flag is enabled.
 * @export
 * @interface NodePoolAssignedResourcesCreateMemory
 */
export interface NodePoolAssignedResourcesCreateMemory {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesCreateMemory
     */
    'deserved'?: number;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof NodePoolAssignedResourcesCreateMemory
     */
    'maxAllowed'?: number;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesCreateMemory
     */
    'overQuotaWeight'?: number;
}
/**
 * The node pool which the assigned resources refer to.
 * @export
 * @interface NodePoolAssignedResourcesCreateNodePool
 */
export interface NodePoolAssignedResourcesCreateNodePool {
    /**
     * Node pool id.
     * @type {number}
     * @memberof NodePoolAssignedResourcesCreateNodePool
     */
    'id': number;
    /**
     * Node pool name.
     * @type {string}
     * @memberof NodePoolAssignedResourcesCreateNodePool
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface NodePoolCreateRequest
 */
export interface NodePoolCreateRequest {
    /**
     * Node Pool Name
     * @type {string}
     * @memberof NodePoolCreateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof NodePoolCreateRequest
     */
    'overProvisioningRatio'?: number;
    /**
     * Label key for associated nodes to the Node Pool (with value as in labelValue)
     * @type {string}
     * @memberof NodePoolCreateRequest
     */
    'labelKey'?: string;
    /**
     * Label value for associated nodes to the Node Pool (with key as in labelKey)
     * @type {string}
     * @memberof NodePoolCreateRequest
     */
    'labelValue'?: string;
    /**
     * 
     * @type {PlacementStrategy}
     * @memberof NodePoolCreateRequest
     */
    'placementStrategy'?: PlacementStrategy;
}
/**
 * 
 * @export
 * @interface NodePoolLabelsRequest
 */
export interface NodePoolLabelsRequest {
    /**
     * key of node label for pool
     * @type {string}
     * @memberof NodePoolLabelsRequest
     */
    'labelKey'?: string;
    /**
     * value of node label for pool
     * @type {string}
     * @memberof NodePoolLabelsRequest
     */
    'labelValue'?: string;
}
/**
 * 
 * @export
 * @interface NodePoolQuotaStatus
 */
export interface NodePoolQuotaStatus {
    /**
     * The node pool name which the current quota status refers to.
     * @type {string}
     * @memberof NodePoolQuotaStatus
     */
    'nodePoolName'?: string;
    /**
     * 
     * @type {QuotaStatusResourceList}
     * @memberof NodePoolQuotaStatus
     */
    'allocated'?: QuotaStatusResourceList;
    /**
     * 
     * @type {QuotaStatusResourceList}
     * @memberof NodePoolQuotaStatus
     */
    'allocatedNonPreemptible'?: QuotaStatusResourceList;
    /**
     * 
     * @type {QuotaStatusResourceList}
     * @memberof NodePoolQuotaStatus
     */
    'requested'?: QuotaStatusResourceList;
}
/**
 * 
 * @export
 * @interface NodePoolResources
 */
export interface NodePoolResources {
    /**
     * Node pool id.
     * @type {number}
     * @memberof NodePoolResources
     */
    'id': number;
    /**
     * Node pool name.
     * @type {string}
     * @memberof NodePoolResources
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface NodePoolResponse
 */
export interface NodePoolResponse {
    /**
     * The NodePool id
     * @type {number}
     * @memberof NodePoolResponse
     */
    'id'?: number;
    /**
     * Key of node label for pool
     * @type {string}
     * @memberof NodePoolResponse
     */
    'labelKey'?: string;
    /**
     * Value of node label for pool
     * @type {string}
     * @memberof NodePoolResponse
     */
    'labelValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof NodePoolResponse
     */
    'overProvisioningRatio'?: number;
    /**
     * 
     * @type {PlacementStrategy}
     * @memberof NodePoolResponse
     */
    'placementStrategy'?: PlacementStrategy;
    /**
     * Is the Node Pool default for cluster
     * @type {boolean}
     * @memberof NodePoolResponse
     */
    'isDefault'?: boolean;
    /**
     * Status of Node Pool
     * @type {string}
     * @memberof NodePoolResponse
     */
    'status'?: NodePoolResponseStatusEnum;
    /**
     * Message for status of Node Pool
     * @type {string}
     * @memberof NodePoolResponse
     */
    'statusMessage'?: string;
    /**
     * List of Nodes that are assigned to this nodepool - as json string
     * @type {string}
     * @memberof NodePoolResponse
     */
    'nodes'?: string;
}

export const NodePoolResponseStatusEnum = {
    Creating: 'Creating',
    Updating: 'Updating',
    Deleting: 'Deleting',
    Empty: 'Empty',
    Unschedulable: 'Unschedulable',
    Ready: 'Ready',
    Deleted: 'Deleted'
} as const;

export type NodePoolResponseStatusEnum = typeof NodePoolResponseStatusEnum[keyof typeof NodePoolResponseStatusEnum];

/**
 * 
 * @export
 * @interface NodePoolStatus
 */
export interface NodePoolStatus {
    /**
     * key of node label for pool
     * @type {string}
     * @memberof NodePoolStatus
     */
    'labelKey'?: string;
}
/**
 * 
 * @export
 * @interface NodePoolStatusRequest
 */
export interface NodePoolStatusRequest {
    /**
     * Status of Node Pool
     * @type {string}
     * @memberof NodePoolStatusRequest
     */
    'status'?: NodePoolStatusRequestStatusEnum;
    /**
     * Message for status of Node Pool
     * @type {string}
     * @memberof NodePoolStatusRequest
     */
    'statusMessage'?: string;
    /**
     * List of Nodes that are assigned to this nodepool - as json string
     * @type {string}
     * @memberof NodePoolStatusRequest
     */
    'nodes'?: string;
}

export const NodePoolStatusRequestStatusEnum = {
    Creating: 'Creating',
    Updating: 'Updating',
    Deleting: 'Deleting',
    Empty: 'Empty',
    Unschedulable: 'Unschedulable',
    Ready: 'Ready',
    Deleted: 'Deleted'
} as const;

export type NodePoolStatusRequestStatusEnum = typeof NodePoolStatusRequestStatusEnum[keyof typeof NodePoolStatusRequestStatusEnum];

/**
 * 
 * @export
 * @interface NodeTypeForProject
 */
export interface NodeTypeForProject {
    /**
     * 
     * @type {number}
     * @memberof NodeTypeForProject
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NodeTypeForProject
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface OrgUnit
 */
export interface OrgUnit {
    /**
     * 
     * @type {string}
     * @memberof OrgUnit
     */
    'id': string;
    /**
     * 
     * @type {ScopeType}
     * @memberof OrgUnit
     */
    'type': ScopeType;
    /**
     * 
     * @type {string}
     * @memberof OrgUnit
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrgUnit
     */
    'parentId'?: string;
    /**
     * 
     * @type {ScopeType}
     * @memberof OrgUnit
     */
    'parentType'?: ScopeType;
}
/**
 * 
 * @export
 * @interface Owner
 */
export interface Owner {
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'kind': string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface PVC
 */
export interface PVC {
    /**
     * Identifier of the pvc name.
     * @type {string}
     * @memberof PVC
     */
    'pvc_name': string;
}
/**
 * 
 * @export
 * @interface PlacementStrategy
 */
export interface PlacementStrategy {
    /**
     * scheduling strategy for cpu
     * @type {string}
     * @memberof PlacementStrategy
     */
    'cpu'?: PlacementStrategyCpuEnum;
    /**
     * scheduling strategy for gpu
     * @type {string}
     * @memberof PlacementStrategy
     */
    'gpu'?: PlacementStrategyGpuEnum;
}

export const PlacementStrategyCpuEnum = {
    Spread: 'spread',
    Binpack: 'binpack'
} as const;

export type PlacementStrategyCpuEnum = typeof PlacementStrategyCpuEnum[keyof typeof PlacementStrategyCpuEnum];
export const PlacementStrategyGpuEnum = {
    Spread: 'spread',
    Binpack: 'binpack'
} as const;

export type PlacementStrategyGpuEnum = typeof PlacementStrategyGpuEnum[keyof typeof PlacementStrategyGpuEnum];

/**
 * 
 * @export
 * @interface Pod
 */
export interface Pod {
    /**
     * Identifier of the pod running the job.
     * @type {string}
     * @memberof Pod
     */
    'podId': string;
    /**
     * Unique identifier of the job.
     * @type {string}
     * @memberof Pod
     */
    'jobId': string;
    /**
     * This had been used as jobId. Remained here for backward compatibility
     * @type {string}
     * @memberof Pod
     */
    'podGroupId'?: string;
    /**
     * Unique identifier of the cluster.
     * @type {string}
     * @memberof Pod
     */
    'clusterUuid': string;
    /**
     * The name of the pod running the job.
     * @type {string}
     * @memberof Pod
     */
    'podName': string;
    /**
     * The name of the image executed by the pod.
     * @type {string}
     * @memberof Pod
     */
    'imageName': string;
    /**
     * Unique identifier of the node.
     * @type {string}
     * @memberof Pod
     */
    'nodeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pod
     */
    'phase': string;
    /**
     * 
     * @type {string}
     * @memberof Pod
     */
    'status'?: string;
    /**
     * Creation time of the pod.
     * @type {number}
     * @memberof Pod
     */
    'created': number;
    /**
     * Completion time of the pod.
     * @type {number}
     * @memberof Pod
     */
    'completed': number;
    /**
     * The time when the pod started executing.
     * @type {number}
     * @memberof Pod
     */
    'started'?: number;
    /**
     * Last time the pod details were updated.
     * @type {number}
     * @memberof Pod
     */
    'lastUpdated': number;
    /**
     * 
     * @type {object}
     * @memberof Pod
     */
    'dynamicData'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof Pod
     */
    'existsInCluster'?: boolean;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof Pod
     */
    'resourceRequest'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof Pod
     */
    'resourceAllocation'?: { [key: string]: number; };
    /**
     * The node pool of the pod.
     * @type {string}
     * @memberof Pod
     */
    'nodePool'?: string;
    /**
     * The namespace of the pod.
     * @type {string}
     * @memberof Pod
     */
    'namespace'?: string;
}
/**
 * 
 * @export
 * @interface PodGroup
 */
export interface PodGroup {
    /**
     * 
     * @type {string}
     * @memberof PodGroup
     */
    'jobId': string;
    /**
     * Unique identifier of the pod group.
     * @type {string}
     * @memberof PodGroup
     */
    'podGroupId': string;
    /**
     * Name of the pod group.
     * @type {string}
     * @memberof PodGroup
     */
    'podGroupName'?: string;
    /**
     * Name of the workload.
     * @type {string}
     * @memberof PodGroup
     */
    'workloadName': string;
    /**
     * Type of the workload.
     * @type {string}
     * @memberof PodGroup
     */
    'workloadType': PodGroupWorkloadTypeEnum;
    /**
     * Specifies the kind of k8s resource that owns the pod group.
     * @type {string}
     * @memberof PodGroup
     */
    'workloadKind': string;
    /**
     * Unique identifier of the cluster.
     * @type {string}
     * @memberof PodGroup
     */
    'clusterUuid': string;
    /**
     * The name of the image executed by the pod.
     * @type {string}
     * @memberof PodGroup
     */
    'imageName': string;
    /**
     * The phase of the pod group.
     * @type {string}
     * @memberof PodGroup
     */
    'podGroupPhase'?: PodGroupPodGroupPhaseEnum;
    /**
     * 
     * @type {string}
     * @memberof PodGroup
     */
    'jobPhase': string;
    /**
     * The owner of the pod group.
     * @type {string}
     * @memberof PodGroup
     */
    'workloadUser': string;
    /**
     * The project that the pod group belongs to.
     * @type {string}
     * @memberof PodGroup
     */
    'project': string;
    /**
     * Unique identifier of the node.
     * @type {string}
     * @memberof PodGroup
     */
    'nodeId'?: string;
    /**
     * Pod group creation time.
     * @type {number}
     * @memberof PodGroup
     */
    'created': number;
    /**
     * Time of the latest update to the pod group details.
     * @type {number}
     * @memberof PodGroup
     */
    'lastUpdated': number;
    /**
     * Specifies the maximum number of pods the workload is able run at any given time.
     * @type {number}
     * @memberof PodGroup
     */
    'parallelism': number;
    /**
     * Specifies the number of successfully finished pods the created workload should be run with.
     * @type {number}
     * @memberof PodGroup
     */
    'completions': number;
    /**
     * 
     * @type {number}
     * @memberof PodGroup
     */
    'currentRequestedGpus': number;
    /**
     * 
     * @type {number}
     * @memberof PodGroup
     */
    'totalRequestedGpus': number;
    /**
     * 
     * @type {number}
     * @memberof PodGroup
     */
    'requestedGpusPerPodgroup': number;
    /**
     * 
     * @type {number}
     * @memberof PodGroup
     */
    'requestedGpusMemoryPerPodgroup': number;
    /**
     * Specifies the Parent Workload Name
     * @type {string}
     * @memberof PodGroup
     */
    'parentWorkloadName': string;
    /**
     * 
     * @type {number}
     * @memberof PodGroup
     */
    'currentAllocatedGpus'?: number;
    /**
     * 
     * @type {number}
     * @memberof PodGroup
     */
    'currentAllocatedGpusMemory'?: number;
    /**
     * 
     * @type {number}
     * @memberof PodGroup
     */
    'totalRequestedCpu'?: number;
    /**
     * 
     * @type {number}
     * @memberof PodGroup
     */
    'totalRequestedMemory'?: number;
    /**
     * 
     * @type {number}
     * @memberof PodGroup
     */
    'totalLimitCpu'?: number;
    /**
     * 
     * @type {number}
     * @memberof PodGroup
     */
    'totalLimitMemory'?: number;
    /**
     * 
     * @type {string}
     * @memberof PodGroup
     */
    'cliCommand'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodGroup
     */
    'requestedMigDevices'?: string;
    /**
     * 
     * @type {object}
     * @memberof PodGroup
     */
    'dynamicData'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof PodGroup
     */
    'existsInCluster'?: boolean;
    /**
     * Describe the content of jobUrl field, values may be (tensorboard , jupyter or \'\')
     * @type {string}
     * @memberof PodGroup
     */
    'jobUrlType'?: string;
    /**
     * If true, it indicates that the pod group runs jupyter notebook.
     * @type {boolean}
     * @memberof PodGroup
     * @deprecated
     */
    'isJupyter'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PodGroup
     */
    'jobUrl'?: string;
    /**
     * this attribute is obsolete, use workloadId instead.
     * @type {string}
     * @memberof PodGroup
     */
    'workspaceId'?: string;
    /**
     * reference to the workspace which was used for the creation of the job.
     * @type {string}
     * @memberof PodGroup
     */
    'workloadId'?: string;
    /**
     * The node pool of the podgroup.
     * @type {string}
     * @memberof PodGroup
     */
    'nodePool'?: string;
}

export const PodGroupWorkloadTypeEnum = {
    Build: 'build',
    Interactive: 'interactive'
} as const;

export type PodGroupWorkloadTypeEnum = typeof PodGroupWorkloadTypeEnum[keyof typeof PodGroupWorkloadTypeEnum];
export const PodGroupPodGroupPhaseEnum = {
    Unknown: 'Unknown',
    Pending: 'Pending',
    Running: 'Running',
    Succeeded: 'Succeeded',
    Failed: 'Failed'
} as const;

export type PodGroupPodGroupPhaseEnum = typeof PodGroupPodGroupPhaseEnum[keyof typeof PodGroupPodGroupPhaseEnum];

/**
 * 
 * @export
 * @interface PodGroupsSync
 */
export interface PodGroupsSync {
    /**
     * The project name we are syncing objects in
     * @type {string}
     * @memberof PodGroupsSync
     */
    'projectName': string;
    /**
     * 
     * @type {Array<PodGroup>}
     * @memberof PodGroupsSync
     */
    'podGroups': Array<PodGroup>;
    /**
     * the first podgroup to sync. if empty, all podgroups before the first one sent will be deleted.
     * @type {string}
     * @memberof PodGroupsSync
     */
    'from'?: string;
    /**
     * the last podgroup to sync. if empty, all podgroups after the last one sent will be deleted.
     * @type {string}
     * @memberof PodGroupsSync
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface PodTemplate
 */
export interface PodTemplate {
    /**
     * 
     * @type {Array<Container>}
     * @memberof PodTemplate
     */
    'containers': Array<Container>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PodTemplate
     */
    'resourceRequest': { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface PodsSync
 */
export interface PodsSync {
    /**
     * The namespace we are syncing objects in
     * @type {string}
     * @memberof PodsSync
     */
    'namespace': string;
    /**
     * 
     * @type {Array<Pod>}
     * @memberof PodsSync
     */
    'pods': Array<Pod>;
    /**
     * the first pod id to sync. if empty, all pods before the first one sent will be deleted.
     * @type {string}
     * @memberof PodsSync
     */
    'from'?: string;
    /**
     * the last pod id to sync. if empty, all pods after the last one sent will be deleted.
     * @type {string}
     * @memberof PodsSync
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {ProjectMetadata}
     * @memberof Project
     */
    'metadata': ProjectMetadata;
    /**
     * 
     * @type {ProjectCurrent}
     * @memberof Project
     */
    'current': ProjectCurrent;
    /**
     * 
     * @type {TimeRange}
     * @memberof Project
     */
    'timeRange'?: TimeRange;
}
/**
 * 
 * @export
 * @interface Project1
 */
export interface Project1 {
    /**
     * Project name.
     * @type {string}
     * @memberof Project1
     */
    'name'?: string;
    /**
     * Resources assigned to this Project per Node Pool.
     * @type {Array<NodePoolAssignedResources>}
     * @memberof Project1
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResources>;
    /**
     * The name of an existing namespace to use for the project in the cluster. Supported only for cluster versions 2.12 or higher.
     * @type {string}
     * @memberof Project1
     */
    'namespace'?: string;
    /**
     * Project id.
     * @type {number}
     * @memberof Project1
     */
    'id'?: number;
    /**
     * ID of the department that owns the project.
     * @type {number}
     * @memberof Project1
     */
    'departmentId'?: number;
    /**
     * ID of the tenant where the project is located.
     * @type {number}
     * @memberof Project1
     */
    'tenantId'?: number;
    /**
     * ID of the cluster where the project is located.
     * @type {string}
     * @memberof Project1
     */
    'clusterUuid'?: string;
    /**
     * Name of the department where the project is located.
     * @type {string}
     * @memberof Project1
     */
    'departmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project1
     */
    'interactiveNodeAffinity'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project1
     */
    'trainNodeAffinity'?: string;
    /**
     * Creation date of the project.
     * @type {string}
     * @memberof Project1
     */
    'createdAt'?: string;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof Project1
     */
    'status'?: ProjectStatus;
    /**
     * project\'s phase
     * @type {string}
     * @memberof Project1
     */
    'phase'?: string;
    /**
     * Deprecated. Use \'deserved\' for the relevant resource type under `NodePoolResources`. The project\'s deserved GPU allocation in case the cluster has those resources.
     * @type {number}
     * @memberof Project1
     * @deprecated
     */
    'deservedGpus'?: number;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. An upper limit for the amount of GPUs the project can get (Even if over quota is allowed and resources are available).
     * @type {number}
     * @memberof Project1
     * @deprecated
     */
    'maxAllowedGpus'?: number;
    /**
     * Deprecated. Instead, use `overQuotaWeight` for the relevant resource type under `NodePoolResources`. The priority the project gets for over quota resources.
     * @type {number}
     * @memberof Project1
     * @deprecated
     */
    'gpuOverQuotaWeight'?: number;
    /**
     * Default node pools list for workload submission for this project if a workload doesn\'t specify a node pools list.
     * @type {Array<string>}
     * @memberof Project1
     */
    'defaultNodePools'?: Array<string>;
    /**
     * A limit (in seconds) for the duration of interactive jobs from this project.
     * @type {number}
     * @memberof Project1
     */
    'interactiveJobTimeLimitSecs'?: number;
    /**
     * Maximum duration (in seconds) that an interactive job can be idle before being terminated.
     * @type {number}
     * @memberof Project1
     */
    'interactiveJobMaxIdleDurationSecs'?: number;
    /**
     * Maximum duration (in seconds) that an interactive preemptible job can be idle before being terminated.
     * @type {number}
     * @memberof Project1
     */
    'interactivePreemptibleJobMaxIdleDurationSecs'?: number;
    /**
     * A limit (in seconds) for the duration of training jobs from this project. Available only from cluster version 2.12
     * @type {number}
     * @memberof Project1
     */
    'trainingJobTimeLimitSecs'?: number;
    /**
     * Maximum duration (in seconds) that a training job can be idle before being terminated.
     * @type {number}
     * @memberof Project1
     */
    'trainingJobMaxIdleDurationSecs'?: number;
    /**
     * 
     * @type {ProjectCommonFieldsNodeAffinity}
     * @memberof Project1
     */
    'nodeAffinity'?: ProjectCommonFieldsNodeAffinity;
    /**
     * 
     * @type {ProjectCommonFieldsPermissions}
     * @memberof Project1
     * @deprecated
     */
    'permissions'?: ProjectCommonFieldsPermissions;
    /**
     * 
     * @type {ProjectCommonFieldsResources}
     * @memberof Project1
     * @deprecated
     */
    'resources'?: ProjectCommonFieldsResources;
}
/**
 * 
 * @export
 * @interface Project1AllOf
 */
export interface Project1AllOf {
    /**
     * Project name.
     * @type {string}
     * @memberof Project1AllOf
     */
    'name'?: string;
    /**
     * Resources assigned to this Project per Node Pool.
     * @type {Array<NodePoolAssignedResources>}
     * @memberof Project1AllOf
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResources>;
    /**
     * The name of an existing namespace to use for the project in the cluster. Supported only for cluster versions 2.12 or higher.
     * @type {string}
     * @memberof Project1AllOf
     */
    'namespace'?: string;
    /**
     * Project id.
     * @type {number}
     * @memberof Project1AllOf
     */
    'id'?: number;
    /**
     * ID of the department that owns the project.
     * @type {number}
     * @memberof Project1AllOf
     */
    'departmentId'?: number;
    /**
     * ID of the tenant where the project is located.
     * @type {number}
     * @memberof Project1AllOf
     */
    'tenantId'?: number;
    /**
     * ID of the cluster where the project is located.
     * @type {string}
     * @memberof Project1AllOf
     */
    'clusterUuid'?: string;
    /**
     * Name of the department where the project is located.
     * @type {string}
     * @memberof Project1AllOf
     */
    'departmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project1AllOf
     */
    'interactiveNodeAffinity'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project1AllOf
     */
    'trainNodeAffinity'?: string;
    /**
     * Creation date of the project.
     * @type {string}
     * @memberof Project1AllOf
     */
    'createdAt'?: string;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof Project1AllOf
     */
    'status'?: ProjectStatus;
    /**
     * project\'s phase
     * @type {string}
     * @memberof Project1AllOf
     */
    'phase'?: string;
}
/**
 * 
 * @export
 * @interface ProjectCommonFields
 */
export interface ProjectCommonFields {
    /**
     * Deprecated. Use \'deserved\' for the relevant resource type under `NodePoolResources`. The project\'s deserved GPU allocation in case the cluster has those resources.
     * @type {number}
     * @memberof ProjectCommonFields
     * @deprecated
     */
    'deservedGpus'?: number;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. An upper limit for the amount of GPUs the project can get (Even if over quota is allowed and resources are available).
     * @type {number}
     * @memberof ProjectCommonFields
     * @deprecated
     */
    'maxAllowedGpus'?: number;
    /**
     * Deprecated. Instead, use `overQuotaWeight` for the relevant resource type under `NodePoolResources`. The priority the project gets for over quota resources.
     * @type {number}
     * @memberof ProjectCommonFields
     * @deprecated
     */
    'gpuOverQuotaWeight'?: number;
    /**
     * Default node pools list for workload submission for this project if a workload doesn\'t specify a node pools list.
     * @type {Array<string>}
     * @memberof ProjectCommonFields
     */
    'defaultNodePools'?: Array<string>;
    /**
     * A limit (in seconds) for the duration of interactive jobs from this project.
     * @type {number}
     * @memberof ProjectCommonFields
     */
    'interactiveJobTimeLimitSecs'?: number;
    /**
     * Maximum duration (in seconds) that an interactive job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectCommonFields
     */
    'interactiveJobMaxIdleDurationSecs'?: number;
    /**
     * Maximum duration (in seconds) that an interactive preemptible job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectCommonFields
     */
    'interactivePreemptibleJobMaxIdleDurationSecs'?: number;
    /**
     * A limit (in seconds) for the duration of training jobs from this project. Available only from cluster version 2.12
     * @type {number}
     * @memberof ProjectCommonFields
     */
    'trainingJobTimeLimitSecs'?: number;
    /**
     * Maximum duration (in seconds) that a training job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectCommonFields
     */
    'trainingJobMaxIdleDurationSecs'?: number;
    /**
     * 
     * @type {ProjectCommonFieldsNodeAffinity}
     * @memberof ProjectCommonFields
     */
    'nodeAffinity'?: ProjectCommonFieldsNodeAffinity;
    /**
     * 
     * @type {ProjectCommonFieldsPermissions}
     * @memberof ProjectCommonFields
     * @deprecated
     */
    'permissions'?: ProjectCommonFieldsPermissions;
    /**
     * 
     * @type {ProjectCommonFieldsResources}
     * @memberof ProjectCommonFields
     * @deprecated
     */
    'resources'?: ProjectCommonFieldsResources;
}
/**
 * Node affinity configuration for jobs in the project.
 * @export
 * @interface ProjectCommonFieldsNodeAffinity
 */
export interface ProjectCommonFieldsNodeAffinity {
    /**
     * 
     * @type {JobsNodeAffinityTrain}
     * @memberof ProjectCommonFieldsNodeAffinity
     */
    'train'?: JobsNodeAffinityTrain;
    /**
     * 
     * @type {JobsNodeAffinityInteractive}
     * @memberof ProjectCommonFieldsNodeAffinity
     */
    'interactive'?: JobsNodeAffinityInteractive;
}
/**
 * Deprecated. Instead, use the `accessRules` API to add permissions to a specific subject in the project scope.
 * @export
 * @interface ProjectCommonFieldsPermissions
 */
export interface ProjectCommonFieldsPermissions {
    /**
     * Names of users that have permissions to the project.
     * @type {Array<string>}
     * @memberof ProjectCommonFieldsPermissions
     */
    'users'?: Array<string>;
    /**
     * Names of groups that have permissions to the project.
     * @type {Array<string>}
     * @memberof ProjectCommonFieldsPermissions
     */
    'groups'?: Array<string>;
    /**
     * Names of applications that have permissions to the project.
     * @type {Array<string>}
     * @memberof ProjectCommonFieldsPermissions
     */
    'applications'?: Array<string>;
}
/**
 * Deprecated. Instead, use `nodePoolsResources`. Total resources assigned to the Project. Can only be used in PUT/POST when there is a single Node Pool in the system. The resources returned in `GET` are the sum of all Node Pool Resources.
 * @export
 * @interface ProjectCommonFieldsResources
 */
export interface ProjectCommonFieldsResources {
    /**
     * 
     * @type {number}
     * @memberof ProjectCommonFieldsResources
     */
    'id'?: number;
    /**
     * 
     * @type {AssignedResourcesGpu}
     * @memberof ProjectCommonFieldsResources
     */
    'gpu'?: AssignedResourcesGpu;
    /**
     * 
     * @type {AssignedResourcesCpu}
     * @memberof ProjectCommonFieldsResources
     */
    'cpu'?: AssignedResourcesCpu;
    /**
     * 
     * @type {AssignedResourcesMemory}
     * @memberof ProjectCommonFieldsResources
     */
    'memory'?: AssignedResourcesMemory;
}
/**
 * 
 * @export
 * @interface ProjectCreateRequest
 */
export interface ProjectCreateRequest {
    /**
     * Project Name
     * @type {string}
     * @memberof ProjectCreateRequest
     */
    'name': string;
    /**
     * ID of the department that owns the project.
     * @type {number}
     * @memberof ProjectCreateRequest
     */
    'departmentId': number;
    /**
     * Resources assigned to this Project per Node Pool.
     * @type {Array<NodePoolAssignedResourcesCreate>}
     * @memberof ProjectCreateRequest
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResourcesCreate>;
    /**
     * The name of an existing namespace to use for the project in the cluster. Supported only for cluster versions 2.12 or higher.
     * @type {string}
     * @memberof ProjectCreateRequest
     */
    'namespace'?: string;
    /**
     * Deprecated. Use \'deserved\' for the relevant resource type under `NodePoolResources`. The project\'s deserved GPU allocation in case the cluster has those resources.
     * @type {number}
     * @memberof ProjectCreateRequest
     * @deprecated
     */
    'deservedGpus'?: number;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. An upper limit for the amount of GPUs the project can get (Even if over quota is allowed and resources are available).
     * @type {number}
     * @memberof ProjectCreateRequest
     * @deprecated
     */
    'maxAllowedGpus'?: number;
    /**
     * Deprecated. Instead, use `overQuotaWeight` for the relevant resource type under `NodePoolResources`. The priority the project gets for over quota resources.
     * @type {number}
     * @memberof ProjectCreateRequest
     * @deprecated
     */
    'gpuOverQuotaWeight'?: number;
    /**
     * Default node pools list for workload submission for this project if a workload doesn\'t specify a node pools list.
     * @type {Array<string>}
     * @memberof ProjectCreateRequest
     */
    'defaultNodePools'?: Array<string>;
    /**
     * A limit (in seconds) for the duration of interactive jobs from this project.
     * @type {number}
     * @memberof ProjectCreateRequest
     */
    'interactiveJobTimeLimitSecs'?: number;
    /**
     * Maximum duration (in seconds) that an interactive job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectCreateRequest
     */
    'interactiveJobMaxIdleDurationSecs'?: number;
    /**
     * Maximum duration (in seconds) that an interactive preemptible job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectCreateRequest
     */
    'interactivePreemptibleJobMaxIdleDurationSecs'?: number;
    /**
     * A limit (in seconds) for the duration of training jobs from this project. Available only from cluster version 2.12
     * @type {number}
     * @memberof ProjectCreateRequest
     */
    'trainingJobTimeLimitSecs'?: number;
    /**
     * Maximum duration (in seconds) that a training job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectCreateRequest
     */
    'trainingJobMaxIdleDurationSecs'?: number;
    /**
     * 
     * @type {ProjectCommonFieldsNodeAffinity}
     * @memberof ProjectCreateRequest
     */
    'nodeAffinity'?: ProjectCommonFieldsNodeAffinity;
    /**
     * 
     * @type {ProjectCommonFieldsPermissions}
     * @memberof ProjectCreateRequest
     * @deprecated
     */
    'permissions'?: ProjectCommonFieldsPermissions;
    /**
     * 
     * @type {ProjectCommonFieldsResources}
     * @memberof ProjectCreateRequest
     * @deprecated
     */
    'resources'?: ProjectCommonFieldsResources;
}
/**
 * 
 * @export
 * @interface ProjectCreateRequestAllOf
 */
export interface ProjectCreateRequestAllOf {
    /**
     * Project Name
     * @type {string}
     * @memberof ProjectCreateRequestAllOf
     */
    'name'?: string;
    /**
     * ID of the department that owns the project.
     * @type {number}
     * @memberof ProjectCreateRequestAllOf
     */
    'departmentId'?: number;
    /**
     * Resources assigned to this Project per Node Pool.
     * @type {Array<NodePoolAssignedResourcesCreate>}
     * @memberof ProjectCreateRequestAllOf
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResourcesCreate>;
    /**
     * The name of an existing namespace to use for the project in the cluster. Supported only for cluster versions 2.12 or higher.
     * @type {string}
     * @memberof ProjectCreateRequestAllOf
     */
    'namespace'?: string;
}
/**
 * Current reported metrics data. resources holds data that is summed up to the project level and projectResources explains the division of it to nodepools
 * @export
 * @interface ProjectCurrent
 */
export interface ProjectCurrent {
    /**
     * 
     * @type {Array<ClusterCurrentResourcesInner>}
     * @memberof ProjectCurrent
     */
    'resources': Array<ClusterCurrentResourcesInner>;
}
/**
 * 
 * @export
 * @interface ProjectMetadata
 */
export interface ProjectMetadata {
    /**
     * 
     * @type {number}
     * @memberof ProjectMetadata
     */
    'projectId': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadata
     */
    'projectName': string;
    /**
     * The id of the department
     * @type {number}
     * @memberof ProjectMetadata
     */
    'departmentId': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadata
     */
    'departmentName': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof ProjectMetadata
     */
    'clusterId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadata
     */
    'clusterName': string;
}
/**
 * 
 * @export
 * @interface ProjectStatus
 */
export interface ProjectStatus {
    /**
     * The namespace of the project
     * @type {string}
     * @memberof ProjectStatus
     */
    'namespace'?: string;
    /**
     * Detailed phase message
     * @type {string}
     * @memberof ProjectStatus
     */
    'message'?: string;
    /**
     * The current quota status for each node pool. Supported only for cluster versions 2.15+ or higher.
     * @type {Array<NodePoolQuotaStatus>}
     * @memberof ProjectStatus
     */
    'quotaStatuses'?: Array<NodePoolQuotaStatus>;
}
/**
 * 
 * @export
 * @interface ProjectStatusRequest
 */
export interface ProjectStatusRequest {
    /**
     * A json representing the project\'s status
     * @type {string}
     * @memberof ProjectStatusRequest
     */
    'status'?: string;
    /**
     * Project Name
     * @type {string}
     * @memberof ProjectStatusRequest
     */
    'name'?: string;
    /**
     * name of the department owning the project
     * @type {string}
     * @memberof ProjectStatusRequest
     */
    'departmentName'?: string;
    /**
     * The current quota status for each node pool. Supported only for cluster versions 2.15+ or higher.
     * @type {Array<NodePoolQuotaStatus>}
     * @memberof ProjectStatusRequest
     */
    'quotaStatuses'?: Array<NodePoolQuotaStatus>;
}
/**
 * 
 * @export
 * @interface ProjectUpdateRequest
 */
export interface ProjectUpdateRequest {
    /**
     * Resources assigned to this Project per Node Pool.
     * @type {Array<NodePoolAssignedResources>}
     * @memberof ProjectUpdateRequest
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResources>;
    /**
     * Deprecated. Use \'deserved\' for the relevant resource type under `NodePoolResources`. The project\'s deserved GPU allocation in case the cluster has those resources.
     * @type {number}
     * @memberof ProjectUpdateRequest
     * @deprecated
     */
    'deservedGpus'?: number;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. An upper limit for the amount of GPUs the project can get (Even if over quota is allowed and resources are available).
     * @type {number}
     * @memberof ProjectUpdateRequest
     * @deprecated
     */
    'maxAllowedGpus'?: number;
    /**
     * Deprecated. Instead, use `overQuotaWeight` for the relevant resource type under `NodePoolResources`. The priority the project gets for over quota resources.
     * @type {number}
     * @memberof ProjectUpdateRequest
     * @deprecated
     */
    'gpuOverQuotaWeight'?: number;
    /**
     * Default node pools list for workload submission for this project if a workload doesn\'t specify a node pools list.
     * @type {Array<string>}
     * @memberof ProjectUpdateRequest
     */
    'defaultNodePools'?: Array<string>;
    /**
     * A limit (in seconds) for the duration of interactive jobs from this project.
     * @type {number}
     * @memberof ProjectUpdateRequest
     */
    'interactiveJobTimeLimitSecs'?: number;
    /**
     * Maximum duration (in seconds) that an interactive job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectUpdateRequest
     */
    'interactiveJobMaxIdleDurationSecs'?: number;
    /**
     * Maximum duration (in seconds) that an interactive preemptible job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectUpdateRequest
     */
    'interactivePreemptibleJobMaxIdleDurationSecs'?: number;
    /**
     * A limit (in seconds) for the duration of training jobs from this project. Available only from cluster version 2.12
     * @type {number}
     * @memberof ProjectUpdateRequest
     */
    'trainingJobTimeLimitSecs'?: number;
    /**
     * Maximum duration (in seconds) that a training job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectUpdateRequest
     */
    'trainingJobMaxIdleDurationSecs'?: number;
    /**
     * 
     * @type {ProjectCommonFieldsNodeAffinity}
     * @memberof ProjectUpdateRequest
     */
    'nodeAffinity'?: ProjectCommonFieldsNodeAffinity;
    /**
     * 
     * @type {ProjectCommonFieldsPermissions}
     * @memberof ProjectUpdateRequest
     * @deprecated
     */
    'permissions'?: ProjectCommonFieldsPermissions;
    /**
     * 
     * @type {ProjectCommonFieldsResources}
     * @memberof ProjectUpdateRequest
     * @deprecated
     */
    'resources'?: ProjectCommonFieldsResources;
}
/**
 * 
 * @export
 * @interface ProjectUpdateRequestAllOf
 */
export interface ProjectUpdateRequestAllOf {
    /**
     * Resources assigned to this Project per Node Pool.
     * @type {Array<NodePoolAssignedResources>}
     * @memberof ProjectUpdateRequestAllOf
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResources>;
}
/**
 * 
 * @export
 * @interface Projects
 */
export interface Projects {
    /**
     * 
     * @type {Array<ProjectsDataInner>}
     * @memberof Projects
     */
    'data': Array<ProjectsDataInner>;
    /**
     * 
     * @type {TimeRange}
     * @memberof Projects
     */
    'timeRange'?: TimeRange;
}
/**
 * 
 * @export
 * @interface ProjectsDataInner
 */
export interface ProjectsDataInner {
    /**
     * 
     * @type {ProjectMetadata}
     * @memberof ProjectsDataInner
     */
    'metadata': ProjectMetadata;
    /**
     * 
     * @type {ProjectCurrent}
     * @memberof ProjectsDataInner
     */
    'current': ProjectCurrent;
    /**
     * 
     * @type {any}
     * @memberof ProjectsDataInner
     */
    'timeRange'?: any;
}
/**
 * 
 * @export
 * @interface QuotaStatusResourceList
 */
export interface QuotaStatusResourceList {
    /**
     * The number of GPUs (allocated/requested/...).
     * @type {number}
     * @memberof QuotaStatusResourceList
     */
    'gpu'?: number;
    /**
     * CPU in Millicores (allocated/requested/...).
     * @type {number}
     * @memberof QuotaStatusResourceList
     */
    'cpu'?: number;
    /**
     * Memory in Mib (allocated/requested/...).
     * @type {number}
     * @memberof QuotaStatusResourceList
     */
    'memory'?: number;
}
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof Resource
     */
    'deserved'?: number;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof Resource
     */
    'maxAllowed'?: number;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof Resource
     */
    'overQuotaWeight'?: number;
}
/**
 * quota equals to the given resources and allocated equal to the real count of used resources
 * @export
 * @interface ResourceData
 */
export interface ResourceData {
    /**
     * 
     * @type {number}
     * @memberof ResourceData
     */
    'quota': number;
    /**
     * 
     * @type {number}
     * @memberof ResourceData
     */
    'allocated': number;
    /**
     * 
     * @type {number}
     * @memberof ResourceData
     */
    'utilization'?: number;
}
/**
 * 
 * @export
 * @interface ResourcePermissions
 */
export interface ResourcePermissions {
    /**
     * Names of users that have permissions to the project.
     * @type {Array<string>}
     * @memberof ResourcePermissions
     */
    'users'?: Array<string>;
    /**
     * Names of groups that have permissions to the project.
     * @type {Array<string>}
     * @memberof ResourcePermissions
     */
    'groups'?: Array<string>;
    /**
     * Names of applications that have permissions to the project.
     * @type {Array<string>}
     * @memberof ResourcePermissions
     */
    'applications'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ResourcesData
 */
export interface ResourcesData {
    /**
     * 
     * @type {number}
     * @memberof ResourcesData
     */
    'numberOfPendingWorkloads': number;
    /**
     * 
     * @type {ResourceData}
     * @memberof ResourcesData
     */
    'gpu': ResourceData;
    /**
     * 
     * @type {ResourceData}
     * @memberof ResourcesData
     */
    'cpu': ResourceData;
    /**
     * 
     * @type {ResourceData}
     * @memberof ResourcesData
     */
    'memory': ResourceData;
}
/**
 * 
 * @export
 * @interface ResourcesDataOvertime
 */
export interface ResourcesDataOvertime {
    /**
     * 
     * @type {number}
     * @memberof ResourcesDataOvertime
     */
    'numberOfPendingWorkloads': number;
    /**
     * 
     * @type {ResourceData}
     * @memberof ResourcesDataOvertime
     */
    'gpu': ResourceData;
    /**
     * 
     * @type {ResourceData}
     * @memberof ResourcesDataOvertime
     */
    'cpu': ResourceData;
    /**
     * 
     * @type {ResourceData}
     * @memberof ResourcesDataOvertime
     */
    'memory': ResourceData;
    /**
     * 
     * @type {string}
     * @memberof ResourcesDataOvertime
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface ResourcesDataOvertimeAllOf
 */
export interface ResourcesDataOvertimeAllOf {
    /**
     * 
     * @type {string}
     * @memberof ResourcesDataOvertimeAllOf
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Role = {
    Admin: 'admin',
    DepartmentAdmin: 'department_admin',
    Researcher: 'researcher',
    Editor: 'editor',
    Viewer: 'viewer',
    ResearchManager: 'research_manager',
    MlEngineer: 'ml_engineer'
} as const;

export type Role = typeof Role[keyof typeof Role];


/**
 * 
 * @export
 * @enum {string}
 */

export const ScopeType = {
    System: 'system',
    Tenant: 'tenant',
    Cluster: 'cluster',
    Department: 'department',
    Project: 'project'
} as const;

export type ScopeType = typeof ScopeType[keyof typeof ScopeType];


/**
 * 
 * @export
 * @interface Secret
 */
export interface Secret {
    /**
     * 
     * @type {SecretSecret}
     * @memberof Secret
     */
    'secret'?: SecretSecret;
}
/**
 * 
 * @export
 * @interface Secret1
 */
export interface Secret1 {
    /**
     * Identifier of the secret name.
     * @type {string}
     * @memberof Secret1
     */
    'secret_name': string;
}
/**
 * The secret.
 * @export
 * @interface SecretSecret
 */
export interface SecretSecret {
    /**
     * The type of the secret.
     * @type {string}
     * @memberof SecretSecret
     */
    'type'?: SecretSecretTypeEnum;
    /**
     * The value of the secret.
     * @type {string}
     * @memberof SecretSecret
     */
    'value'?: string;
}

export const SecretSecretTypeEnum = {
    Password: 'password',
    Secret: 'secret',
    Certificate: 'certificate'
} as const;

export type SecretSecretTypeEnum = typeof SecretSecretTypeEnum[keyof typeof SecretSecretTypeEnum];

/**
 * 
 * @export
 * @interface Service
 */
export interface Service {
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'clusterUuid': string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface ServicesSync
 */
export interface ServicesSync {
    /**
     * The namespace we are syncing objects in
     * @type {string}
     * @memberof ServicesSync
     */
    'namespace': string;
    /**
     * 
     * @type {Array<Service>}
     * @memberof ServicesSync
     */
    'services': Array<Service>;
}
/**
 * 
 * @export
 * @interface Setting
 */
export interface Setting {
    /**
     * 
     * @type {boolean}
     * @memberof Setting
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'stage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface StatusChange
 */
export interface StatusChange {
    /**
     * the workload status
     * @type {string}
     * @memberof StatusChange
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface StorageClass
 */
export interface StorageClass {
    /**
     * Identifier of the storage class
     * @type {string}
     * @memberof StorageClass
     */
    'storage_class_name': string;
    /**
     * Indicator if the storage class is the default storage class
     * @type {boolean}
     * @memberof StorageClass
     */
    'is_default'?: boolean;
}
/**
 * 
 * @export
 * @interface SubmissionError
 */
export interface SubmissionError {
    /**
     * 
     * @type {number}
     * @memberof SubmissionError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'details'?: string;
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof SubmissionError
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'authSettingsId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'displayName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'ssoAuthSettingsId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'authRealm'?: string;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof Tenant
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'eulaSigningUser'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'contractType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Tenant
     */
    'smgEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'authClientID'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'cliClientId'?: string;
}
/**
 * 
 * @export
 * @interface TenantSettingCreationRequest
 */
export interface TenantSettingCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantSettingCreationRequest
     */
    'key'?: string;
    /**
     * 
     * @type {any}
     * @memberof TenantSettingCreationRequest
     */
    'value'?: any;
}
/**
 * 
 * @export
 * @interface TenantSettingCreationResponse
 */
export interface TenantSettingCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof TenantSettingCreationResponse
     */
    'msg'?: string;
}
/**
 * Overtime metrics data. Returned only if both start and end query parameters are set.
 * @export
 * @interface TimeRange
 */
export interface TimeRange {
    /**
     * 
     * @type {Array<ResourcesDataOvertime>}
     * @memberof TimeRange
     */
    'resources': Array<ResourcesDataOvertime>;
}
/**
 * 
 * @export
 * @interface UpdateClusterLivenessRequest
 */
export interface UpdateClusterLivenessRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateClusterLivenessRequest
     */
    'timeTag'?: number;
}
/**
 * 
 * @export
 * @interface UpdateNodePoolRequest
 */
export interface UpdateNodePoolRequest {
    /**
     * key of node label for pool
     * @type {string}
     * @memberof UpdateNodePoolRequest
     */
    'labelKey'?: string;
    /**
     * value of node label for pool
     * @type {string}
     * @memberof UpdateNodePoolRequest
     */
    'labelValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateNodePoolRequest
     */
    'overProvisioningRatio'?: number;
    /**
     * 
     * @type {PlacementStrategy}
     * @memberof UpdateNodePoolRequest
     */
    'placementStrategy'?: PlacementStrategy;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {AuthEntityType}
     * @memberof User
     */
    'entityType'?: AuthEntityType;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof User
     */
    'tenantId'?: number;
    /**
     * Unique identifier of the user
     * @type {string}
     * @memberof User
     */
    'userId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'permitAllClusters'?: boolean;
    /**
     * A list of clusters that the user or application can access.
     * @type {Array<string>}
     * @memberof User
     */
    'permittedClusters'?: Array<string>;
    /**
     * 
     * @type {Array<Role>}
     * @memberof User
     */
    'roles'?: Array<Role>;
    /**
     * The creation date of the application.
     * @type {string}
     * @memberof User
     */
    'createdAt'?: string;
    /**
     * Email address of the user.
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * The last time the user logged into the system.
     * @type {string}
     * @memberof User
     */
    'lastLogin'?: string;
}
/**
 * 
 * @export
 * @interface User1
 */
export interface User1 {
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'role': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User1
     */
    'roles'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof User1
     */
    'groups'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UserAllOf
 */
export interface UserAllOf {
    /**
     * Email address of the user.
     * @type {string}
     * @memberof UserAllOf
     */
    'email'?: string;
    /**
     * The last time the user logged into the system.
     * @type {string}
     * @memberof UserAllOf
     */
    'lastLogin'?: string;
}
/**
 * 
 * @export
 * @interface UserCreationRequest
 */
export interface UserCreationRequest {
    /**
     * Email address of the user.
     * @type {string}
     * @memberof UserCreationRequest
     */
    'email': string;
    /**
     * 
     * @type {Array<Role>}
     * @memberof UserCreationRequest
     */
    'roles': Array<Role>;
    /**
     * 
     * @type {AuthEntityType}
     * @memberof UserCreationRequest
     */
    'entityType'?: AuthEntityType;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof UserCreationRequest
     */
    'tenantId'?: number;
    /**
     * The user\'s password.
     * @type {string}
     * @memberof UserCreationRequest
     */
    'password': string;
    /**
     * True if the user is requested to change his password upon next login.
     * @type {boolean}
     * @memberof UserCreationRequest
     */
    'needToChangePassword'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreationRequest
     */
    'permitAllClusters'?: boolean;
    /**
     * Unique identifier of the user
     * @type {string}
     * @memberof UserCreationRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCreationRequest
     */
    'permittedClusters'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UserCreationResponse
 */
export interface UserCreationResponse {
    /**
     * Email address of the user.
     * @type {string}
     * @memberof UserCreationResponse
     */
    'email': string;
    /**
     * 
     * @type {Array<Role>}
     * @memberof UserCreationResponse
     */
    'roles': Array<Role>;
    /**
     * 
     * @type {AuthEntityType}
     * @memberof UserCreationResponse
     */
    'entityType'?: AuthEntityType;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof UserCreationResponse
     */
    'tenantId'?: number;
    /**
     * The user\'s password.
     * @type {string}
     * @memberof UserCreationResponse
     */
    'password': string;
    /**
     * True if the user is requested to change his password upon next login.
     * @type {boolean}
     * @memberof UserCreationResponse
     */
    'needToChangePassword'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreationResponse
     */
    'permitAllClusters'?: boolean;
    /**
     * Unique identifier of the user
     * @type {string}
     * @memberof UserCreationResponse
     */
    'userId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCreationResponse
     */
    'permittedClusters'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserType = {
    SsoUser: 'sso-user',
    RegularUser: 'regular-user'
} as const;

export type UserType = typeof UserType[keyof typeof UserType];


/**
 * 
 * @export
 * @interface Users
 */
export interface Users {
    /**
     * 
     * @type {Array<User1>}
     * @memberof Users
     */
    'users': Array<User1>;
}
/**
 * 
 * @export
 * @interface V1K8sClustersUuidDelete200Response
 */
export interface V1K8sClustersUuidDelete200Response {
    /**
     * 
     * @type {string}
     * @memberof V1K8sClustersUuidDelete200Response
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @interface WandbSweepsRequest
 */
export interface WandbSweepsRequest {
    /**
     * Wandb API key.
     * @type {string}
     * @memberof WandbSweepsRequest
     */
    'apiKey': string;
    /**
     * Wandb host URL, to be provided in case of a self-hosted W&B installation.
     * @type {string}
     * @memberof WandbSweepsRequest
     */
    'baseUrl'?: string | null;
    /**
     * Wandb project.
     * @type {string}
     * @memberof WandbSweepsRequest
     */
    'project': string;
    /**
     * Sweep config JSON object.
     * @type {object}
     * @memberof WandbSweepsRequest
     */
    'config': object;
}
/**
 * 
 * @export
 * @interface WandbSweepsResponse
 */
export interface WandbSweepsResponse {
    /**
     * 
     * @type {string}
     * @memberof WandbSweepsResponse
     */
    'sweepId'?: string;
}
/**
 * 
 * @export
 * @interface WorkloadEvent
 */
export interface WorkloadEvent {
    /**
     * clusterUuid of the workload event
     * @type {string}
     * @memberof WorkloadEvent
     */
    'clusterUuid': string;
    /**
     * tenantId of the workload event
     * @type {number}
     * @memberof WorkloadEvent
     */
    'tenantId': number;
    /**
     * uid of the workload event
     * @type {string}
     * @memberof WorkloadEvent
     */
    'uid': string;
    /**
     * type of the workload event
     * @type {string}
     * @memberof WorkloadEvent
     */
    'type': string;
    /**
     * message of the workload event
     * @type {string}
     * @memberof WorkloadEvent
     */
    'message': string;
    /**
     * reason of the workload event
     * @type {string}
     * @memberof WorkloadEvent
     */
    'reason': string;
    /**
     * the event source component
     * @type {string}
     * @memberof WorkloadEvent
     */
    'sourceComponent': string;
    /**
     * involvedObjUID of the workload event
     * @type {string}
     * @memberof WorkloadEvent
     */
    'involvedObjUID': string;
    /**
     * involvedObjKind of the workload event
     * @type {string}
     * @memberof WorkloadEvent
     */
    'involvedObjKind': string;
    /**
     * involvedObjName of the workload event
     * @type {string}
     * @memberof WorkloadEvent
     */
    'involvedObjName': string;
    /**
     * involvedObjNamespace of the workload event
     * @type {string}
     * @memberof WorkloadEvent
     */
    'involvedObjNamespace': string;
}
/**
 * 
 * @export
 * @interface WorkloadHistoryMeta
 */
export interface WorkloadHistoryMeta {
    /**
     * the creation timestamp of the workload history item
     * @type {number}
     * @memberof WorkloadHistoryMeta
     */
    'creationTimestamp': number;
    /**
     * the type of the workload history item
     * @type {string}
     * @memberof WorkloadHistoryMeta
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface WorkloadHistoryResponse
 */
export interface WorkloadHistoryResponse {
    /**
     * 
     * @type {Array<WorkloadHistoryResponseEntriesInner>}
     * @memberof WorkloadHistoryResponse
     */
    'entries': Array<WorkloadHistoryResponseEntriesInner>;
}
/**
 * 
 * @export
 * @interface WorkloadHistoryResponseEntriesInner
 */
export interface WorkloadHistoryResponseEntriesInner {
    /**
     * 
     * @type {WorkloadHistoryMeta}
     * @memberof WorkloadHistoryResponseEntriesInner
     */
    'meta': WorkloadHistoryMeta;
    /**
     * 
     * @type {WorkloadHistorySpec}
     * @memberof WorkloadHistoryResponseEntriesInner
     */
    'spec': WorkloadHistorySpec;
}
/**
 * 
 * @export
 * @interface WorkloadHistorySpec
 */
export interface WorkloadHistorySpec {
    /**
     * 
     * @type {WorkloadEvent}
     * @memberof WorkloadHistorySpec
     */
    'workloadEvent'?: WorkloadEvent;
    /**
     * 
     * @type {StatusChange}
     * @memberof WorkloadHistorySpec
     */
    'statusChange'?: StatusChange;
}
/**
 * 
 * @export
 * @interface WorkloadStatus
 */
export interface WorkloadStatus {
    /**
     * The unique id identifying the workspace/training workload.
     * @type {string}
     * @memberof WorkloadStatus
     */
    'id': string;
    /**
     * Status of a workload
     * @type {string}
     * @memberof WorkloadStatus
     */
    'status': WorkloadStatusStatusEnum;
}

export const WorkloadStatusStatusEnum = {
    Submitted: 'Submitted',
    Failed: 'Failed'
} as const;

export type WorkloadStatusStatusEnum = typeof WorkloadStatusStatusEnum[keyof typeof WorkloadStatusStatusEnum];


/**
 * ClustersApi - axios parameter creator
 * @export
 */
export const ClustersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new cluster for the tenant. This endpoint requires ADMIN role.
         * @summary Create a cluster
         * @param {CreateCluster} createCluster 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createCluster: async (createCluster: CreateCluster, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCluster' is not null or undefined
            assertParamExists('createCluster', 'createCluster', createCluster)
            const localVarPath = `/v1/k8s/clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCluster, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the installation values file of a cluster by Retrieve the installation values file of a given cluster by ID.  Supports clusters 2.13 and lower. 
         * @summary Get cluster installation file by id.
         * @param {string} clusterUuid Unique identifier of the cluster.
         * @param {'gke' | 'aws' | 'aks' | 'op' | 'airgapped' | 'openshift'} [cloud] Cloud type identifier.
         * @param {string} [clusterip] Comma-separated list of IP addresses that provide access to the cluster.
         * @param {'json' | 'yaml'} [format] Format of the output file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallFile: async (clusterUuid: string, cloud?: 'gke' | 'aws' | 'aks' | 'op' | 'airgapped' | 'openshift', clusterip?: string, format?: 'json' | 'yaml', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getInstallFile', 'clusterUuid', clusterUuid)
            const localVarPath = `/v1/k8s/clusters/{cluster_uuid}/installfile`
                .replace(`{${"cluster_uuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cloud !== undefined) {
                localVarQueryParameter['cloud'] = cloud;
            }

            if (clusterip !== undefined) {
                localVarQueryParameter['clusterip'] = clusterip;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete all cluster sub-resources
         * @param {string} uuid Unique identifier of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalClustersUuidSubResourcesDelete: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('internalClustersUuidSubResourcesDelete', 'uuid', uuid)
            const localVarPath = `/internal/clusters/{uuid}/sub-resources`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create all cluster sub-resources
         * @param {string} uuid Unique identifier of the cluster.
         * @param {ClusterMetaData} clusterMetaData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalClustersUuidSubResourcesPost: async (uuid: string, clusterMetaData: ClusterMetaData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('internalClustersUuidSubResourcesPost', 'uuid', uuid)
            // verify required parameter 'clusterMetaData' is not null or undefined
            assertParamExists('internalClustersUuidSubResourcesPost', 'clusterMetaData', clusterMetaData)
            const localVarPath = `/internal/clusters/{uuid}/sub-resources`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterMetaData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the details of a given cluster. This endpoint requires ADMIN role.
         * @summary Update a Cluster by Id
         * @param {string} uuid Unique identifier of the cluster.
         * @param {Cluster1} cluster1 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateCluster: async (uuid: string, cluster1: Cluster1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('updateCluster', 'uuid', uuid)
            // verify required parameter 'cluster1' is not null or undefined
            assertParamExists('updateCluster', 'cluster1', cluster1)
            const localVarPath = `/v1/k8s/clusters/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cluster1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send cluster-info. This endpoint is reserved for internal run:ai purposes.
         * @param {string} uuid Unique identifier of the cluster
         * @param {ClusterInfo} clusterInfo 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateClusterInfo: async (uuid: string, clusterInfo: ClusterInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('updateClusterInfo', 'uuid', uuid)
            // verify required parameter 'clusterInfo' is not null or undefined
            assertParamExists('updateClusterInfo', 'clusterInfo', clusterInfo)
            const localVarPath = `/v1/k8s/clusters/{uuid}/cluster-info`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update liveness status for a specific cluster. This endpoint is reserved for internal run:ai purposes.
         * @param {string} uuid Unique identifier of the cluster
         * @param {UpdateClusterLivenessRequest} updateClusterLivenessRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateClusterLiveness: async (uuid: string, updateClusterLivenessRequest: UpdateClusterLivenessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('updateClusterLiveness', 'uuid', uuid)
            // verify required parameter 'updateClusterLivenessRequest' is not null or undefined
            assertParamExists('updateClusterLiveness', 'updateClusterLivenessRequest', updateClusterLivenessRequest)
            const localVarPath = `/v1/k8s/clusters/{uuid}/liveness`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateClusterLivenessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the configuration of a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Get cluster configuration.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of a cluster.
         * @param {string} [clusterDomain] The domain of the cluster.
         * @param {string} [clusterVersion] The version of the configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1K8sClustersClusterUuidConfigGet: async (clusterUuid: string, clusterDomain?: string, clusterVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('v1K8sClustersClusterUuidConfigGet', 'clusterUuid', clusterUuid)
            const localVarPath = `/v1/k8s/clusters/{cluster_uuid}/config`
                .replace(`{${"cluster_uuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterDomain !== undefined) {
                localVarQueryParameter['cluster_domain'] = clusterDomain;
            }

            if (clusterVersion !== undefined) {
                localVarQueryParameter['cluster_version'] = clusterVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of all the clusters of the tenant.
         * @summary List clusters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1K8sClustersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/k8s/clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Cluster by Cluster ID. This endpoint requires ADMIN role.
         * @summary Delete a Cluster
         * @param {string} uuid Unique identifier of the cluster.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1K8sClustersUuidDelete: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('v1K8sClustersUuidDelete', 'uuid', uuid)
            const localVarPath = `/v1/k8s/clusters/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a cluster given a cluster id. This endpoint requires ADMIN role.
         * @summary Get cluster by id
         * @param {string} uuid Unique identifier of the cluster.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1K8sClustersUuidGet: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('v1K8sClustersUuidGet', 'uuid', uuid)
            const localVarPath = `/v1/k8s/clusters/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClustersApi - functional programming interface
 * @export
 */
export const ClustersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClustersApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new cluster for the tenant. This endpoint requires ADMIN role.
         * @summary Create a cluster
         * @param {CreateCluster} createCluster 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createCluster(createCluster: CreateCluster, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cluster1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCluster(createCluster, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the installation values file of a cluster by Retrieve the installation values file of a given cluster by ID.  Supports clusters 2.13 and lower. 
         * @summary Get cluster installation file by id.
         * @param {string} clusterUuid Unique identifier of the cluster.
         * @param {'gke' | 'aws' | 'aks' | 'op' | 'airgapped' | 'openshift'} [cloud] Cloud type identifier.
         * @param {string} [clusterip] Comma-separated list of IP addresses that provide access to the cluster.
         * @param {'json' | 'yaml'} [format] Format of the output file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstallFile(clusterUuid: string, cloud?: 'gke' | 'aws' | 'aks' | 'op' | 'airgapped' | 'openshift', clusterip?: string, format?: 'json' | 'yaml', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstallFile(clusterUuid, cloud, clusterip, format, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete all cluster sub-resources
         * @param {string} uuid Unique identifier of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalClustersUuidSubResourcesDelete(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalClustersUuidSubResourcesDelete(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create all cluster sub-resources
         * @param {string} uuid Unique identifier of the cluster.
         * @param {ClusterMetaData} clusterMetaData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalClustersUuidSubResourcesPost(uuid: string, clusterMetaData: ClusterMetaData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalClustersUuidSubResourcesPost(uuid, clusterMetaData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the details of a given cluster. This endpoint requires ADMIN role.
         * @summary Update a Cluster by Id
         * @param {string} uuid Unique identifier of the cluster.
         * @param {Cluster1} cluster1 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateCluster(uuid: string, cluster1: Cluster1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cluster1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCluster(uuid, cluster1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send cluster-info. This endpoint is reserved for internal run:ai purposes.
         * @param {string} uuid Unique identifier of the cluster
         * @param {ClusterInfo} clusterInfo 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateClusterInfo(uuid: string, clusterInfo: ClusterInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClusterInfo(uuid, clusterInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update liveness status for a specific cluster. This endpoint is reserved for internal run:ai purposes.
         * @param {string} uuid Unique identifier of the cluster
         * @param {UpdateClusterLivenessRequest} updateClusterLivenessRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateClusterLiveness(uuid: string, updateClusterLivenessRequest: UpdateClusterLivenessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClusterLiveness(uuid, updateClusterLivenessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the configuration of a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Get cluster configuration.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of a cluster.
         * @param {string} [clusterDomain] The domain of the cluster.
         * @param {string} [clusterVersion] The version of the configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1K8sClustersClusterUuidConfigGet(clusterUuid: string, clusterDomain?: string, clusterVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1K8sClustersClusterUuidConfigGet(clusterUuid, clusterDomain, clusterVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a list of all the clusters of the tenant.
         * @summary List clusters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1K8sClustersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Cluster1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1K8sClustersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a Cluster by Cluster ID. This endpoint requires ADMIN role.
         * @summary Delete a Cluster
         * @param {string} uuid Unique identifier of the cluster.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1K8sClustersUuidDelete(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1K8sClustersUuidDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1K8sClustersUuidDelete(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a cluster given a cluster id. This endpoint requires ADMIN role.
         * @summary Get cluster by id
         * @param {string} uuid Unique identifier of the cluster.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1K8sClustersUuidGet(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cluster1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1K8sClustersUuidGet(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClustersApi - factory interface
 * @export
 */
export const ClustersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClustersApiFp(configuration)
    return {
        /**
         * Create a new cluster for the tenant. This endpoint requires ADMIN role.
         * @summary Create a cluster
         * @param {CreateCluster} createCluster 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createCluster(createCluster: CreateCluster, options?: any): AxiosPromise<Cluster1> {
            return localVarFp.createCluster(createCluster, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the installation values file of a cluster by Retrieve the installation values file of a given cluster by ID.  Supports clusters 2.13 and lower. 
         * @summary Get cluster installation file by id.
         * @param {string} clusterUuid Unique identifier of the cluster.
         * @param {'gke' | 'aws' | 'aks' | 'op' | 'airgapped' | 'openshift'} [cloud] Cloud type identifier.
         * @param {string} [clusterip] Comma-separated list of IP addresses that provide access to the cluster.
         * @param {'json' | 'yaml'} [format] Format of the output file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallFile(clusterUuid: string, cloud?: 'gke' | 'aws' | 'aks' | 'op' | 'airgapped' | 'openshift', clusterip?: string, format?: 'json' | 'yaml', options?: any): AxiosPromise<string> {
            return localVarFp.getInstallFile(clusterUuid, cloud, clusterip, format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete all cluster sub-resources
         * @param {string} uuid Unique identifier of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalClustersUuidSubResourcesDelete(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalClustersUuidSubResourcesDelete(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create all cluster sub-resources
         * @param {string} uuid Unique identifier of the cluster.
         * @param {ClusterMetaData} clusterMetaData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalClustersUuidSubResourcesPost(uuid: string, clusterMetaData: ClusterMetaData, options?: any): AxiosPromise<void> {
            return localVarFp.internalClustersUuidSubResourcesPost(uuid, clusterMetaData, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the details of a given cluster. This endpoint requires ADMIN role.
         * @summary Update a Cluster by Id
         * @param {string} uuid Unique identifier of the cluster.
         * @param {Cluster1} cluster1 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateCluster(uuid: string, cluster1: Cluster1, options?: any): AxiosPromise<Cluster1> {
            return localVarFp.updateCluster(uuid, cluster1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send cluster-info. This endpoint is reserved for internal run:ai purposes.
         * @param {string} uuid Unique identifier of the cluster
         * @param {ClusterInfo} clusterInfo 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateClusterInfo(uuid: string, clusterInfo: ClusterInfo, options?: any): AxiosPromise<void> {
            return localVarFp.updateClusterInfo(uuid, clusterInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update liveness status for a specific cluster. This endpoint is reserved for internal run:ai purposes.
         * @param {string} uuid Unique identifier of the cluster
         * @param {UpdateClusterLivenessRequest} updateClusterLivenessRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateClusterLiveness(uuid: string, updateClusterLivenessRequest: UpdateClusterLivenessRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateClusterLiveness(uuid, updateClusterLivenessRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the configuration of a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Get cluster configuration.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of a cluster.
         * @param {string} [clusterDomain] The domain of the cluster.
         * @param {string} [clusterVersion] The version of the configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1K8sClustersClusterUuidConfigGet(clusterUuid: string, clusterDomain?: string, clusterVersion?: string, options?: any): AxiosPromise<object> {
            return localVarFp.v1K8sClustersClusterUuidConfigGet(clusterUuid, clusterDomain, clusterVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of all the clusters of the tenant.
         * @summary List clusters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1K8sClustersGet(options?: any): AxiosPromise<Array<Cluster1>> {
            return localVarFp.v1K8sClustersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Cluster by Cluster ID. This endpoint requires ADMIN role.
         * @summary Delete a Cluster
         * @param {string} uuid Unique identifier of the cluster.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1K8sClustersUuidDelete(uuid: string, options?: any): AxiosPromise<V1K8sClustersUuidDelete200Response> {
            return localVarFp.v1K8sClustersUuidDelete(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a cluster given a cluster id. This endpoint requires ADMIN role.
         * @summary Get cluster by id
         * @param {string} uuid Unique identifier of the cluster.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1K8sClustersUuidGet(uuid: string, options?: any): AxiosPromise<Cluster1> {
            return localVarFp.v1K8sClustersUuidGet(uuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClustersApi - object-oriented interface
 * @export
 * @class ClustersApi
 * @extends {BaseAPI}
 */
export class ClustersApi extends BaseAPI {
    /**
     * Create a new cluster for the tenant. This endpoint requires ADMIN role.
     * @summary Create a cluster
     * @param {CreateCluster} createCluster 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public createCluster(createCluster: CreateCluster, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).createCluster(createCluster, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the installation values file of a cluster by Retrieve the installation values file of a given cluster by ID.  Supports clusters 2.13 and lower. 
     * @summary Get cluster installation file by id.
     * @param {string} clusterUuid Unique identifier of the cluster.
     * @param {'gke' | 'aws' | 'aks' | 'op' | 'airgapped' | 'openshift'} [cloud] Cloud type identifier.
     * @param {string} [clusterip] Comma-separated list of IP addresses that provide access to the cluster.
     * @param {'json' | 'yaml'} [format] Format of the output file.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getInstallFile(clusterUuid: string, cloud?: 'gke' | 'aws' | 'aks' | 'op' | 'airgapped' | 'openshift', clusterip?: string, format?: 'json' | 'yaml', options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getInstallFile(clusterUuid, cloud, clusterip, format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete all cluster sub-resources
     * @param {string} uuid Unique identifier of the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public internalClustersUuidSubResourcesDelete(uuid: string, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).internalClustersUuidSubResourcesDelete(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create all cluster sub-resources
     * @param {string} uuid Unique identifier of the cluster.
     * @param {ClusterMetaData} clusterMetaData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public internalClustersUuidSubResourcesPost(uuid: string, clusterMetaData: ClusterMetaData, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).internalClustersUuidSubResourcesPost(uuid, clusterMetaData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the details of a given cluster. This endpoint requires ADMIN role.
     * @summary Update a Cluster by Id
     * @param {string} uuid Unique identifier of the cluster.
     * @param {Cluster1} cluster1 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public updateCluster(uuid: string, cluster1: Cluster1, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).updateCluster(uuid, cluster1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send cluster-info. This endpoint is reserved for internal run:ai purposes.
     * @param {string} uuid Unique identifier of the cluster
     * @param {ClusterInfo} clusterInfo 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public updateClusterInfo(uuid: string, clusterInfo: ClusterInfo, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).updateClusterInfo(uuid, clusterInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update liveness status for a specific cluster. This endpoint is reserved for internal run:ai purposes.
     * @param {string} uuid Unique identifier of the cluster
     * @param {UpdateClusterLivenessRequest} updateClusterLivenessRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public updateClusterLiveness(uuid: string, updateClusterLivenessRequest: UpdateClusterLivenessRequest, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).updateClusterLiveness(uuid, updateClusterLivenessRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the configuration of a Kubernetes cluster by Universally Unique Identifier (UUID).
     * @summary Get cluster configuration.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of a cluster.
     * @param {string} [clusterDomain] The domain of the cluster.
     * @param {string} [clusterVersion] The version of the configuration.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public v1K8sClustersClusterUuidConfigGet(clusterUuid: string, clusterDomain?: string, clusterVersion?: string, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).v1K8sClustersClusterUuidConfigGet(clusterUuid, clusterDomain, clusterVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of all the clusters of the tenant.
     * @summary List clusters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public v1K8sClustersGet(options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).v1K8sClustersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Cluster by Cluster ID. This endpoint requires ADMIN role.
     * @summary Delete a Cluster
     * @param {string} uuid Unique identifier of the cluster.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public v1K8sClustersUuidDelete(uuid: string, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).v1K8sClustersUuidDelete(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a cluster given a cluster id. This endpoint requires ADMIN role.
     * @summary Get cluster by id
     * @param {string} uuid Unique identifier of the cluster.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public v1K8sClustersUuidGet(uuid: string, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).v1K8sClustersUuidGet(uuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DepartmentsApi - axios parameter creator
 * @export
 */
export const DepartmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new department in the cluster.
         * @summary Create a new department.
         * @param {string} clusterId The unique uuid identifying the cluster.
         * @param {DepartmentCreateRequest} departmentCreateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createDepartment: async (clusterId: string, departmentCreateRequest: DepartmentCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('createDepartment', 'clusterId', clusterId)
            // verify required parameter 'departmentCreateRequest' is not null or undefined
            assertParamExists('createDepartment', 'departmentCreateRequest', departmentCreateRequest)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/departments`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a department from a specific cluster.
         * @summary Delete a department.
         * @param {string} clusterId The unique uuid identifying the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteDepartment: async (clusterId: string, departmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('deleteDepartment', 'clusterId', clusterId)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('deleteDepartment', 'departmentId', departmentId)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/departments/{department-id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"department-id"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the details of a specific department. Requires  the`view` permission for the department.
         * @summary Get a specific department.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} departmentId The unique id of the department.
         * @param {boolean} [excludePermissions] backwards compatibility of the \&#39;departmentAdmins\&#39; field. if \&#39;true\&#39;, will not set the \&#39;departmentAdmins\&#39; field in the returned department. if \&#39;false\&#39;, will set the \&#39;departmentAdmins\&#39; field in the returned department.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDepartment: async (clusterId: string, departmentId: number, excludePermissions?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getDepartment', 'clusterId', clusterId)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('getDepartment', 'departmentId', departmentId)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/departments/{department-id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"department-id"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (excludePermissions !== undefined) {
                localVarQueryParameter['excludePermissions'] = excludePermissions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all the departments managed by the tenant on a specific cluster.
         * @summary List all departments.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {boolean} [memoryUnitMb] Memory returned in MB. When set to &#x60;false&#x60; (default) memory will be returned in MiB.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDepartments: async (clusterId: string, excludePermissions?: boolean, memoryUnitMb?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getDepartments', 'clusterId', clusterId)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/departments`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (excludePermissions !== undefined) {
                localVarQueryParameter['excludePermissions'] = excludePermissions;
            }

            if (memoryUnitMb !== undefined) {
                localVarQueryParameter['memoryUnitMb'] = memoryUnitMb;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a department\'s details in the cluster. \\n For example, node pools and other details.
         * @summary Update a department.
         * @param {string} clusterId The unique uuid identifying the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {DepartmentUpdateRequest} departmentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDepartment: async (clusterId: string, departmentId: number, departmentUpdateRequest: DepartmentUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('updateDepartment', 'clusterId', clusterId)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('updateDepartment', 'departmentId', departmentId)
            // verify required parameter 'departmentUpdateRequest' is not null or undefined
            assertParamExists('updateDepartment', 'departmentUpdateRequest', departmentUpdateRequest)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/departments/{department-id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"department-id"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deprecated. Instead, use the accessrules API to add the department-admin permissions to a specific subject.
         * @summary Set the department admins.
         * @param {string} clusterId The unique uuid identifying the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {DepartmentAccessControl} departmentAccessControl 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDepartmentAdmins: async (clusterId: string, departmentId: number, departmentAccessControl: DepartmentAccessControl, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('updateDepartmentAdmins', 'clusterId', clusterId)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('updateDepartmentAdmins', 'departmentId', departmentId)
            // verify required parameter 'departmentAccessControl' is not null or undefined
            assertParamExists('updateDepartmentAdmins', 'departmentAccessControl', departmentAccessControl)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/departments/{department-id}/access-control`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"department-id"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentAccessControl, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentsApi - functional programming interface
 * @export
 */
export const DepartmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new department in the cluster.
         * @summary Create a new department.
         * @param {string} clusterId The unique uuid identifying the cluster.
         * @param {DepartmentCreateRequest} departmentCreateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createDepartment(clusterId: string, departmentCreateRequest: DepartmentCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Department1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDepartment(clusterId, departmentCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a department from a specific cluster.
         * @summary Delete a department.
         * @param {string} clusterId The unique uuid identifying the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteDepartment(clusterId: string, departmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Department1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDepartment(clusterId, departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the details of a specific department. Requires  the`view` permission for the department.
         * @summary Get a specific department.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} departmentId The unique id of the department.
         * @param {boolean} [excludePermissions] backwards compatibility of the \&#39;departmentAdmins\&#39; field. if \&#39;true\&#39;, will not set the \&#39;departmentAdmins\&#39; field in the returned department. if \&#39;false\&#39;, will set the \&#39;departmentAdmins\&#39; field in the returned department.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getDepartment(clusterId: string, departmentId: number, excludePermissions?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Department1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartment(clusterId, departmentId, excludePermissions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all the departments managed by the tenant on a specific cluster.
         * @summary List all departments.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {boolean} [memoryUnitMb] Memory returned in MB. When set to &#x60;false&#x60; (default) memory will be returned in MiB.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getDepartments(clusterId: string, excludePermissions?: boolean, memoryUnitMb?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Department1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartments(clusterId, excludePermissions, memoryUnitMb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a department\'s details in the cluster. \\n For example, node pools and other details.
         * @summary Update a department.
         * @param {string} clusterId The unique uuid identifying the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {DepartmentUpdateRequest} departmentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateDepartment(clusterId: string, departmentId: number, departmentUpdateRequest: DepartmentUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Department1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDepartment(clusterId, departmentId, departmentUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deprecated. Instead, use the accessrules API to add the department-admin permissions to a specific subject.
         * @summary Set the department admins.
         * @param {string} clusterId The unique uuid identifying the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {DepartmentAccessControl} departmentAccessControl 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateDepartmentAdmins(clusterId: string, departmentId: number, departmentAccessControl: DepartmentAccessControl, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentAccessControl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDepartmentAdmins(clusterId, departmentId, departmentAccessControl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepartmentsApi - factory interface
 * @export
 */
export const DepartmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentsApiFp(configuration)
    return {
        /**
         * Creates a new department in the cluster.
         * @summary Create a new department.
         * @param {string} clusterId The unique uuid identifying the cluster.
         * @param {DepartmentCreateRequest} departmentCreateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createDepartment(clusterId: string, departmentCreateRequest: DepartmentCreateRequest, options?: any): AxiosPromise<Department1> {
            return localVarFp.createDepartment(clusterId, departmentCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a department from a specific cluster.
         * @summary Delete a department.
         * @param {string} clusterId The unique uuid identifying the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteDepartment(clusterId: string, departmentId: number, options?: any): AxiosPromise<Department1> {
            return localVarFp.deleteDepartment(clusterId, departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the details of a specific department. Requires  the`view` permission for the department.
         * @summary Get a specific department.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} departmentId The unique id of the department.
         * @param {boolean} [excludePermissions] backwards compatibility of the \&#39;departmentAdmins\&#39; field. if \&#39;true\&#39;, will not set the \&#39;departmentAdmins\&#39; field in the returned department. if \&#39;false\&#39;, will set the \&#39;departmentAdmins\&#39; field in the returned department.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDepartment(clusterId: string, departmentId: number, excludePermissions?: boolean, options?: any): AxiosPromise<Department1> {
            return localVarFp.getDepartment(clusterId, departmentId, excludePermissions, options).then((request) => request(axios, basePath));
        },
        /**
         * List all the departments managed by the tenant on a specific cluster.
         * @summary List all departments.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {boolean} [memoryUnitMb] Memory returned in MB. When set to &#x60;false&#x60; (default) memory will be returned in MiB.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDepartments(clusterId: string, excludePermissions?: boolean, memoryUnitMb?: boolean, options?: any): AxiosPromise<Array<Department1>> {
            return localVarFp.getDepartments(clusterId, excludePermissions, memoryUnitMb, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a department\'s details in the cluster. \\n For example, node pools and other details.
         * @summary Update a department.
         * @param {string} clusterId The unique uuid identifying the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {DepartmentUpdateRequest} departmentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDepartment(clusterId: string, departmentId: number, departmentUpdateRequest: DepartmentUpdateRequest, options?: any): AxiosPromise<Department1> {
            return localVarFp.updateDepartment(clusterId, departmentId, departmentUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deprecated. Instead, use the accessrules API to add the department-admin permissions to a specific subject.
         * @summary Set the department admins.
         * @param {string} clusterId The unique uuid identifying the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {DepartmentAccessControl} departmentAccessControl 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDepartmentAdmins(clusterId: string, departmentId: number, departmentAccessControl: DepartmentAccessControl, options?: any): AxiosPromise<DepartmentAccessControl> {
            return localVarFp.updateDepartmentAdmins(clusterId, departmentId, departmentAccessControl, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepartmentsApi - object-oriented interface
 * @export
 * @class DepartmentsApi
 * @extends {BaseAPI}
 */
export class DepartmentsApi extends BaseAPI {
    /**
     * Creates a new department in the cluster.
     * @summary Create a new department.
     * @param {string} clusterId The unique uuid identifying the cluster.
     * @param {DepartmentCreateRequest} departmentCreateRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public createDepartment(clusterId: string, departmentCreateRequest: DepartmentCreateRequest, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).createDepartment(clusterId, departmentCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a department from a specific cluster.
     * @summary Delete a department.
     * @param {string} clusterId The unique uuid identifying the cluster.
     * @param {number} departmentId The unique id identifying the department.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public deleteDepartment(clusterId: string, departmentId: number, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).deleteDepartment(clusterId, departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the details of a specific department. Requires  the`view` permission for the department.
     * @summary Get a specific department.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {number} departmentId The unique id of the department.
     * @param {boolean} [excludePermissions] backwards compatibility of the \&#39;departmentAdmins\&#39; field. if \&#39;true\&#39;, will not set the \&#39;departmentAdmins\&#39; field in the returned department. if \&#39;false\&#39;, will set the \&#39;departmentAdmins\&#39; field in the returned department.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getDepartment(clusterId: string, departmentId: number, excludePermissions?: boolean, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).getDepartment(clusterId, departmentId, excludePermissions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all the departments managed by the tenant on a specific cluster.
     * @summary List all departments.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
     * @param {boolean} [memoryUnitMb] Memory returned in MB. When set to &#x60;false&#x60; (default) memory will be returned in MiB.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getDepartments(clusterId: string, excludePermissions?: boolean, memoryUnitMb?: boolean, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).getDepartments(clusterId, excludePermissions, memoryUnitMb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a department\'s details in the cluster. \\n For example, node pools and other details.
     * @summary Update a department.
     * @param {string} clusterId The unique uuid identifying the cluster.
     * @param {number} departmentId The unique id identifying the department.
     * @param {DepartmentUpdateRequest} departmentUpdateRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public updateDepartment(clusterId: string, departmentId: number, departmentUpdateRequest: DepartmentUpdateRequest, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).updateDepartment(clusterId, departmentId, departmentUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deprecated. Instead, use the accessrules API to add the department-admin permissions to a specific subject.
     * @summary Set the department admins.
     * @param {string} clusterId The unique uuid identifying the cluster.
     * @param {number} departmentId The unique id identifying the department.
     * @param {DepartmentAccessControl} departmentAccessControl 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public updateDepartmentAdmins(clusterId: string, departmentId: number, departmentAccessControl: DepartmentAccessControl, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).updateDepartmentAdmins(clusterId, departmentId, departmentAccessControl, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalApi - axios parameter creator
 * @export
 */
export const InternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the ancestors resource of a scoped resource.
         * @summary Get scoped resource ancestors.
         * @param {string} resourceType 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAncestors: async (resourceType: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('getAncestors', 'resourceType', resourceType)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAncestors', 'id', id)
            const localVarPath = `/internal/{resourceType}/{id}/ancestors`
                .replace(`{${"resourceType"}}`, encodeURIComponent(String(resourceType)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the name of a scoped resource.
         * @summary Get scoped resource name.
         * @param {string} resourceType 
         * @param {string} id 
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopeName: async (resourceType: string, id: string, clusterId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('getScopeName', 'resourceType', resourceType)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScopeName', 'id', id)
            const localVarPath = `/internal/{resourceType}/{id}/name`
                .replace(`{${"resourceType"}}`, encodeURIComponent(String(resourceType)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the metric store for a tenant
         * @param {string} tenantId Unique identifier of the tenant.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalTenantsTenantIdMetricStoreGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('internalTenantsTenantIdMetricStoreGet', 'tenantId', tenantId)
            const localVarPath = `/internal/tenants/{tenantId}/metric-store`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalApi - functional programming interface
 * @export
 */
export const InternalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the ancestors resource of a scoped resource.
         * @summary Get scoped resource ancestors.
         * @param {string} resourceType 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAncestors(resourceType: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ancestors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAncestors(resourceType, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the name of a scoped resource.
         * @summary Get scoped resource name.
         * @param {string} resourceType 
         * @param {string} id 
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScopeName(resourceType: string, id: string, clusterId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScopeName200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScopeName(resourceType, id, clusterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the metric store for a tenant
         * @param {string} tenantId Unique identifier of the tenant.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalTenantsTenantIdMetricStoreGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalTenantsTenantIdMetricStoreGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalApi - factory interface
 * @export
 */
export const InternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalApiFp(configuration)
    return {
        /**
         * Get the ancestors resource of a scoped resource.
         * @summary Get scoped resource ancestors.
         * @param {string} resourceType 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAncestors(resourceType: string, id: string, options?: any): AxiosPromise<Ancestors> {
            return localVarFp.getAncestors(resourceType, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the name of a scoped resource.
         * @summary Get scoped resource name.
         * @param {string} resourceType 
         * @param {string} id 
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopeName(resourceType: string, id: string, clusterId?: string, options?: any): AxiosPromise<GetScopeName200Response> {
            return localVarFp.getScopeName(resourceType, id, clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the metric store for a tenant
         * @param {string} tenantId Unique identifier of the tenant.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalTenantsTenantIdMetricStoreGet(tenantId: string, options?: any): AxiosPromise<MetricStore> {
            return localVarFp.internalTenantsTenantIdMetricStoreGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalApi - object-oriented interface
 * @export
 * @class InternalApi
 * @extends {BaseAPI}
 */
export class InternalApi extends BaseAPI {
    /**
     * Get the ancestors resource of a scoped resource.
     * @summary Get scoped resource ancestors.
     * @param {string} resourceType 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getAncestors(resourceType: string, id: string, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).getAncestors(resourceType, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the name of a scoped resource.
     * @summary Get scoped resource name.
     * @param {string} resourceType 
     * @param {string} id 
     * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getScopeName(resourceType: string, id: string, clusterId?: string, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).getScopeName(resourceType, id, clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the metric store for a tenant
     * @param {string} tenantId Unique identifier of the tenant.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public internalTenantsTenantIdMetricStoreGet(tenantId: string, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).internalTenantsTenantIdMetricStoreGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NodePoolsApi - axios parameter creator
 * @export
 */
export const NodePoolsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Use to create a node pool in a cluster by Universally Unique Identifier (UUID).
         * @summary Create a Node Pool.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {NodePoolCreateRequest} [nodePoolCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNodePool: async (clusterId: string, nodePoolCreateRequest?: NodePoolCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('createNodePool', 'clusterId', clusterId)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/node-pools`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodePoolCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to delete a node pool by Universally Unique Identifier (UUID).
         * @summary Delete a Node Pool by id.§
         * @param {string} clusterId Unique identifier of the cluster
         * @param {number} id The unique id identifying the Node Pool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNodePool: async (clusterId: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('deleteNodePool', 'clusterId', clusterId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteNodePool', 'id', id)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/node-pools/{id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all the node pools with details from the cluster by Universally Unique Identifier (UUID).
         * @summary Get the cluster\'s Node Pools.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodePools: async (clusterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getNodePools', 'clusterId', clusterId)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/node-pools`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sync all Node Pools statuses from Kubernetes cluster to Run:AI backend. 
         * @summary Sync Node Pools.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {Array<NodePoolStatusRequest>} [nodePoolStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncNodePools: async (clusterId: string, nodePoolStatusRequest?: Array<NodePoolStatusRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('syncNodePools', 'clusterId', clusterId)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/node-pools/sync`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodePoolStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to update the details of a node pool by Universally Unique Identifier (UUID).
         * @summary Update a Node Pool.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {number} id The unique id identifying the Node Pool.
         * @param {UpdateNodePoolRequest} updateNodePoolRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNodePool: async (clusterId: string, id: number, updateNodePoolRequest: UpdateNodePoolRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('updateNodePool', 'clusterId', clusterId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNodePool', 'id', id)
            // verify required parameter 'updateNodePoolRequest' is not null or undefined
            assertParamExists('updateNodePool', 'updateNodePoolRequest', updateNodePoolRequest)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/node-pools/{id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNodePoolRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to update the labels of a node pool.
         * @summary Update labels of a Node Pool.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {number} id The unique id identifying the Node Pool.
         * @param {NodePoolLabelsRequest} nodePoolLabelsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNodePoolLabels: async (clusterId: string, id: number, nodePoolLabelsRequest: NodePoolLabelsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('updateNodePoolLabels', 'clusterId', clusterId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNodePoolLabels', 'id', id)
            // verify required parameter 'nodePoolLabelsRequest' is not null or undefined
            assertParamExists('updateNodePoolLabels', 'nodePoolLabelsRequest', nodePoolLabelsRequest)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/node-pools/{id}/labels`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodePoolLabelsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to update the status of a node pool by Universally Unique Identifier (UUID).
         * @summary Update the status of a Node Pool.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {string} name The unique name identifying the Node Pool.
         * @param {NodePoolStatusRequest} nodePoolStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNodePoolStatus: async (clusterId: string, name: string, nodePoolStatusRequest: NodePoolStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('updateNodePoolStatus', 'clusterId', clusterId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('updateNodePoolStatus', 'name', name)
            // verify required parameter 'nodePoolStatusRequest' is not null or undefined
            assertParamExists('updateNodePoolStatus', 'nodePoolStatusRequest', nodePoolStatusRequest)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/node-pools/status`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodePoolStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodePoolsApi - functional programming interface
 * @export
 */
export const NodePoolsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodePoolsApiAxiosParamCreator(configuration)
    return {
        /**
         * Use to create a node pool in a cluster by Universally Unique Identifier (UUID).
         * @summary Create a Node Pool.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {NodePoolCreateRequest} [nodePoolCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNodePool(clusterId: string, nodePoolCreateRequest?: NodePoolCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNodePool(clusterId, nodePoolCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to delete a node pool by Universally Unique Identifier (UUID).
         * @summary Delete a Node Pool by id.§
         * @param {string} clusterId Unique identifier of the cluster
         * @param {number} id The unique id identifying the Node Pool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNodePool(clusterId: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNodePool(clusterId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve all the node pools with details from the cluster by Universally Unique Identifier (UUID).
         * @summary Get the cluster\'s Node Pools.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodePools(clusterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NodePool>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodePools(clusterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sync all Node Pools statuses from Kubernetes cluster to Run:AI backend. 
         * @summary Sync Node Pools.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {Array<NodePoolStatusRequest>} [nodePoolStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncNodePools(clusterId: string, nodePoolStatusRequest?: Array<NodePoolStatusRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncNodePools(clusterId, nodePoolStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to update the details of a node pool by Universally Unique Identifier (UUID).
         * @summary Update a Node Pool.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {number} id The unique id identifying the Node Pool.
         * @param {UpdateNodePoolRequest} updateNodePoolRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNodePool(clusterId: string, id: number, updateNodePoolRequest: UpdateNodePoolRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNodePool(clusterId, id, updateNodePoolRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to update the labels of a node pool.
         * @summary Update labels of a Node Pool.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {number} id The unique id identifying the Node Pool.
         * @param {NodePoolLabelsRequest} nodePoolLabelsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNodePoolLabels(clusterId: string, id: number, nodePoolLabelsRequest: NodePoolLabelsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNodePoolLabels(clusterId, id, nodePoolLabelsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to update the status of a node pool by Universally Unique Identifier (UUID).
         * @summary Update the status of a Node Pool.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {string} name The unique name identifying the Node Pool.
         * @param {NodePoolStatusRequest} nodePoolStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNodePoolStatus(clusterId: string, name: string, nodePoolStatusRequest: NodePoolStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNodePoolStatus(clusterId, name, nodePoolStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NodePoolsApi - factory interface
 * @export
 */
export const NodePoolsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodePoolsApiFp(configuration)
    return {
        /**
         * Use to create a node pool in a cluster by Universally Unique Identifier (UUID).
         * @summary Create a Node Pool.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {NodePoolCreateRequest} [nodePoolCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNodePool(clusterId: string, nodePoolCreateRequest?: NodePoolCreateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createNodePool(clusterId, nodePoolCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to delete a node pool by Universally Unique Identifier (UUID).
         * @summary Delete a Node Pool by id.§
         * @param {string} clusterId Unique identifier of the cluster
         * @param {number} id The unique id identifying the Node Pool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNodePool(clusterId: string, id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNodePool(clusterId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all the node pools with details from the cluster by Universally Unique Identifier (UUID).
         * @summary Get the cluster\'s Node Pools.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodePools(clusterId: string, options?: any): AxiosPromise<Array<NodePool>> {
            return localVarFp.getNodePools(clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sync all Node Pools statuses from Kubernetes cluster to Run:AI backend. 
         * @summary Sync Node Pools.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {Array<NodePoolStatusRequest>} [nodePoolStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncNodePools(clusterId: string, nodePoolStatusRequest?: Array<NodePoolStatusRequest>, options?: any): AxiosPromise<void> {
            return localVarFp.syncNodePools(clusterId, nodePoolStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to update the details of a node pool by Universally Unique Identifier (UUID).
         * @summary Update a Node Pool.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {number} id The unique id identifying the Node Pool.
         * @param {UpdateNodePoolRequest} updateNodePoolRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNodePool(clusterId: string, id: number, updateNodePoolRequest: UpdateNodePoolRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateNodePool(clusterId, id, updateNodePoolRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to update the labels of a node pool.
         * @summary Update labels of a Node Pool.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {number} id The unique id identifying the Node Pool.
         * @param {NodePoolLabelsRequest} nodePoolLabelsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNodePoolLabels(clusterId: string, id: number, nodePoolLabelsRequest: NodePoolLabelsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateNodePoolLabels(clusterId, id, nodePoolLabelsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to update the status of a node pool by Universally Unique Identifier (UUID).
         * @summary Update the status of a Node Pool.
         * @param {string} clusterId Unique identifier of the cluster
         * @param {string} name The unique name identifying the Node Pool.
         * @param {NodePoolStatusRequest} nodePoolStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNodePoolStatus(clusterId: string, name: string, nodePoolStatusRequest: NodePoolStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateNodePoolStatus(clusterId, name, nodePoolStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodePoolsApi - object-oriented interface
 * @export
 * @class NodePoolsApi
 * @extends {BaseAPI}
 */
export class NodePoolsApi extends BaseAPI {
    /**
     * Use to create a node pool in a cluster by Universally Unique Identifier (UUID).
     * @summary Create a Node Pool.
     * @param {string} clusterId Unique identifier of the cluster
     * @param {NodePoolCreateRequest} [nodePoolCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public createNodePool(clusterId: string, nodePoolCreateRequest?: NodePoolCreateRequest, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).createNodePool(clusterId, nodePoolCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to delete a node pool by Universally Unique Identifier (UUID).
     * @summary Delete a Node Pool by id.§
     * @param {string} clusterId Unique identifier of the cluster
     * @param {number} id The unique id identifying the Node Pool.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public deleteNodePool(clusterId: string, id: number, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).deleteNodePool(clusterId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all the node pools with details from the cluster by Universally Unique Identifier (UUID).
     * @summary Get the cluster\'s Node Pools.
     * @param {string} clusterId Unique identifier of the cluster
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public getNodePools(clusterId: string, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).getNodePools(clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sync all Node Pools statuses from Kubernetes cluster to Run:AI backend. 
     * @summary Sync Node Pools.
     * @param {string} clusterId Unique identifier of the cluster
     * @param {Array<NodePoolStatusRequest>} [nodePoolStatusRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public syncNodePools(clusterId: string, nodePoolStatusRequest?: Array<NodePoolStatusRequest>, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).syncNodePools(clusterId, nodePoolStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to update the details of a node pool by Universally Unique Identifier (UUID).
     * @summary Update a Node Pool.
     * @param {string} clusterId Unique identifier of the cluster
     * @param {number} id The unique id identifying the Node Pool.
     * @param {UpdateNodePoolRequest} updateNodePoolRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public updateNodePool(clusterId: string, id: number, updateNodePoolRequest: UpdateNodePoolRequest, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).updateNodePool(clusterId, id, updateNodePoolRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to update the labels of a node pool.
     * @summary Update labels of a Node Pool.
     * @param {string} clusterId Unique identifier of the cluster
     * @param {number} id The unique id identifying the Node Pool.
     * @param {NodePoolLabelsRequest} nodePoolLabelsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public updateNodePoolLabels(clusterId: string, id: number, nodePoolLabelsRequest: NodePoolLabelsRequest, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).updateNodePoolLabels(clusterId, id, nodePoolLabelsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to update the status of a node pool by Universally Unique Identifier (UUID).
     * @summary Update the status of a Node Pool.
     * @param {string} clusterId Unique identifier of the cluster
     * @param {string} name The unique name identifying the Node Pool.
     * @param {NodePoolStatusRequest} nodePoolStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public updateNodePoolStatus(clusterId: string, name: string, nodePoolStatusRequest: NodePoolStatusRequest, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).updateNodePoolStatus(clusterId, name, nodePoolStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationalApi - axios parameter creator
 * @export
 */
export const OrganizationalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get tenant organizational tree
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgTree: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/k8s/org-tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationalApi - functional programming interface
 * @export
 */
export const OrganizationalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get tenant organizational tree
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgTree(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrgTree200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgTree(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationalApi - factory interface
 * @export
 */
export const OrganizationalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationalApiFp(configuration)
    return {
        /**
         * 
         * @summary Get tenant organizational tree
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgTree(options?: any): AxiosPromise<GetOrgTree200Response> {
            return localVarFp.getOrgTree(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationalApi - object-oriented interface
 * @export
 * @class OrganizationalApi
 * @extends {BaseAPI}
 */
export class OrganizationalApi extends BaseAPI {
    /**
     * 
     * @summary Get tenant organizational tree
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationalApi
     */
    public getOrgTree(options?: AxiosRequestConfig) {
        return OrganizationalApiFp(this.configuration).getOrgTree(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new project in a specific cluster. Deprecated - use `/api/v1/org-unit/projects` instead.
         * @summary Create a new project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {ProjectCreateRequest} projectCreateRequest 
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;permissions&#x60; field. If &#x60;true&#x60;, the &#x60;permissions&#x60; field in the request body is ignored. If &#x60;false&#x60;, relevant access rules for the &#x60;permissions&#x60; field are created in the project scope. 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createProject: async (clusterId: string, projectCreateRequest: ProjectCreateRequest, excludePermissions?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('createProject', 'clusterId', clusterId)
            // verify required parameter 'projectCreateRequest' is not null or undefined
            assertParamExists('createProject', 'projectCreateRequest', projectCreateRequest)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/projects`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (excludePermissions !== undefined) {
                localVarQueryParameter['excludePermissions'] = excludePermissions;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a project from a specific cluster. Deprecated - use `/api/v1/org-unit/projects/{projectId}` instead.
         * @summary Delete a project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} id The unique id of the project.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteProject: async (clusterId: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('deleteProject', 'clusterId', clusterId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProject', 'id', id)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/projects/{id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the details of a specific project from a specific cluster. Use for project analysis. **Requires `view` permissions to the queried project**. Deprecated - use `/api/v1/org-unit/projects/{projectId}` instead.
         * @summary List details of a specific project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} id The unique project-id identifying the project.
         * @param {boolean} [excludePermissions] Backward compatibility of the \&#39;permissions\&#39; field. If \&#39;true\&#39;, the \&#39;permissions\&#39; field in the returned projects is not set. If \&#39;false\&#39;, the \&#39;permissions\&#39; field is set in the returned projects.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProject: async (clusterId: string, id: string, excludePermissions?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getProject', 'clusterId', clusterId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProject', 'id', id)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/projects/{id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (excludePermissions !== undefined) {
                localVarQueryParameter['excludePermissions'] = excludePermissions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of all projects and details from a specific cluster. Use in reporting and analysis tools. Deprecated - use `/api/v1/org-unit/projects` instead.
         * @summary List all projects and their details.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {boolean} [excludePermissions] Backward compatibility of the \&#39;permissions\&#39; field. If \&#39;true\&#39;, the \&#39;permissions\&#39; field in the returned projects is not set. If \&#39;false\&#39;, the \&#39;permissions\&#39; field is set in the returned projects.
         * @param {boolean} [memoryUnitMb] Memory returned in MB. When set to &#x60;false&#x60; (default) memory will be returned in MiB.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjects: async (clusterId: string, excludePermissions?: boolean, memoryUnitMb?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getProjects', 'clusterId', clusterId)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/projects`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (excludePermissions !== undefined) {
                localVarQueryParameter['excludePermissions'] = excludePermissions;
            }

            if (memoryUnitMb !== undefined) {
                localVarQueryParameter['memoryUnitMb'] = memoryUnitMb;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a project\'s details in a specific cluster. For example, node pool resources, and others. Deprecated - use `/api/v1/org-unit/projects/{projectId}` instead.
         * @summary Update a project.
         * @param {string} id The unique project-id.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {ProjectUpdateRequest} projectUpdateRequest 
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;permissions&#x60; field. If &#x60;true&#x60;, the &#x60;permissions&#x60; field in the returned projects is not set. If &#x60;false&#x60;, the &#x60;permissions&#x60; field is set in the returned projects. 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateProject: async (id: string, clusterId: string, projectUpdateRequest: ProjectUpdateRequest, excludePermissions?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProject', 'id', id)
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('updateProject', 'clusterId', clusterId)
            // verify required parameter 'projectUpdateRequest' is not null or undefined
            assertParamExists('updateProject', 'projectUpdateRequest', projectUpdateRequest)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (excludePermissions !== undefined) {
                localVarQueryParameter['excludePermissions'] = excludePermissions;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Project status
         * @param {string} clusterId Unique identifier of the cluster
         * @param {ProjectStatusRequest} projectStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateProjectStatus: async (clusterId: string, projectStatusRequest: ProjectStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('updateProjectStatus', 'clusterId', clusterId)
            // verify required parameter 'projectStatusRequest' is not null or undefined
            assertParamExists('updateProjectStatus', 'projectStatusRequest', projectStatusRequest)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/projects/status`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new project in a specific cluster. Deprecated - use `/api/v1/org-unit/projects` instead.
         * @summary Create a new project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {ProjectCreateRequest} projectCreateRequest 
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;permissions&#x60; field. If &#x60;true&#x60;, the &#x60;permissions&#x60; field in the request body is ignored. If &#x60;false&#x60;, relevant access rules for the &#x60;permissions&#x60; field are created in the project scope. 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createProject(clusterId: string, projectCreateRequest: ProjectCreateRequest, excludePermissions?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(clusterId, projectCreateRequest, excludePermissions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a project from a specific cluster. Deprecated - use `/api/v1/org-unit/projects/{projectId}` instead.
         * @summary Delete a project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} id The unique id of the project.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteProject(clusterId: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(clusterId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the details of a specific project from a specific cluster. Use for project analysis. **Requires `view` permissions to the queried project**. Deprecated - use `/api/v1/org-unit/projects/{projectId}` instead.
         * @summary List details of a specific project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} id The unique project-id identifying the project.
         * @param {boolean} [excludePermissions] Backward compatibility of the \&#39;permissions\&#39; field. If \&#39;true\&#39;, the \&#39;permissions\&#39; field in the returned projects is not set. If \&#39;false\&#39;, the \&#39;permissions\&#39; field is set in the returned projects.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProject(clusterId: string, id: string, excludePermissions?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(clusterId, id, excludePermissions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a list of all projects and details from a specific cluster. Use in reporting and analysis tools. Deprecated - use `/api/v1/org-unit/projects` instead.
         * @summary List all projects and their details.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {boolean} [excludePermissions] Backward compatibility of the \&#39;permissions\&#39; field. If \&#39;true\&#39;, the \&#39;permissions\&#39; field in the returned projects is not set. If \&#39;false\&#39;, the \&#39;permissions\&#39; field is set in the returned projects.
         * @param {boolean} [memoryUnitMb] Memory returned in MB. When set to &#x60;false&#x60; (default) memory will be returned in MiB.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProjects(clusterId: string, excludePermissions?: boolean, memoryUnitMb?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(clusterId, excludePermissions, memoryUnitMb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a project\'s details in a specific cluster. For example, node pool resources, and others. Deprecated - use `/api/v1/org-unit/projects/{projectId}` instead.
         * @summary Update a project.
         * @param {string} id The unique project-id.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {ProjectUpdateRequest} projectUpdateRequest 
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;permissions&#x60; field. If &#x60;true&#x60;, the &#x60;permissions&#x60; field in the returned projects is not set. If &#x60;false&#x60;, the &#x60;permissions&#x60; field is set in the returned projects. 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateProject(id: string, clusterId: string, projectUpdateRequest: ProjectUpdateRequest, excludePermissions?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(id, clusterId, projectUpdateRequest, excludePermissions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Project status
         * @param {string} clusterId Unique identifier of the cluster
         * @param {ProjectStatusRequest} projectStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateProjectStatus(clusterId: string, projectStatusRequest: ProjectStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectStatus(clusterId, projectStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * Creates a new project in a specific cluster. Deprecated - use `/api/v1/org-unit/projects` instead.
         * @summary Create a new project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {ProjectCreateRequest} projectCreateRequest 
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;permissions&#x60; field. If &#x60;true&#x60;, the &#x60;permissions&#x60; field in the request body is ignored. If &#x60;false&#x60;, relevant access rules for the &#x60;permissions&#x60; field are created in the project scope. 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createProject(clusterId: string, projectCreateRequest: ProjectCreateRequest, excludePermissions?: boolean, options?: any): AxiosPromise<Project1> {
            return localVarFp.createProject(clusterId, projectCreateRequest, excludePermissions, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a project from a specific cluster. Deprecated - use `/api/v1/org-unit/projects/{projectId}` instead.
         * @summary Delete a project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} id The unique id of the project.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteProject(clusterId: string, id: number, options?: any): AxiosPromise<Project1> {
            return localVarFp.deleteProject(clusterId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the details of a specific project from a specific cluster. Use for project analysis. **Requires `view` permissions to the queried project**. Deprecated - use `/api/v1/org-unit/projects/{projectId}` instead.
         * @summary List details of a specific project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} id The unique project-id identifying the project.
         * @param {boolean} [excludePermissions] Backward compatibility of the \&#39;permissions\&#39; field. If \&#39;true\&#39;, the \&#39;permissions\&#39; field in the returned projects is not set. If \&#39;false\&#39;, the \&#39;permissions\&#39; field is set in the returned projects.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProject(clusterId: string, id: string, excludePermissions?: boolean, options?: any): AxiosPromise<Project1> {
            return localVarFp.getProject(clusterId, id, excludePermissions, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of all projects and details from a specific cluster. Use in reporting and analysis tools. Deprecated - use `/api/v1/org-unit/projects` instead.
         * @summary List all projects and their details.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {boolean} [excludePermissions] Backward compatibility of the \&#39;permissions\&#39; field. If \&#39;true\&#39;, the \&#39;permissions\&#39; field in the returned projects is not set. If \&#39;false\&#39;, the \&#39;permissions\&#39; field is set in the returned projects.
         * @param {boolean} [memoryUnitMb] Memory returned in MB. When set to &#x60;false&#x60; (default) memory will be returned in MiB.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjects(clusterId: string, excludePermissions?: boolean, memoryUnitMb?: boolean, options?: any): AxiosPromise<Array<Project1>> {
            return localVarFp.getProjects(clusterId, excludePermissions, memoryUnitMb, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a project\'s details in a specific cluster. For example, node pool resources, and others. Deprecated - use `/api/v1/org-unit/projects/{projectId}` instead.
         * @summary Update a project.
         * @param {string} id The unique project-id.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {ProjectUpdateRequest} projectUpdateRequest 
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;permissions&#x60; field. If &#x60;true&#x60;, the &#x60;permissions&#x60; field in the returned projects is not set. If &#x60;false&#x60;, the &#x60;permissions&#x60; field is set in the returned projects. 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateProject(id: string, clusterId: string, projectUpdateRequest: ProjectUpdateRequest, excludePermissions?: boolean, options?: any): AxiosPromise<Project1> {
            return localVarFp.updateProject(id, clusterId, projectUpdateRequest, excludePermissions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Project status
         * @param {string} clusterId Unique identifier of the cluster
         * @param {ProjectStatusRequest} projectStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateProjectStatus(clusterId: string, projectStatusRequest: ProjectStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateProjectStatus(clusterId, projectStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * Creates a new project in a specific cluster. Deprecated - use `/api/v1/org-unit/projects` instead.
     * @summary Create a new project.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {ProjectCreateRequest} projectCreateRequest 
     * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;permissions&#x60; field. If &#x60;true&#x60;, the &#x60;permissions&#x60; field in the request body is ignored. If &#x60;false&#x60;, relevant access rules for the &#x60;permissions&#x60; field are created in the project scope. 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createProject(clusterId: string, projectCreateRequest: ProjectCreateRequest, excludePermissions?: boolean, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createProject(clusterId, projectCreateRequest, excludePermissions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a project from a specific cluster. Deprecated - use `/api/v1/org-unit/projects/{projectId}` instead.
     * @summary Delete a project.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {number} id The unique id of the project.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteProject(clusterId: string, id: number, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).deleteProject(clusterId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the details of a specific project from a specific cluster. Use for project analysis. **Requires `view` permissions to the queried project**. Deprecated - use `/api/v1/org-unit/projects/{projectId}` instead.
     * @summary List details of a specific project.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} id The unique project-id identifying the project.
     * @param {boolean} [excludePermissions] Backward compatibility of the \&#39;permissions\&#39; field. If \&#39;true\&#39;, the \&#39;permissions\&#39; field in the returned projects is not set. If \&#39;false\&#39;, the \&#39;permissions\&#39; field is set in the returned projects.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProject(clusterId: string, id: string, excludePermissions?: boolean, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProject(clusterId, id, excludePermissions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of all projects and details from a specific cluster. Use in reporting and analysis tools. Deprecated - use `/api/v1/org-unit/projects` instead.
     * @summary List all projects and their details.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {boolean} [excludePermissions] Backward compatibility of the \&#39;permissions\&#39; field. If \&#39;true\&#39;, the \&#39;permissions\&#39; field in the returned projects is not set. If \&#39;false\&#39;, the \&#39;permissions\&#39; field is set in the returned projects.
     * @param {boolean} [memoryUnitMb] Memory returned in MB. When set to &#x60;false&#x60; (default) memory will be returned in MiB.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjects(clusterId: string, excludePermissions?: boolean, memoryUnitMb?: boolean, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjects(clusterId, excludePermissions, memoryUnitMb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a project\'s details in a specific cluster. For example, node pool resources, and others. Deprecated - use `/api/v1/org-unit/projects/{projectId}` instead.
     * @summary Update a project.
     * @param {string} id The unique project-id.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {ProjectUpdateRequest} projectUpdateRequest 
     * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;permissions&#x60; field. If &#x60;true&#x60;, the &#x60;permissions&#x60; field in the returned projects is not set. If &#x60;false&#x60;, the &#x60;permissions&#x60; field is set in the returned projects. 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateProject(id: string, clusterId: string, projectUpdateRequest: ProjectUpdateRequest, excludePermissions?: boolean, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateProject(id, clusterId, projectUpdateRequest, excludePermissions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Project status
     * @param {string} clusterId Unique identifier of the cluster
     * @param {ProjectStatusRequest} projectStatusRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateProjectStatus(clusterId: string, projectStatusRequest: ProjectStatusRequest, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateProjectStatus(clusterId, projectStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get tenant
         * @summary Get tenant
         * @param {string} [tenantName] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getTenant: async (tenantName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/k8s/tenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenantName !== undefined) {
                localVarQueryParameter['tenantName'] = tenantName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get tenant auth config, used for authentication.
         * @summary Get tenant auth config
         * @param {string} [tenantName] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getTenantConfig: async (tenantName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/k8s/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenantName !== undefined) {
                localVarQueryParameter['tenantName'] = tenantName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all tenant settings.
         * @summary Get all tenant settings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/k8s/setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a specific tenant setting by key.
         * @summary Get a tenant setting by key.
         * @param {string} settingKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantSettingsByKey: async (settingKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingKey' is not null or undefined
            assertParamExists('getTenantSettingsByKey', 'settingKey', settingKey)
            const localVarPath = `/v1/k8s/setting/{settingKey}`
                .replace(`{${"settingKey"}}`, encodeURIComponent(String(settingKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to update tenant settings.
         * @summary Update a tenant setting.
         * @param {TenantSettingCreationRequest} [tenantSettingCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenantSetting: async (tenantSettingCreationRequest?: TenantSettingCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/k8s/setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantSettingCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration)
    return {
        /**
         * Get tenant
         * @summary Get tenant
         * @param {string} [tenantName] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getTenant(tenantName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenant(tenantName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get tenant auth config, used for authentication.
         * @summary Get tenant auth config
         * @param {string} [tenantName] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getTenantConfig(tenantName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Config>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantConfig(tenantName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve all tenant settings.
         * @summary Get all tenant settings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Setting>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a specific tenant setting by key.
         * @summary Get a tenant setting by key.
         * @param {string} settingKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantSettingsByKey(settingKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantSettingsByKey(settingKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to update tenant settings.
         * @summary Update a tenant setting.
         * @param {TenantSettingCreationRequest} [tenantSettingCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTenantSetting(tenantSettingCreationRequest?: TenantSettingCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantSettingCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTenantSetting(tenantSettingCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantApiFp(configuration)
    return {
        /**
         * Get tenant
         * @summary Get tenant
         * @param {string} [tenantName] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getTenant(tenantName?: string, options?: any): AxiosPromise<Tenant> {
            return localVarFp.getTenant(tenantName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get tenant auth config, used for authentication.
         * @summary Get tenant auth config
         * @param {string} [tenantName] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getTenantConfig(tenantName?: string, options?: any): AxiosPromise<Config> {
            return localVarFp.getTenantConfig(tenantName, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all tenant settings.
         * @summary Get all tenant settings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantSettings(options?: any): AxiosPromise<Array<Setting>> {
            return localVarFp.getTenantSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a specific tenant setting by key.
         * @summary Get a tenant setting by key.
         * @param {string} settingKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantSettingsByKey(settingKey: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.getTenantSettingsByKey(settingKey, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to update tenant settings.
         * @summary Update a tenant setting.
         * @param {TenantSettingCreationRequest} [tenantSettingCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenantSetting(tenantSettingCreationRequest?: TenantSettingCreationRequest, options?: any): AxiosPromise<TenantSettingCreationResponse> {
            return localVarFp.updateTenantSetting(tenantSettingCreationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     * Get tenant
     * @summary Get tenant
     * @param {string} [tenantName] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenant(tenantName?: string, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getTenant(tenantName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get tenant auth config, used for authentication.
     * @summary Get tenant auth config
     * @param {string} [tenantName] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenantConfig(tenantName?: string, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getTenantConfig(tenantName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all tenant settings.
     * @summary Get all tenant settings.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenantSettings(options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getTenantSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a specific tenant setting by key.
     * @summary Get a tenant setting by key.
     * @param {string} settingKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenantSettingsByKey(settingKey: string, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getTenantSettingsByKey(settingKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to update tenant settings.
     * @summary Update a tenant setting.
     * @param {TenantSettingCreationRequest} [tenantSettingCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public updateTenantSetting(tenantSettingCreationRequest?: TenantSettingCreationRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).updateTenantSetting(tenantSettingCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WandbApi - axios parameter creator
 * @export
 */
export const WandbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a wandb sweep and return its id.
         * @summary Create a sweep.
         * @param {WandbSweepsRequest} wandbSweepsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSweep: async (wandbSweepsRequest: WandbSweepsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wandbSweepsRequest' is not null or undefined
            assertParamExists('createSweep', 'wandbSweepsRequest', wandbSweepsRequest)
            const localVarPath = `/v1/k8s/wandb/sweeps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wandbSweepsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WandbApi - functional programming interface
 * @export
 */
export const WandbApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WandbApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a wandb sweep and return its id.
         * @summary Create a sweep.
         * @param {WandbSweepsRequest} wandbSweepsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSweep(wandbSweepsRequest: WandbSweepsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WandbSweepsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSweep(wandbSweepsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WandbApi - factory interface
 * @export
 */
export const WandbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WandbApiFp(configuration)
    return {
        /**
         * Create a wandb sweep and return its id.
         * @summary Create a sweep.
         * @param {WandbSweepsRequest} wandbSweepsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSweep(wandbSweepsRequest: WandbSweepsRequest, options?: any): AxiosPromise<WandbSweepsResponse> {
            return localVarFp.createSweep(wandbSweepsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WandbApi - object-oriented interface
 * @export
 * @class WandbApi
 * @extends {BaseAPI}
 */
export class WandbApi extends BaseAPI {
    /**
     * Create a wandb sweep and return its id.
     * @summary Create a sweep.
     * @param {WandbSweepsRequest} wandbSweepsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WandbApi
     */
    public createSweep(wandbSweepsRequest: WandbSweepsRequest, options?: AxiosRequestConfig) {
        return WandbApiFp(this.configuration).createSweep(wandbSweepsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


