import jwt_decode from "jwt-decode";
import { defineStore } from "pinia";
import { authService } from "@/services/control-plane/auth.service/auth.service";
import { tenantService } from "@/services/control-plane/tenant.service/tenant.service";
import { useAppStore } from "@/stores/app.store";
import { useSettingStore } from "@/stores/setting.store";
import type { IUser } from "@/models/user.model";
import { ERoles } from "@/models/user.model";
import type { IAuthProviderData, ITenantConfigInfo } from "@/models/tenant.model";
import type { ScopeType, OrgUnit } from "@/swagger-models/backend-client";
import { EContractTypes } from "@/models/tenant.model";
import { tenantUtil } from "@/utils/tenant.util";
import { orgTreeService } from "@/services/control-plane/rbac/org-tree.service/org-tree.service";
import { useGrafanaStore } from "./grafana.store";
import { STORAGE_KEYS } from "@/models/storage.model";
import { AuthEntityType } from "@/swagger-models/backend-client";
import { ResourceType, Action } from "@/swagger-models/authorization-client";
import { storageUtil } from "@/utils/storage.util";
import type { IRefreshTokenResponse } from "@/models/auth.model";
import { dateUtil } from "@/utils/date.util";
import { usePermissionStore } from "./permissions.store";
import type { Tenant } from "@/swagger-models/tenants-manager-client";

import { EIntervalLabels } from "@/models/interval.model";
import { intervalUtil } from "@/utils/interval.util";
import { ACCESS_TOKEN, EXTERNAL_TOKEN, ID_TOKEN, REFRESH_TOKEN, USE_EXTERNAL_TOKEN } from "@/common/storage.constant";

import axios from "axios";
import { initClientApis } from "@/services/infra/client-apis/client-apis-init";
import { useIdpsStore } from "@/stores/idps.store";
import { grafanaService } from "@/services/control-plane/grafana.service/grafana.service";

const AUTH_PROVIDER_DISCOVERY_PATH = `.well-known/openid-configuration`;

function buildAuthorizationLoginUrl(
  authorizationEndpoint: string,
  authClientID: string,
  connection: string,
  redirect_uri: string,
): string {
  const searchParams = new URLSearchParams();
  searchParams.set("response_type", "code");
  searchParams.set("connection", connection);
  searchParams.set("client_id", authClientID);
  searchParams.set("redirect_uri", `${redirect_uri}/login/callback`);
  searchParams.set("scope", "openid email profile offline_access");

  return `${authorizationEndpoint || ""}?${searchParams.toString()}`;
}

export const useAuthStore = defineStore("Auth", {
  state: () => ({
    idToken: "" as string,
    accessToken: "" as string,
    currentUser: {} as IUser,
    tenant: {} as Tenant,
    logo: "" as string,
    lastLogin: "" as string,
    externalToken: "" as string,
    orgUnits: [] as Array<OrgUnit>,
    refreshToken: "" as string,
    authProviderConfig: {} as object,
    idpStore: useIdpsStore(),
    appStore: useAppStore(),
    settingStore: useSettingStore(),
  }),
  getters: {
    orgUnitList(): Array<OrgUnit> {
      return this.orgUnits;
    },
    isAdmin(): boolean {
      const permissionStore = usePermissionStore();
      return (
        this.currentUser?.roles?.some((role) => role === ERoles.ADMIN) ||
        permissionStore.hasPermission(ResourceType.Tenant, Action.Create)
      );
    },
    userLastLogin(): string {
      return this.lastLogin;
    },
    roles(): Array<string> {
      return this.currentUser?.roles || [];
    },
    getUID(): number | null {
      if (!this.accessToken) return null;
      const { uid }: { uid: number } = jwt_decode(this.accessToken);
      if (Number.isInteger(uid)) return uid;
      return uid ? Number(uid) : null;
    },
    getGID(): number | null {
      if (!this.accessToken) return null;
      const { gid }: { gid: number } = jwt_decode(this.accessToken);
      if (Number.isInteger(gid)) return gid;
      return gid ? Number(gid) : null;
    },
    getSupplementaryGroups(): string | null {
      if (!this.accessToken) return null;
      const { supplementarygroups }: { supplementarygroups: string } = jwt_decode(this.accessToken);
      return supplementarygroups || null;
    },
    isSSO(): boolean {
      return this.settingStore.isSSO || this.idpStore.idps.length > 0;
    },
    isCurrentUserSSO(): boolean {
      return this.currentUser.entityType === AuthEntityType.SsoUser;
    },
    isTrial(): boolean {
      return this.tenant.contractType === EContractTypes.Trail;
    },
    userEmail(): string {
      return this.currentUser?.email || "";
    },
    userIsRunAiEmp(): boolean {
      const email = this.currentUser.email || "";
      return email.split("@")[1] === "run.ai";
    },
    signedEula(): boolean {
      return !!this.tenant.eula;
    },
    needEulaSigned(): boolean {
      return !this.userIsRunAiEmp && this.isAdmin && !this.signedEula;
    },
    eulaNotSignedAndUserCannotSign(): boolean {
      return !!this.currentUser.email && !this.isAdmin && !this.userIsRunAiEmp && !this.signedEula;
    },
  },
  actions: {
    async loadUserOrgUnits(): Promise<Array<OrgUnit>> {
      this.orgUnits = await orgTreeService.getOrgTreeUnits();
      return this.orgUnits;
    },
    getOrgUnitByScopeAndId(scope: ScopeType, scopeId: string): OrgUnit | undefined {
      return this.orgUnits.find((ou: OrgUnit) => ou.type === scope && ou.id === scopeId);
    },
    loadTokenFromLocalStorage(): void {
      this.idToken = storageUtil.get<string | null>(ID_TOKEN) || "";
      this.accessToken = storageUtil.get<string | null>(ACCESS_TOKEN) || "";
      this.refreshToken = storageUtil.get<string | null>(REFRESH_TOKEN) || "";
      this.externalToken = storageUtil.get<string | null>(EXTERNAL_TOKEN) || "";
    },
    async loadUserInfo(): Promise<void> {
      this.currentUser = await authService.getCurrentUser();
      const { email, identity_provider }: { email: string; identity_provider: string } = jwt_decode(this.accessToken);
      this.currentUser.email = email;
      this.currentUser.identityProvider = identity_provider;
      this.setLocalStorageLoggedInUser();
    },
    setLocalStorageLoggedInUser(): void {
      const loggedInUserEmail: string | null = localStorage.getItem(STORAGE_KEYS.LOGGED_IN_USER_EMAIL);
      if (loggedInUserEmail !== this.currentUser.name) {
        localStorage.setItem(STORAGE_KEYS.IS_FIRST_LOGIN, "true");
        localStorage.setItem(STORAGE_KEYS.LOGGED_IN_USER_EMAIL, this.currentUser.name);
      } else {
        localStorage.removeItem(STORAGE_KEYS.IS_FIRST_LOGIN);
      }
    },
    async loadTenant(): Promise<Tenant> {
      const tenants = await tenantService.get();
      this.tenant = tenants[0];
      try {
        const res = await tenantService.getTenantLogo();
        this.logo = res.logo;
      } catch (error: unknown) {
        console.info("Couldn't get tenant logo...", error);
      }
      if (usePermissionStore().hasPermission(ResourceType.DashboardsOverview, Action.Read)) {
        const grafanaStore = useGrafanaStore();
        this.tenant.smg && grafanaStore.smgLogin();
        grafanaStore.getGrafanaDashboards();
      }
      return this.tenant;
    },
    async loadTenantConfigInfo(): Promise<ITenantConfigInfo> {
      const authData = await this.loadTenantAuthProviderData();
      return {
        clientConfigInfo: tenantUtil.getClientConfigInfo(authData, this.appStore.config.tenantName, this.isSSO),
        serverConfigInfo: tenantUtil.getServerConfigInfo(authData, this.isSSO),
      };
    },
    async loadTenantAuthProviderData(): Promise<IAuthProviderData> {
      return tenantService.getTenantAuthProviderData(this.tenant.name) as Promise<IAuthProviderData>;
    },
    login(): void {
      const loginUrl: string = buildAuthorizationLoginUrl(
        // @ts-ignore
        this.authProviderConfig["authorization_endpoint"],
        this.appStore.config.authClientID,
        this.appStore.config.tenantName,
        window.location.origin,
      );

      window.location.replace(loginUrl);
    },
    async logout(): Promise<void> {
      intervalUtil.stopInterval(EIntervalLabels.RefreshToken);
      storageUtil.remove(STORAGE_KEYS.ADMIN_MESSAGE_ID);
      const settingStore = useSettingStore();
      const searchParams = new URLSearchParams();
      searchParams.set("client_id", this.appStore.config.authClientID);
      searchParams.set("post_logout_redirect_uri", settingStore.logoutURI || window.location.origin);
      if (this.idToken) {
        searchParams.set("id_token_hint", this.idToken);
      }
      // @ts-ignore
      const signOutUrl = `${this.authProviderConfig["end_session_endpoint"]}?${searchParams.toString()}`;
      this.idToken = "";
      this.accessToken = "";
      this.externalToken = "";
      this.refreshToken = "";
      storageUtil.remove(ID_TOKEN);
      storageUtil.remove(ACCESS_TOKEN);
      storageUtil.remove(EXTERNAL_TOKEN);
      storageUtil.remove(REFRESH_TOKEN);
      storageUtil.remove(USE_EXTERNAL_TOKEN);
      try {
        await grafanaService.grafanaLogout();
      } catch (error: unknown) {
        console.warn("Failed to logout from Grafana: ", error);
      }
      window.location.replace(signOutUrl);
    },
    async loadAuthProviderConfig(authURL: string): Promise<void> {
      const url = `${authURL}/${AUTH_PROVIDER_DISCOVERY_PATH}`;
      try {
        const response = await axios.get(url);
        this.authProviderConfig = response.data;
      } catch (error: unknown) {
        console.error("Failed to load auth provider data: ", error);
        console.log("re-trying to load auth provider data...");
        const response = await axios.get(url);
        this.authProviderConfig = response.data;
      }
    },
    async postLogin(): Promise<void> {
      const searchParams = new URLSearchParams(window.location.search);
      const code: string | null = searchParams.get("code");
      const redirectUri = window.location.origin + window.location.pathname;
      if (!code) return;
      const tokens: IRefreshTokenResponse = await authService.exchangeCode(code, redirectUri);
      this.setTokens(tokens);
    },
    setTokens(tokens: IRefreshTokenResponse): void {
      this.idToken = tokens.idToken || "";
      this.accessToken = tokens.accessToken || "";
      this.externalToken = tokens.externalToken || "";
      this.refreshToken = tokens.refreshToken || "";
      storageUtil.save(ID_TOKEN, this.idToken);
      storageUtil.save(ACCESS_TOKEN, this.accessToken);
      storageUtil.save(EXTERNAL_TOKEN, this.externalToken);
      storageUtil.save(REFRESH_TOKEN, this.refreshToken);
    },
    async updateAccessToken(): Promise<void> {
      const tokens: IRefreshTokenResponse = await authService.refreshToken(this.refreshToken);
      this.setTokens(tokens);
    },
    async signEULA(): Promise<void> {
      await tenantService.signEULA(this.currentUser.email);
      await this.loadTenant();
    },
    updateUserLastLoggedIn(): void {
      this.lastLogin = dateUtil.dateFormat(new Date(), "MM/dd/yyyy, HH:mm");
    },
    refreshUserTokenInterval(): void {
      // We want to refresh the token X seconds before it expires to prevent 401 errors and unexpected behavior
      intervalUtil.stopInterval(EIntervalLabels.RefreshToken);
      intervalUtil.startInterval(EIntervalLabels.RefreshToken, async () => {
        if (this.accessToken) {
          if (authService.isJwtAboutToExpired(this.accessToken)) {
            const tokens: IRefreshTokenResponse = await authService.refreshToken(this.refreshToken);
            this.setTokens(tokens);
            initClientApis(this.accessToken);
          }
        }
      });
    },
  },
});
