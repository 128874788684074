<template>
  <q-table
    class="runai-table"
    :rows="rows"
    :columns="columns"
    :visible-columns="filterBy.displayedColumns"
    :pagination="filterBy"
    :filter="searchTerm"
    @update:pagination="updateFilters"
    :selection="disableSelection ? 'none' : selection"
    :selected="selected"
    :row-key="getRowKey"
    :class="{ 'sticky-column-table': stickyColumns, 'sticky-header': stickyHeader }"
    :style="style"
    :binary-state-sort="true"
    :hide-selected-banner="true"
    table-class="runai-table"
    rows-per-page-label="Rows per page"
    :loading="loading"
    :hide-bottom="hidePagination"
    :flat="noShadow"
    :bordered="bordered"
    square
    @request="fetchPartialPagedData"
    :rows-per-page-options="rowsPerPageOptions"
    @update:selected="headerSelectionToggle"
  >
    <template v-slot:top-row="{ cols }">
      <runai-table-row
        v-if="topRow"
        :columns="cols"
        :row="topRow"
        :name-column-icon="getRowIcon(topRow)"
        :is-selected="isTopRowSelected"
        :is-selectable="!disableSelection"
        @checkbox-clicked="
          isSingleSelect
            ? onSelect(getRowKey(topRow), topRow, 0)
            : onMultipleSelect($event, { key: getRowKey(topRow), row: topRow, rowIndex: 0, selected: isTopRowSelected })
        "
        @row-clicked="onRowClicked($event, topRow, 0)"
        @custom-cell-event="$emit($event.emitName, topRow)"
      />
    </template>

    <template v-slot:body="props">
      <runai-table-row
        :columns="props.cols"
        :row="props.row"
        :name-column-icon="getRowIcon(props.row)"
        :is-selectable="!disableSelection"
        @custom-cell-event="$emit($event.emitName, props.row)"
        @checkbox-clicked="
          isSingleSelect ? onSelect(props.key, props.row, props.rowIndex) : onMultipleSelect($event, props)
        "
        @row-clicked="onRowClicked($event, props.row, props.rowIndex)"
        :is-selected="props.selected"
      />
    </template>

    <template v-slot:no-data>
      <slot v-if="!loading && !topRow" name="no-data"></slot>
    </template>
  </q-table>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// Components
import { RunaiTableRow } from "@/components/common/runai-table/runai-table-row";

// Models
import type { IFilterBy } from "@/models/filter.model";
import type { ITableColumn } from "@/models/table.model";

// Utils
import { isEqual } from "@/utils/common.util";

type RowProps = {
  row: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  key: string | number;
  rowIndex: number;
  selected: boolean;
};

export default defineComponent({
  components: {
    RunaiTableRow,
  },
  emits: ["update:selected", "update-filters"],
  props: {
    rows: {
      type: Array as PropType<any[]>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: true,
    },
    columns: {
      type: Array as PropType<ITableColumn[]>,
      required: true,
    },
    filterBy: {
      type: Object as PropType<IFilterBy>,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    selected: {
      type: Array as PropType<any[]>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: false,
      default: () => [],
    },
    disableSelection: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    topRow: {
      type: [Object, null] as PropType<any | null>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: false,
      default: null,
    },
    getRowKey: {
      type: Function as PropType<(row: any) => string | number>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: false,
      default: () => "",
    },
    getRowIcon: {
      type: Function as PropType<(row: any) => string>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: false,
      default: () => "",
    },
    stickyColumns: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    hidePagination: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    noShadow: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    modalView: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    stickyHeader: {
      // When using sticky header, height/max-height must be provided as well in the style prop
      type: Boolean as PropType<boolean>,
      default: false,
    },
    style: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
    bordered: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    isServerSidePagination: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    rowsPerPageOptions: {
      type: Array as PropType<number[]>,
      default: () => [0, 5, 7, 10, 15, 20, 25, 50],
    },
    selection: {
      type: String as PropType<"none" | "single" | "multiple">,
      default: "single",
    },
  },
  data() {
    return {
      lastSelectedIndex: null as null | number,
    };
  },
  computed: {
    searchTerm(): string | undefined {
      return this.modalView ? this.filterBy.searchTerm : undefined;
    },
    isTopRowSelected(): boolean {
      const topRowKey = this.getRowKey(this.topRow);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return !!this.selected?.find((currRow: any) => this.getRowKey(currRow) === topRowKey);
    },
    isSingleSelect(): boolean {
      return this.selection === "single";
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    headerSelectionToggle(selectedRows: readonly any[]): void {
      this.$emit("update:selected", selectedRows);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onRowClicked(event: MouseEvent, row: any, rowIndex: number): void {
      if (this.isSingleSelect || (!event.shiftKey && !event.metaKey && !event.ctrlKey)) {
        // Click on the row itself without any modifier keys acts as regular single selection.
        this.onSelect(this.getRowKey(row), row, rowIndex);
      } else {
        // Behave as regular multiple selection
        const isRowSelected = this.selected.find((currRow) => this.getRowKey(currRow) === this.getRowKey(row));
        this.onMultipleSelect(
          { shiftKey: event.shiftKey, metaKey: event.metaKey, ctrlKey: event.ctrlKey },
          { key: this.getRowKey(row), row, rowIndex: rowIndex, selected: isRowSelected },
        );
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSelect(key: any, row: any, rowIndex: number): void {
      if (this.selected.find((currRow) => this.getRowKey(currRow) === key)) {
        this.lastSelectedIndex = null;
        this.$emit("update:selected", []);
      } else {
        this.lastSelectedIndex = rowIndex;
        this.$emit("update:selected", [row]);
      }
    },
    onMultipleSelect(event: { shiftKey: boolean; metaKey: boolean; ctrlKey: boolean }, props: RowProps) {
      // If shift is not pressed, select/unselect the row
      if (!event.shiftKey) {
        if (!props.selected) {
          this.$emit("update:selected", [...this.selected, props.row]);
          this.lastSelectedIndex = props.rowIndex;
        } else {
          this.$emit(
            "update:selected",
            this.selected.filter((currRow) => this.getRowKey(currRow) !== props.key),
          );
          this.lastSelectedIndex = null;
        }
      } else {
        if (this.lastSelectedIndex === null) {
          // if the row isn't selected and there is no last selected row, select it
          if (!props.selected) {
            this.lastSelectedIndex = props.rowIndex;
            this.$emit("update:selected", [...this.selected, props.row]);
            return;
          }
        }

        // if the row is selected, unselect it
        if (props.selected) {
          this.lastSelectedIndex = null;
          this.$emit(
            "update:selected",
            this.selected.filter((currRow) => this.getRowKey(currRow) !== props.key),
          );
        }

        // if the row isn't selected, select it and all rows between the last selected row and this row
        this.handleRangeSelection(props.selected, props.rowIndex);
      }
    },
    updateFilters(newFilterBy: IFilterBy): void {
      if (isEqual(this.filterBy, newFilterBy)) return;
      if (this.isServerSidePagination) {
        this.fetchPartialPagedData(newFilterBy as { pagination: IFilterBy });
      } else {
        this.$emit("update-filters", { ...this.filterBy, ...newFilterBy });
      }
    },
    fetchPartialPagedData(props: { pagination: IFilterBy }) {
      this.$emit("update-filters", props.pagination);
    },
    handleRangeSelection(isSelected: boolean, rowIndex: number) {
      if (this.lastSelectedIndex === null) return;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const rowsToAdd: Array<any> = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const rowKeysToRemove: Array<any> = [];

      const start = Math.min(this.lastSelectedIndex, rowIndex);
      const end = Math.max(this.lastSelectedIndex, rowIndex);
      for (let i = start; i <= end; i++) {
        if (isSelected) {
          rowKeysToRemove.push(this.getRowKey(this.rows[i]));
        } else {
          if (!this.selected.find((currRow) => this.getRowKey(currRow) === this.getRowKey(this.rows[i]))) {
            rowsToAdd.push(this.rows[i]);
          }
        }
      }

      if (isSelected) {
        this.$emit(
          "update:selected",
          this.selected.filter((currRow) => !rowKeysToRemove.includes(this.getRowKey(currRow))),
        );
      } else {
        this.$emit("update:selected", [...this.selected, ...rowsToAdd]);
      }
    },
  },
});
</script>
<style lang="scss" scoped></style>
