<template>
  <section class="environment-variable-box q-pa-md row items-center">
    <q-input
      aid="environment-variable-name"
      class="col-3 q-mr-md"
      label="Name"
      stack-label
      placeholder="Enter a name"
      :model-value="environmentVariable.name"
      @update:model-value="updateEnvironmentVariableName"
      :rules="[isValidName]"
      no-error-icon
      :disable="readOnly"
    />
    <runai-select
      aid="environment-variable-source"
      class="col-2 q-mr-md"
      :options="sourceOptions"
      label="Source"
      emit-value
      stack-label
      unclearable
      :model-value="selectedSource"
      @update:model-value="updateSelectedSource"
      :rules="[() => true]"
      :disable="readOnly"
    />
    <q-input
      v-if="selectedSource === 'custom'"
      aid="environment-variable-value"
      class="col-3"
      stack-label
      placeholder="Enter a value"
      label="Value"
      :rules="[isValidValue]"
      :model-value="environmentVariable.value"
      @update:model-value="updateEnvironmentVariableValue"
      no-error-icon
      :disable="readOnly"
    />
    <template v-else>
      <runai-select
        aid="environment-variable-credential"
        :style="{ width: '150px' }"
        class="q-mr-md"
        label="Credentials name"
        placeholder="Select..."
        stack-label
        emit-value
        :options="credentials"
        :model-value="environmentVariable.credential?.assetId || null"
        @update:model-value="updateEnvironmentVariableCredentialId"
        :rules="[isValidCredential]"
        no-option-text="No credentials found"
        :disable="readOnly"
      />
      <q-input
        aid="environment-variable-key"
        :style="{ width: '150px' }"
        stack-label
        placeholder="Enter a key"
        label="Secret key"
        :model-value="environmentVariable.credential?.key"
        @update:model-value="updateEnvironmentVariableKey"
        no-error-icon
        :rules="[isValidKey]"
        :disable="readOnly"
      />
    </template>

    <q-space />
    <q-btn
      aid="remove-environment-variable-button"
      class="close-button"
      icon="fa-regular fa-xmark"
      flat
      round
      @click="removeEnvironmentVariable"
      :disable="readOnly"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
// Components
import { RunaiSelect } from "@/components/common/runai-select";
// Models
import { ISelectOption } from "@/models/global.model";
import { EnvironmentVariable } from "@/swagger-models/assets-service-client";
// Utils
import { errorMessages } from "@/common/error-message.constant";
import { isNotEmpty } from "@/common/form.validators";

enum ESourceType {
  Custom = "custom",
  Credentials = "credentials",
}

export default defineComponent({
  name: "environment-variable-box",
  components: {
    RunaiSelect,
  },
  emits: ["update-environment-variable", "remove-environment-variable"],
  props: {
    environmentVariable: {
      type: Object as PropType<EnvironmentVariable>,
      required: true,
    },
    credentials: {
      type: Array as PropType<ISelectOption[]>,
      required: true,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      selectedSource: ESourceType.Custom as ESourceType,
    };
  },
  created() {
    this.selectedSource = this.environmentVariable.credential ? ESourceType.Credentials : ESourceType.Custom;
  },
  computed: {
    sourceOptions(): ISelectOption[] {
      return [
        {
          value: ESourceType.Custom,
          label: "Custom",
          toolTip: "Enter a name and value for the environment variable",
        },
        {
          value: ESourceType.Credentials,
          label: "Credentials",
          toolTip: "Select an existing credentials as the environment variable",
        },
      ];
    },
  },
  methods: {
    updateEnvironmentVariableName(name: string | number | null): void {
      this.$emit("update-environment-variable", {
        ...this.environmentVariable,
        name,
      });
    },
    updateSelectedSource(selectedSource: ESourceType): void {
      this.selectedSource = selectedSource;
      if (selectedSource === ESourceType.Custom) {
        this.$emit("update-environment-variable", {
          ...this.environmentVariable,
          credential: undefined,
        });
      } else {
        this.$emit("update-environment-variable", {
          ...this.environmentVariable,
          value: undefined,
          credential: {
            assetId: null,
            key: "",
          },
        });
      }
    },
    updateEnvironmentVariableValue(value: string | number | null): void {
      this.$emit("update-environment-variable", {
        ...this.environmentVariable,
        value,
      });
    },
    updateEnvironmentVariableCredentialId(assetId: string): void {
      this.$emit("update-environment-variable", {
        ...this.environmentVariable,
        credential: {
          ...this.environmentVariable.credential,
          assetId,
        },
      });
    },
    updateEnvironmentVariableKey(key: string | number | null): void {
      this.$emit("update-environment-variable", {
        ...this.environmentVariable,
        credential: {
          ...this.environmentVariable.credential,
          key,
        },
      });
    },
    removeEnvironmentVariable(): void {
      this.$emit("remove-environment-variable", null);
    },
    // Rules
    isValidName(value: string): boolean | string {
      return isNotEmpty(value) || errorMessages.NAME_NOT_EMPTY;
    },
    isValidValue(value: string): boolean | string {
      return isNotEmpty(value) || errorMessages.VALUE_NOT_EMPTY;
    },
    isValidKey(value: string): boolean | string {
      return isNotEmpty(value) || errorMessages.KEY_NOT_EMPTY;
    },
    isValidCredential(value: string): boolean | string {
      return isNotEmpty(value) || errorMessages.SELECT_A_NAME;
    },
  },
});
</script>

<style lang="scss" scoped>
.environment-variable-box {
  background-color: $body-background-color;

  .close-button {
    color: $black-54;
    font-size: 12px;
  }
}
</style>
