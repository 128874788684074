<template>
  <section class="tolerations-section">
    <div>Set toleration(s) to let the workload be scheduled on a node with a matching taint</div>
    <toleration-box
      v-for="(toleration, idx) in tolerations"
      :key="idx"
      :toleration="toleration"
      @remove-toleration="removeToleration(idx)"
      @update-toleration="updateToleration(idx, $event)"
      :read-only="readOnly"
    />
    <q-btn
      aid="add-toleration-btn"
      flat
      label="+ toleration"
      color="primary"
      class="q-mt-sm"
      @click="addToleration"
      :disable="readOnly"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
// Components
import { TolerationBox } from "./toleration-box";
// Models
import type { Toleration } from "@/swagger-models/assets-service-client";

export default defineComponent({
  name: "tolerations-section",
  components: {
    TolerationBox,
  },
  emits: ["update-tolerations"],
  props: {
    tolerations: {
      type: Array as PropType<Array<Toleration>>,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getDefaultToleration(): Toleration {
      return {
        key: "",
        operator: "Exists",
        value: null,
        effect: "NoSchedule",
      };
    },
    addToleration(): void {
      this.$emit("update-tolerations", [...this.tolerations, { ...this.getDefaultToleration() }]);
    },
    removeToleration(idx: number): void {
      this.$emit(
        "update-tolerations",
        this.tolerations.filter((_, i) => i !== idx),
      );
    },
    updateToleration(idx: number, toleration: Toleration): void {
      this.$emit(
        "update-tolerations",
        this.tolerations.map((t, i) => (i === idx ? toleration : t)),
      );
    },
  },
});
</script>
