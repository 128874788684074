<template>
  <section class="runai-page-actions" ref="actions">
    <Transition name="flicker">
      <div class="selected-rows-actions q-px-md" v-if="showSelectedRowsActions && !disableActions">
        <span class="title">{{ selectedRowsActionsLabel }}</span>
        <q-separator :vertical="true" color="white" class="seperator q-my-md q-mx-md" />
        <div class="actions-container">
          <slot name="selected-rows-actions" />
        </div>
        <runai-action-button
          v-if="showDetailsBtn"
          :btn-action="isDrawerOpen ? 'hideDetails' : 'showDetails'"
          @click="toggleDetailsDrawer"
        />
        <q-btn
          flat
          round
          color="white"
          size="12px"
          icon="fa-regular fa-xmark"
          @click="$emit('selected-actions-close')"
          aid="close-actions-btn"
        />
      </div>
    </Transition>

    <Transition name="slide-to-top">
      <div class="main-actions" v-if="!showSelectedRowsActions || disableActions">
        <div class="left-actions">
          <template v-if="showPrimaryBtn">
            <slot name="primary-btn">
              <div>
                <q-btn
                  v-permission="{ resourceType, action: Action.Create }"
                  color="primary"
                  class="btn-icon-sm primary-cta-btn"
                  :icon="primaryBtnIcon"
                  aid="primary-cta-btn"
                  :label="primaryBtnLabel"
                  @click="$emit('create-entity')"
                  :disable="primaryBtnDisable"
                >
                </q-btn>
                <q-tooltip v-if="primaryBtnDisable && primaryToolTipRows">
                  <div v-for="row in primaryToolTipRows" :key="row">
                    {{ row }}
                  </div>
                </q-tooltip>
              </div>
            </slot>
          </template>
        </div>
        <runai-page-filter
          v-if="!advancedFilters"
          :initial-filters-model="columnFilters"
          @update-column-filters="updateFilterBy('columnFilters', $event)"
          :filter-options="filterOptions"
          :filter-input-label="filterInputLabel"
          :cluster-filter="clusterFilter"
          :hide-all-cluster-filter-option="hideAllClusterFilterOption"
        ></runai-page-filter>
        <runai-advanced-filters
          v-else
          @update-filter-by="updateAdvancedFilters($event)"
          :filter-options="advancedFilterOptions"
          :initial-filters-model="advancedColumnFilters"
          :hide-all-cluster-filter-option="hideAllClusterFilterOption"
          :filter-by="filters"
          :hide-cluster-filter="hideClusterFilter"
        />
        <div class="right-actions-container">
          <runai-general-search
            v-if="showSearch"
            :search-term="searchTerm"
            @update-search-term="updateFilterBy('searchTerm', $event)"
          ></runai-general-search>
          <q-btn
            size="sm"
            class="q-mx-sm"
            aid="run-page-action-select-columns-btn"
            flat
            icon="fa-regular fa-columns-3"
            label="Columns"
            stack
          >
            <q-menu transition-show="scale" transition-hide="scale" @before-hide="beforeClose" max-width="1000px">
              <runai-checkbox-list
                :options="allColumnOptions"
                :selected="selectedColumns"
                @changed="updateSelectedColumns"
              />
            </q-menu>
          </q-btn>
          <runai-actions-menu
            class="q-mx-sm"
            v-if="filteredActionMenuItems?.length"
            :action-items="filteredActionMenuItems"
          />
          <q-btn
            v-if="supportRefresh"
            size="sm"
            class="q-mx-sm refresh-data"
            flat
            icon="fa-regular fa-rotate-right"
            aid="refresh-data-button"
            label="refresh"
            stack
            :disable="refreshingData"
            @click="refreshData"
            :key="Number(refreshingData)"
          >
            <q-tooltip v-if="refreshingData">Data is up to date</q-tooltip>
          </q-btn>
          <q-btn
            v-if="showDetailsBtn"
            size="sm"
            class="q-mx-sm show-details-btn"
            flat
            icon="fa-regular fa-memo-circle-info"
            aid="show-details-button"
            :label="`${isDrawerOpen ? 'hide' : 'show'} details`"
            stack
            @click="toggleDetailsDrawer"
          />
        </div>
      </div>
    </Transition>
    <runai-side-drawer
      @open="isDrawerOpen = true"
      @close="isDrawerOpen = false"
      :is-open="isDrawerOpen"
      v-if="showDetailsBtn"
      :is-empty="!selectedRowsAmount"
      :empty-message="emptyMessage"
      :height-from-top="heightFromTop"
      @resize="onResize"
    >
      <template v-slot:drawer-content>
        <slot name="drawer-content" />
      </template>
    </runai-side-drawer>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

// models
import type { ICheckboxItem } from "@/components/common/runai-checkbox-list/model";
import type {
  IFilterModel,
  IFilterOption,
  IFilterBy,
  IAdvancedFilterOption,
  IAdvancedFilterModel,
} from "@/models/filter.model";
import type { ITableColumn } from "@/models/table.model";
import type { ActionMenuItem } from "@/components/common/runai-page-actions/model";
import { type ResourceType, Action } from "@/swagger-models/authorization-client";

// components
import { RunaiCheckboxList } from "@/components/common/runai-checkbox-list";
import { RunaiPageFilter } from "@/components/common/runai-page-filter";
import { RunaiGeneralSearch } from "@/components/common/runai-general-search";
import { RunaiActionButton } from "./runai-action-button";
import { RunaiSideDrawer } from "@/components/common/runai-side-drawer";

// stores
import { useClusterStore } from "@/stores/cluster.store";

// services
import { filterService } from "@/services/filter.service/filter.service";
import { RunaiActionsMenu } from "@/components/common/runai-page-actions/runai-actions-menu/";
import { RunaiAdvancedFilters } from "@/components/common/runai-advanced-filters";

const CLUSTER_COLUMN_FILTER_NAME = "cluster";

export default defineComponent({
  components: {
    RunaiAdvancedFilters,
    RunaiActionsMenu,
    RunaiCheckboxList,
    RunaiPageFilter,
    RunaiGeneralSearch,
    RunaiSideDrawer,
    RunaiActionButton,
  },
  emits: [
    "columns-changed",
    "selected-actions-close",
    "filters-changed",
    "create-entity",
    "show-details",
    "drawer-resized",
    "drawer-toggled",
    "export-csv",
    "refresh-data",
  ],
  props: {
    advancedFilters: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    columns: {
      type: Array as PropType<ITableColumn[]>,
      required: true,
    },
    filters: {
      type: Object as PropType<IFilterBy>,
      required: true,
    },
    selectedRowsAmount: {
      type: Number as PropType<number>,
      required: false,
    },
    showPrimaryBtn: {
      type: Boolean as PropType<boolean>,
      default: true,
      required: false,
    },
    showSearch: {
      type: Boolean as PropType<boolean>,
      default: true,
      required: false,
    },
    primaryBtnLabel: {
      type: String as PropType<string>,
      required: false,
    },
    primaryBtnIcon: {
      type: String as PropType<string>,
      default: "fa-solid fa-plus",
    },
    primaryBtnDisable: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false,
    },
    primaryTooltipText: {
      type: String as PropType<string>,
      required: false,
    },
    filterInputLabel: {
      type: String as PropType<string>,
      required: false,
    },
    actionMenuItems: {
      type: Array as PropType<ActionMenuItem[]>,
      required: false,
    },
    hideExportCsv: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    showDetailsBtn: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false,
    },
    emptyMessage: {
      type: String as PropType<string>,
      default: "Select a row to see it's details",
      required: false,
    },
    drawerDarkMode: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false,
    },
    disableActions: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false,
    },
    supportRefresh: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    hideAllClusterFilterOption: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    hideClusterFilter: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      selectedColumns: [] as Array<string>,
      isDrawerOpen: false as boolean,
      heightFromTop: 0 as number,
      clusterStore: useClusterStore(),
      refreshingData: false,
    };
  },
  created() {
    this.selectedColumns = this.columns
      .filter((col: ITableColumn) => this.displayedColumns.has(col.name) || col.mandatory)
      .map((col) => col.name);
  },
  mounted() {
    this.setHeightFromTop();
    addEventListener("resize", this.setHeightFromTop);
  },
  computed: {
    allColumnOptions(): Array<ICheckboxItem> {
      return this.columns.map((col: ITableColumn) => ({
        label: col.label,
        value: col.name,
        disable: col.mandatory,
      }));
    },
    Action(): typeof Action {
      return Action;
    },
    resourceType(): ResourceType {
      return this.$route?.meta.resourceType as ResourceType;
    },
    filteredActionMenuItems(): ActionMenuItem[] {
      const csvOption = {
        label: "Download as CSV",
        action: () => this.$emit("export-csv"),
      };
      if (this.actionMenuItems?.length) {
        if (this.hideExportCsv) {
          return this.actionMenuItems;
        }
        return [csvOption, ...this.actionMenuItems];
      }
      if (this.hideExportCsv) {
        return [];
      }
      return [csvOption];
    },
    columnFilters(): Array<IFilterModel> {
      return this.filters.columnFilters || ([] as Array<IFilterModel>);
    },
    advancedColumnFilters(): Array<IAdvancedFilterModel> {
      return this.filters.advancedFilters || [];
    },
    searchTerm(): string {
      return this.filters.searchTerm || "";
    },
    filterOptions(): Array<IFilterOption> {
      return filterService.getFilterOptions(this.columns.filter((c) => !c.hideFilter));
    },
    advancedFilterOptions(): Array<IAdvancedFilterOption> {
      return filterService.getAdvancedFilterOptions(this.columns.filter((c) => !c.hideFilter));
    },
    selectedRowsActionsLabel(): string {
      return this.selectedRowsAmount + " selected";
    },
    showSelectedRowsActions(): boolean {
      return !!this.selectedRowsAmount;
    },
    displayedColumns(): Set<string> {
      return new Set(this.filters.displayedColumns);
    },
    primaryToolTipRows(): string[] | null {
      if (!this.primaryTooltipText) return null;
      return this.primaryTooltipText.split("\n");
    },
    clusterFilter(): IFilterModel | undefined {
      const clusterCol = this.columns.find((c) => c.name === CLUSTER_COLUMN_FILTER_NAME);
      if (!clusterCol) return;

      const clusterFilters = this.filters?.columnFilters?.find((f) => f.name === CLUSTER_COLUMN_FILTER_NAME);
      return { field: clusterCol.field, name: clusterCol.name, term: clusterFilters?.term || "" };
    },
  },
  methods: {
    refreshData(): void {
      this.$emit("refresh-data");
      this.refreshingData = true;
      setTimeout(() => (this.refreshingData = false), 5000);
    },
    updateSelectedColumns(selectedColumns: Array<string>): void {
      this.selectedColumns = selectedColumns;
    },
    beforeClose(): void {
      this.updateFilterBy("displayedColumns", this.selectedColumns);
    },
    updateAdvancedFilters(filterBy: IFilterBy): void {
      this.$emit("filters-changed", filterBy);
    },
    updateFilterBy(key: string, newVal: string | number | Array<string>): void {
      this.setHeightFromTop();
      const keyChanged: string = key;
      this.$emit("filters-changed", { ...this.filters, [key]: newVal }, keyChanged);
    },
    toggleDetailsDrawer(): void {
      this.setHeightFromTop();
      this.isDrawerOpen = !this.isDrawerOpen;
    },
    setHeightFromTop(): void {
      this.$nextTick(() => {
        const actionPageElement = this.$refs.actions as HTMLElement;
        if (actionPageElement) {
          this.heightFromTop = actionPageElement.getBoundingClientRect().bottom;
        }
      });
    },
    onResize(width: string): void {
      this.$emit("drawer-resized", width);
    },
  },
  watch: {
    "clusterStore.clusterAlert": {
      handler() {
        this.setHeightFromTop();
      },
      deep: true,
      immediate: true,
    },
    isDrawerOpen: {
      handler(val: boolean) {
        this.$emit("drawer-toggled", val);
      },
    },
  },
  unmounted() {
    removeEventListener("resize", this.setHeightFromTop);
  },
});
</script>

<style lang="scss" scoped>
.runai-page-actions {
  padding: 9px 0 9px 16px;
  background-color: $white;
  overflow: hidden;
  position: relative;
  min-height: $runai-page-actions-min-height;
  border-bottom: 1px solid $black-15;

  .main-actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .left-actions,
    .right-actions-container {
      display: flex;

      .primary-cta-btn {
        flex-shrink: 0;
        margin-right: 16px;
      }
    }

    .runai-page-filter {
      flex: 1;
    }

    .show-details-button {
      width: 103px;
    }
  }

  .selected-rows-actions {
    background-color: $primary;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    color: $white;

    .title {
      font-size: 14px;
      font-weight: 500;
    }

    .actions-container {
      flex: 1;
    }
  }

  .seperator {
    opacity: 0.2;
  }
}
</style>
