import type { IStatusColOptions } from "@/models/table.model";
import { EQuotaEntity } from "@/models/resource.model";
import type { Scope } from "@/swagger-models/assets-service-client";

import type { ISelectOption } from "@/models/global.model";
import type { Project, QuotaStatusNodePool, Resources } from "@/swagger-models/org-unit-service-client";
import { ProjectPhase } from "@/swagger-models/org-unit-service-client";

export enum EWorkloadNodeAffinity {
  Train = "train",
  Interactive = "interactive",
}

export enum EProjectModalEntity {
  Department = "Department",
}

/**
 * @deprecated This interface is deprecated and will be removed in future versions. use ProjectPhase from org-unit-service-client instead
 */
export enum EProjectPhase {
  Creating = "Creating",
  Updating = "Updating",
  Deleting = "Deleting",
  Ready = "Ready",
  NotReady = "NotReady",
  Initializing = "Initializing",
}

export const ProjectPhaseMap: Record<ProjectPhase, IStatusColOptions> = {
  [ProjectPhase.Creating]: {
    status: "Creating...",
    displayAnimation: true,
  },
  [ProjectPhase.Updating]: {
    status: "Updating...",
    displayAnimation: true,
  },
  [ProjectPhase.Deleting]: {
    status: "Deleting...",
    displayAnimation: true,
  },
  [ProjectPhase.Ready]: {
    status: "Ready",
    color: "success",
    displayAnimation: false,
  },
  [ProjectPhase.NotReady]: {
    status: "Not Ready",
    color: "negative",
    displayAnimation: false,
  },
  [ProjectPhase.Initializing]: {
    status: "Initializing...",
    displayAnimation: true,
  },
  [ProjectPhase.Unknown]: {
    status: "Unknown",
    displayAnimation: false,
  },
};

export interface ISelectedNodeAffinity {
  id: number;
  name: string;
}
export interface INodeAffinityOption {
  affinityType: string;
  selectedTypes: Array<ISelectedNodeAffinity>;
}
export interface ILoadProjectsOptions {
  clusterId: string;
  withAccessRules?: boolean;
  withNamespace?: boolean;
}
export interface INodeAffinitySelectOption {
  label: string;
  value: string;
  loading: boolean;
  selectedTypes: ISelectOption[];
}

export interface INodeAffinity {
  train: INodeAffinityOption;
  interactive: INodeAffinityOption;
}
export interface IProjectPermissions {
  users: Array<string>;
  groups: Array<string>;
  applications: Array<string>;
}

export interface IProjectsModalOptions {
  parentId: string;
  entityName: string;
  entityType: EProjectModalEntity;
  clusterId: string;
}
/**
 * @deprecated This interface is deprecated and will be removed in future versions. use Project from org-unit-service-client instead
 */
export interface IProject extends Project {
  clusterUuid?: string;
  deservedGpus?: number;
  maxAllowedGpus?: number;
  departmentId: number;
  interactiveJobTimeLimitSecs?: null | number;
  interactiveJobMaxIdleDurationSecs?: null | number;
  interactivePreemptibleJobMaxIdleDurationSecs?: null | number;
  trainingJobTimeLimitSecs?: null | number;
  trainingJobMaxIdleDurationSecs?: null | number;
  swapEnabled: boolean;
  gpuOverQuotaWeight: number;
  nodeAffinity: INodeAffinity;
  departmentName: string;
  permissions: IProjectPermissions;
  nodePoolsResources: INodePoolResources[];
  namespace?: string;
  roles?: string[];
  allocatedCpu?: number;
  allocatedGpus?: number;
  allocatedMemory?: number;
  phase: EProjectPhase | null;
}

export interface IProjectTable extends Project {
  rolesNames?: string[];
}

export interface IProjectResources {
  id: number;
  tenantId: number;
  clusterUuid: string;
  name: string;
  deservedGpus: number;
  allocatedGpus: number;
  createdAt?: string;
}

export interface INodePoolResourceDetails {
  id: number;
  name: string;
}
/**
 * @deprecated This interface is deprecated and will be removed in future versions.
 */
export interface INodePoolResources {
  nodePool: INodePoolResourceDetails;
  gpu: IResourceQuota;
  cpu: IResourceQuota;
  memory: IResourceQuota;
}
export interface INodePoolsResourcesRow extends Resources {
  priority?: number | string;
  allocatedGpus?: number;
  allocatedCpu?: number;
  allocatedMemory?: number;
  overQuotaWeightLabel?: string;
}
export interface IResourceQuota {
  deserved?: number | null;
  maxAllowed?: number | null;
  overQuotaWeight?: number | null;
}

export interface IProjectSectionModel {
  scope: Scope;
  projectId: number | null;
}

export interface INodePoolModalOptions {
  header: string;
  entity: EQuotaEntity;
  nodePoolQuotaStatuses: QuotaStatusNodePool[];
  resources: Resources[];
  defaultNodePools?: string[];
  loading?: boolean;
}

export enum EProjectColumnName {
  ProjectName = "project-name",
  DepartmentName = "department-name",
  Status = "status",
  NodePools = "node-pools",
  AccessRules = "accessRules",
  AllocatedCpu = "allocated-cpu",
  AllocatedMemory = "allocated-memory",
  AllocatedGpus = "allocated-gpus",
  GpuUtilization = "gpu-utilization",
  CpuUtilization = "cpu-utilization",
  MemoryUtilization = "memory-utilization",
  GpuQuota = "gpu-quota",
  CpuQuota = "cpu-quota",
  CpuMemoryQuota = "cpu-memory-quota",
  NodeAffinityTrain = "node-affinity-train",
  NodeAffinityInteractive = "node-affinity-interactive",
  TrainingJobMaxIdleDurationSecs = "training-job-max-idle-duration-secs",
  InteractivePreemptibleJobMaxIdleDurationSecs = "interactive-preemptible-job-max-idle-duration-secs",
  InteractiveJobMaxIdleDurationSecs = "interactive-job-max-idle-duration-secs",
  InteractiveJobTimeLimitSecs = "interactive-job-time-limit-secs",
  TrainingJobTimeLimitSecs = "training-job-time-limit-secs",
  CreatedAt = "created-at",
  Workloads = "workloads",
}
