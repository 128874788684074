import { invertObject } from "@/utils/object.util/object.util";

export enum EventStatus {
  Success = "Success",
  Failure = "Failure",
}

export enum ESubjectType {
  User = "User",
  Group = "Group",
  Application = "Application",
}

export const DisplayNameAuditEntityType: Record<string, string> = {
  NodeType: "Node type (Affinity)",
  Setting: "Settings",
  ApplicationSecret: "Application secret",
  NodePool: "Node pool",
  Nfs: "NFS",
  HostPath: "Host path",
  AccessKeys: "Access keys",
};

export const FiltersTermByDisplayNameEntityType: Record<string, string | undefined> =
  invertObject(DisplayNameAuditEntityType);

export interface IAuditEventResponse {
  audit_logs: IAuditEvent[];
  total: number;
  next: number;
}
export interface IAuditEvent {
  id: string;
  timestamp: string;
  tenant_id: string;
  subject: string;
  subject_type: string;
  source_ip: string;
  action: string; //mao to event
  http_method: string;
  url: string;
  entity_type: string;
  entity_name: string;
  entity_id: string;
  result: string;
  http_status_code: number;
  cluster_id: string;
  request_id: string;
}

export interface IAuditEventQueryParams {
  start?: string;
  end?: string;
  filterBy?: Array<string>;
  sortBy?: string;
  sortOrder?: "asc" | "desc";
  offset?: number;
  numberOfSamples?: number;
}
