<template>
  <wrapper-card @close="$emit('close')" :header="header" :subheader="subheader">
    <template #body>
      <gray-card
        :hide-close-button="index === 0 && workloadsDuration.length == 1"
        @close="hideWorkloadSection(index)"
        v-for="(workload, index) in workloadsDuration"
        :key="workload.value"
      >
        <div class="row items-center q-pt-md">
          <div class="col-4 q-mr-md">
            <runai-select
              label="Workload type"
              @update:model-value="onWorkloadSelect(workload, $event, index)"
              :model-value="getOptionValue(workload)"
              :rules="[workloadTypeValidation]"
              :options="workloadOptions"
              aid="gpu-workload-type"
            />
          </div>
          <div class="col">
            <runai-duration-input
              hide-seconds
              @update:model-value="updateDuration(workload, $event, index)"
              :model-value="getWorkloadDuration(workload)"
              :rules="[durationValidation]"
            />
          </div>
        </div>
      </gray-card>
    </template>

    <template #actions>
      <q-btn
        v-if="showAddDurationButton"
        @click="addWorkloadSection"
        color="primary"
        flat
        :label="addDurationButtonLabel"
      />
    </template>
  </wrapper-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
// cmps
import { RunaiDurationInput } from "@/components/common/runai-duration-input";
import { WrapperCard } from "@/components/project/project-edit-form/cards/";
import { GrayCard } from "@/components/project/project-edit-form/cards/";
import { RunaiSelect } from "@/components/common/runai-select";
// models
import type { ISelectOption } from "@/models/global.model";
import type { IWorkloadDurationOption } from "@/models/workload.model";
import { errorMessages } from "@/common/error-message.constant";

export default defineComponent({
  name: "workload-duration-configurator",
  components: { GrayCard, WrapperCard, RunaiDurationInput, RunaiSelect },
  emits: ["close", "add-duration", "remove-duration", "duration-changed"],
  props: {
    header: {
      type: String,
      required: true,
    },
    subheader: {
      type: String,
      required: true,
    },
    workloadsDuration: {
      type: Array as PropType<IWorkloadDurationOption[]>,
      required: true,
    },
    workloadOptions: {
      type: Array as PropType<ISelectOption[]>,
      required: true,
    },
    addDurationButtonLabel: {
      type: String,
      required: true,
    },
  },
  computed: {
    showAddDurationButton() {
      return this.workloadsDuration.length < this.workloadOptions.length;
    },
  },
  methods: {
    durationValidation(val: number): boolean | string {
      return val <= 0 ? errorMessages.DURATION_GREATER_THAN_ZERO : true;
    },
    workloadTypeValidation(option: ISelectOption | null): boolean | string {
      return !!option || errorMessages.SELECT_TYPE;
    },
    getWorkloadDuration(workload: IWorkloadDurationOption): number {
      if (workload.duration === null) {
        return 0;
      }
      return workload.duration;
    },
    getOptionValue(workload: ISelectOption): null | ISelectOption {
      if (workload.value) {
        return workload;
      }
      return null;
    },
    addWorkloadSection(): void {
      this.$emit("add-duration");
    },
    hideWorkloadSection(workloadIndex: number): void {
      this.$emit("remove-duration", workloadIndex);
    },
    onWorkloadSelect(workload: IWorkloadDurationOption, selectedWorkload: ISelectOption, workloadIndex: number): void {
      this.$emit("duration-changed", { ...selectedWorkload, duration: workload.duration }, workloadIndex);
    },
    updateDuration(workload: IWorkloadDurationOption, duration: number, workloadIndex: number): void {
      this.$emit("duration-changed", { ...workload, duration }, workloadIndex);
    },
  },
});
</script>
