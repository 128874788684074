import { defineStore } from "pinia";

// models
import type {
  WorkloadCreationRequest,
  Workspace,
  WorkspaceCreationRequestV2,
  WorkspaceV2,
} from "@/swagger-models/assets-service-client";
import { ResourceType, type Action, type PermittedScopes } from "@/swagger-models/authorization-client";
import type { IUIWorkloadCreation } from "@/models/workload.model";
import type { Workload } from "@/swagger-models/workloads-service-client";

// services
import { workspaceService } from "@/services/control-plane/workspace.service/workspace.service";
import { permissionService } from "@/services/authorization/permission.service/permission.service";
import { workloadService } from "@/services/cluster/workload.service/workload.service";

// utils
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { retryCall } from "@/utils/common.util/common.util";

export const useWorkspaceStore = defineStore("Workspace", {
  state: () => ({
    workspace: workloadUtil.getEmptyUIWorkloadCreation() as IUIWorkloadCreation,
    templateId: null as string | null,
    selectedWorkspace: null as WorkspaceV2 | null,
    actionPermissionsByScopes: {} as Record<Action, PermittedScopes>,
  }),
  getters: {
    workspaceName(): string {
      return this.workspace.name || "";
    },
    selectedTemplate(): string | null {
      return this.templateId;
    },
  },
  actions: {
    async loadActionPermissions(): Promise<void> {
      if (Object.keys(this.actionPermissionsByScopes).length > 0) return;
      this.actionPermissionsByScopes = await permissionService.getPermittedScopesForAction(ResourceType.Workspaces);
    },
    async loadById(id: string): Promise<WorkspaceV2> {
      this.selectedWorkspace = await workspaceService.getById(id);
      return this.selectedWorkspace;
    },
    setWorkspace(workspace: IUIWorkloadCreation): void {
      this.workspace = workspace;
    },
    setTemplate(templateId: string | null): void {
      this.templateId = templateId;
    },
    resetCreateWorkspaceData(): void {
      this.templateId = null;
      this.workspace = workloadUtil.getEmptyUIWorkloadCreation();
    },
    async createWorkspaceV2(workspace: WorkspaceCreationRequestV2): Promise<WorkspaceV2> {
      return workspaceService.createWorkspaceV2(workspace);
    },
    async createWorkspace(workspace: WorkloadCreationRequest): Promise<Workload | null> {
      const createdWorkspace: Workspace = await workspaceService.createFromAssets(workspace);
      return retryCall<Workload>(() => workloadService.getWorkloadById(createdWorkspace.meta.id)).catch(() => null);
    },
  },
});
