// services
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { workloadService } from "@/services/cluster/workload.service/workload.service";
import { storageUtil } from "@/utils/storage.util";
import { dateUtil } from "@/utils/date.util";

// models
import type {
  WorkloadCreationRequest,
  Training,
  TrainingCreationRequestV2,
  TrainingV2,
} from "@/swagger-models/assets-service-client";
import type { IWorkloadCreate, IWorkloadResponse, IWorkloadSpec } from "@/models/workload.model";
import type { ILocalStatus } from "@/models/global.model";
import { PolicyType, type DistributedPolicyV2, type TrainingPolicyV2 } from "@/swagger-models/policy-service-client";

// constants
import { K8S_API } from "@/common/api.constant";
import { API } from "@/common/api.constant";
import { policyService } from "../policy.service/policy.service";
import { ScopeType } from "@/swagger-models/authorization-client";
import { httpService } from "@/services/infra/https.service/http.service";
import { assetsServiceApi } from "@/services/infra/client-apis/assets-service-api/assets-service-api";
import { workloadServiceApi } from "@/services/infra/client-apis/workloads-service-api/workloads-service-api";
import type { HttpResponse } from "@/swagger-models/workloads-service-client";

const TRAINING_STATUS = "trainingStatus";

export const trainingService = {
  createFromAssets,
  getById,
  removeTrainingV2,
  resumeTrainingV2,
  stopTrainingV2,
  removeDistributedTrainingV2,
  stop,
  getPolicy,
  createTrainingV2,
};

async function createTrainingV2(trainingCreationRequest: TrainingCreationRequestV2): Promise<TrainingV2> {
  try {
    const response = await assetsServiceApi.trainingApi.createTrainingV2(trainingCreationRequest);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

const endpoint = `${API.v1}/training`;
function trainingsEndpoint(clusterUuid: string): string {
  return `${K8S_API.v1}/clusters/${clusterUuid}/trainings`;
}

async function getById(trainingId: string): Promise<TrainingV2> {
  try {
    const response = await assetsServiceApi.trainingApi.getTrainingByIdV2(trainingId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function createFromAssets(training: WorkloadCreationRequest): Promise<Training> {
  const createdTraining: Training = await controlPlaneService.post(endpoint, training);
  try {
    const workloadCreate: IWorkloadCreate = createdTraining.workload as IWorkloadCreate;
    const workloadType = training.distributed ? "distributed" : "training";
    await createWorkload(workloadCreate, workloadType);
  } catch (e) {
    // submit failure status
    await workloadService.handleFailedWorkloadClusterCreation(createdTraining.meta.id, e);
    await _submitTrainingCreationStatus(training.clusterId, createdTraining.meta.id, false);
    throw e;
  }
  // submit success status
  await _submitTrainingCreationStatus(training.clusterId, createdTraining.meta.id, true);
  return createdTraining;
}

async function removeTrainingV2(trainingId: string): Promise<void> {
  try {
    const response = await workloadServiceApi.trainingsApi.deleteTraining(trainingId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}
async function resumeTrainingV2(trainingId: string): Promise<HttpResponse> {
  try {
    const response = await workloadServiceApi.trainingsApi.resumeTraining(trainingId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}
async function stopTrainingV2(trainingId: string): Promise<HttpResponse> {
  try {
    const response = await workloadServiceApi.trainingsApi.suspendTraining(trainingId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function removeDistributedTrainingV2(distributedId: string): Promise<void> {
  try {
    const response = await workloadServiceApi.distributedApi.deleteDistributed(distributedId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getPolicy(workloadType: PolicyType, projectId: number): Promise<TrainingPolicyV2 | DistributedPolicyV2> {
  const scopeProperties = { scope: ScopeType.Project, projectId: Number(projectId) };
  switch (workloadType) {
    case PolicyType.Distributed:
      return policyService.getDistributedPolicyByScope(scopeProperties);
    case PolicyType.Training:
      return policyService.getTrainingPolicyByScope(scopeProperties);
    case PolicyType.Workspace:
      return {};
  }
}

async function _submitTrainingCreationStatus(clusterUid: string, trainingId: string, status: boolean): Promise<void> {
  await controlPlaneService.put(`${trainingsEndpoint(clusterUid)}/${trainingId}/submission`, { success: status });
}

async function createWorkload(workloadCreate: IWorkloadCreate, workloadType: string): Promise<IWorkloadResponse> {
  const workloadRoute = workloadType === "training" ? "TrainingWorkload" : "DistributedWorkload";
  const spec: IWorkloadSpec = {
    ...workloadCreate.spec,
    name: { value: workloadCreate.metadata.name },
  };

  let masterSpec: IWorkloadSpec | undefined;
  if (workloadCreate.masterSpec) {
    masterSpec = workloadCreate.masterSpec;
  }

  return workloadService.createWorkload(workloadRoute, workloadCreate.metadata, spec, masterSpec);
}

function _cleanupStatus() {
  const currentStatus: Record<string, ILocalStatus> = _getTrainingStatus();
  if (!currentStatus || Object.keys(currentStatus).length === 0) return;
  Object.keys(currentStatus).forEach((key: string) => {
    if (_shouldCleanLocalStatus(currentStatus[key])) {
      delete currentStatus[key];
    }
  });
  _saveTrainingStatus(currentStatus);
}

// this should be called only once in a while,
// because it's a heavy operation and not needed to be called on every status update
_cleanupStatus();

function _shouldCleanLocalStatus(trainingToCheck: ILocalStatus): boolean {
  return dateUtil.moreThanHourFromNow(trainingToCheck.creationTimestamp);
}

function _getTrainingStatus(): Record<string, ILocalStatus> {
  return storageUtil.get<Record<string, ILocalStatus>>(TRAINING_STATUS);
}

function _saveTrainingStatus(trainingStatus: Record<string, ILocalStatus>): void {
  storageUtil.save<Record<string, ILocalStatus>>(TRAINING_STATUS, trainingStatus);
}
